import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdDelete } from "react-icons/md";
import './SupportTicketDetails.scss';
import { API_URL } from "../../../shared";

const SupportTicketDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ticketId } = useParams();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConfirmDialogDelete, setShowConfirmDialogDelete] = useState(false);
  const [ticket, setTicket] = useState(null);

  const getTicketDetails = async () => {
    try {
      const response = await fetch(`${API_URL}/support-ticket/${ticketId}`, { credentials: "include" }); 
      if (response.ok) {
        const data = await response.json();
        setTicket(data); 
      } else {
        throw new Error("Failed to fetch ticket details");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    console.log(ticketId);
    
    if (ticketId) {
      getTicketDetails();
    }
  }, [ticketId]); 

  const handleCloseTicket = () => {
    setShowConfirmDialog(true);
  };

  const confirmCloseTicket = async () => {
    try {
      const response = await fetch(`${API_URL}/support-ticket/update/${ticketId}`, {
        method: 'PUT', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: 'Closed' }), 
        credentials: 'include',
      });

      if (response.ok) {
        alert("Support ticket closed successfully.");
        setTicket((prevTicket) => ({ ...prevTicket, status: 'Closed' }));
        navigate('/b2b/dashboard/support-tickets');
      } else {
        throw new Error("Failed to close ticket");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteTicket = () => {
    setShowConfirmDialogDelete(true);
  };

  const confirmDeleteTicket = async () => {
    try {
      const response = await fetch(`${API_URL}/support-ticket/delete/${ticketId}`, {
        method: 'DELETE',
        credentials: 'include',
      });

      if (response.ok) {
        alert("Ticket deleted successfully.");
        navigate('/b2b/dashboard/support-tickets');
      } else {
        throw new Error("Failed to delete ticket");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formatDate = (dateString) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(new Date(dateString));
  };

  return (
    <div className="support-ticket-details">
      <div className="header-section">
        <h2>{t('dashboard.ticketDetails')}</h2>
        <button 
          className="back-button"
          onClick={() => navigate('/b2b/dashboard/support-tickets')}
        >
          {t('dashboard.backToTickets')}
        </button>
      </div>

      {ticket && (
        <div className="ticket-content">
          <div className="ticket-header">
            <div className="ticket-id">#{ticket.ticket_number}</div>
            <span className={`status-badge ${ticket.status.toLowerCase()}`}>
              {ticket.status}
            </span>
            <span className={`priority-badge ${ticket.priority.toLowerCase()}`}>
              {ticket.priority}
            </span>
          </div>

          <div className="ticket-info">
            <h3>{ticket.subject_title}</h3>
            <div className="metadata">
              <span>{t('dashboard.category')}: {ticket.category}</span>
              <span>{t('dashboard.created')}: {formatDate(ticket.created_at)}</span>
              <span>{t('dashboard.lastUpdate')}: {formatDate(ticket.updated_at)}</span>
            </div>
          </div>

          <div className="ticket-description">
            <h4>{t('dashboard.description')}</h4>
            <p>{ticket.message}</p>
          </div>

          {ticket?.attachments?.length > 0 && (
            <div className="ticket-attachments">
              <h4>{t('dashboard.attachedFiles')}</h4>
              <div className="attached-files">
                {ticket.attachments.map((file, index) => (
                  <div key={index} className="file-item">
                    <a href={file} target="_blank" rel="noopener noreferrer">
                      {`Attachment ${index + 1}`}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )}


          <div className="ticket-actions">
            <button 
                className="delete-ticket-btn"
                onClick={handleDeleteTicket}
              >
                {t('dashboard.deleteTicket')}
            </button>
            <button 
              className={`close-ticket-btn ${ticket.status === 'Closed' ? 'disabled-btn' : ''}`}
              onClick={handleCloseTicket}
              disabled={ticket?.status === 'Closed'}
              key={ticket.status}
            >
              {t('dashboard.closeTicket')}
            </button>
          </div>
        </div>
      )}

      {showConfirmDialog && (
        <div className="confirm-dialog">
          <div className="confirm-content">
            <p>{t('dashboard.confirmCloseTicket')}</p>
            <div className="confirm-actions">
              <button 
                className="confirm-yes"
                onClick={confirmCloseTicket}
              >
                {t('dashboard.yes')}
              </button>
              <button 
                className="confirm-no"
                onClick={() => setShowConfirmDialog(false)}
              >
                {t('dashboard.no')}
              </button>
            </div>
          </div>
        </div>
      )}

      {showConfirmDialogDelete && (
        <div className="confirm-dialog">
          <div className="confirm-content">
            <p>{t('dashboard.confirmDeleteTicket')}</p>
            <div className="confirm-actions">
              <button 
                className="confirm-yes"
                onClick={confirmDeleteTicket}
              >
                {t('dashboard.yes')}
              </button>
              <button 
                className="confirm-no"
                onClick={() => setShowConfirmDialogDelete(false)}
              >
                {t('dashboard.no')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SupportTicketDetails;
