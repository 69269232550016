import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './CandidateSharedPool.scss';
import { logError } from '../../../services/errorLogging';
import { API_URL } from "../../../shared";

const CandidateSharedPool = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchSharedCandidates();
  }, []);

  const fetchSharedCandidates = async () => {
    try {
      const response = await fetch(`${API_URL}/candidates/shared`, {
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      setCandidates(data);
    } catch (error) {
      logError(error);
      setError(error.message || t('candidatePool.errors.fetchFailed'));
    } finally {
      setLoading(false);
    }
  };

  const filteredCandidates = candidates.filter(candidate =>
    `${candidate.first_name} ${candidate.last_name}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
    candidate.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <div className="loading">{t('candidatePool.loading')}</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="candidate-pool">
      <div className="pool-header">
        <h2>{t('candidatePool.sharedPoolTitle')}</h2>
        <div className="search-bar">
          <input
            type="text"
            placeholder={t('candidatePool.searchPlaceholder')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="candidates-grid">
        {filteredCandidates.length > 0 ? (
          filteredCandidates.map(candidate => (
            <div key={candidate.id} className="candidate-card">
              <div className="candidate-info">
                <h3>{`${candidate.first_name} ${candidate.last_name}`}</h3>
                <p className="email">{candidate.email}</p>
                {candidate.phone && <p className="phone">{candidate.phone}</p>}
                {candidate.linkedin_url && (
                  <a 
                    href={candidate.linkedin_url} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="linkedin-link"
                  >
                    {t('candidatePool.linkedinProfile')}
                  </a>
                )}
              </div>
              <div className="card-actions">
                <button 
                  className="view-details"
                  onClick={() => navigate(`/b2b/dashboard/candidates/${candidate.id}`)}
                >
                  {t('candidatePool.viewDetails')}
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="no-candidates">
            <p>{t('candidatePool.noCandidates')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CandidateSharedPool;
