import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import parse from "html-react-parser";
import { FaEdit, FaSave, FaUserPlus } from "react-icons/fa";
import "./AssesmentDetailPageLanguageSkills.scss";
import { API_URL } from "../../../../shared.js";
import SendAssessmentModal from "./composants/SendAssessmentModal.jsx";


const AssesmentDetailPageLanguageSkills = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [htmlContent, setHtmlContent] = useState("");
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isInvitation, setIsInvitation] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const assessmentId = id;
      try {
        const response = await fetch(`${API_URL}/assessment/${assessmentId}`, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        console.log("Result:", result);

        let sanitized = result.analysis_content;

        // Vérifier si ça commence et finit par un guillemet
        if (sanitized.startsWith('"') && sanitized.endsWith('"')) {
          sanitized = sanitized.slice(1, -1);
        }
        
        // 2) Retirer les lignes de code-block ```html et ```
        sanitized = sanitized.replace(/```html\s*/i, ""); // supprime ```html + éventuellement \n
        sanitized = sanitized.replace(/```/g, "");
        
        // 3) Remplacer les `\"` par un vrai guillemet
        sanitized = sanitized.replace(/\\"/g, '"');
        
        // 4) Remplacer les `\\n` par des retours à la ligne réels
        sanitized = sanitized.replace(/\\n/g, "\n");
        
        // 5) (Facultatif) Couper tout ce qu’il y a avant <html>, si nécessaire
        const htmlIndex = sanitized.indexOf("<html");
        if (htmlIndex > 0) {
          sanitized = sanitized.slice(htmlIndex);
        }
        
        // Maintenant, `sanitized` doit contenir votre HTML brut
        console.log("sanitized:", sanitized);
        setHtmlContent(sanitized);
        setData(result);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);



  const handleInviteModalOpen = () => {
    setIsInvitation(true);
    // On lance les deux fetch en parallèle
  };

  const handleInviteModalClose = () => {
    setIsInvitation(false);
  };

  return (
    <>
      {isInvitation && (
        <div className="modal-overlay" onClick={handleInviteModalClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            {<SendAssessmentModal onClose={handleInviteModalClose} />}
          </div>
        </div>
      )}

      {/* Page principale */}
      <div className="assessment-page">
        <div className="header">
          <h1>Language-Skills Assessment</h1>
          <button
            type="button"
            className="invite-btn"
            onClick={handleInviteModalOpen}
          >
            <FaUserPlus /> Invite
          </button>
        </div>

        <div className="content-section">
          {loading && (
            <div className="loading-spinner">
              <ClipLoader color="#6920b0" size={60} />
            </div>
          )}
          {error && <p className="error">Error: {error}</p>}

          {/* AFFICHAGE DIRECT DU HTML */}
          {htmlContent && (
            <div
              className="html-render"
              dangerouslySetInnerHTML={{ __html: htmlContent }}
            />
          )}
        </div>

        <div className="actions">

        </div>
      </div>
    </>

  );
};

export default AssesmentDetailPageLanguageSkills;
