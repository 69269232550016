import './ShareTrackingBoard.scss';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSlideshare } from 'react-icons/fa';
import { MdAddCircle } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { IoCloseOutline } from "react-icons/io5";


const ShareTrackingBoard = ({ onClose }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");


  const handleInviteSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('email:', email);
      // const response = await fetch(
      //   `${API_URL}/hiring-request-form-invitation`,
      //   {
      //     method: "POST",
      //     credentials: "include",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({ email: email }),
      //   }
      // );

      // if (!response.ok) {
      //   throw new Error(`HTTP error! status: ${response.status}`);
      // }

      // const result = await response.json();
      // if (result.success) {
      //   alert("Invitation sent successfully.");
      //   setEmail("");
      // } else {
      //   console.error("Error sending inviation:", result.message);
      // }
    } catch (error) {
      console.error("Error sending inviation:", error);
    }
  };

  return (
    <div className='ShareTrackingBoardModal'>

      <button
        className="modal-close"
        onClick={onClose}
      >
        <IoCloseOutline />
      </button>

      <h1 className='ShareTrackingBoardModal__title'>Modal Shared TrackingBoard</h1>
      <div>
        <form className="ShareTrackingBoardModal__email-content" onSubmit={handleInviteSubmit}>
          <label htmlFor="email">{t('hiringRequests.invite.label')}</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t('hiringRequests.invite.placeholder')}
            required
          />
          <button type="submit">{t('hiringRequests.invite.submit')}</button>
        </form>
      </div>

      <div className='ShareTrackingBoardModal__share-peopleContent'>
        <h2 className='ShareTrackingBoardModal__share-peopleContent-title'>Share with</h2>
        <div className='ShareTrackingBoardModal__share-peopleContent-people'>
          <div className='ShareTrackingBoardModal__share-peopleContent-people-item'>
            <p className='ShareTrackingBoardModal__share-peopleContent-people-item__name'>Name 1 </p>
            <button className='ShareTrackingBoardModal__share-peopleContent-people-item__button'>Disable sharing</button>
          </div>
          <div className='ShareTrackingBoardModal__share-peopleContent-people-item'>
            <p className='ShareTrackingBoardModal__share-peopleContent-people-item__name'>Name 2 </p>
            <button className='ShareTrackingBoardModal__share-peopleContent-people-item__button'>Disable sharing</button>
          </div>
          <div className='ShareTrackingBoardModal__share-peopleContent-people-item'>
            <p className='ShareTrackingBoardModal__share-peopleContent-people-item__name'>Name 3 </p>
            <button className='ShareTrackingBoardModal__share-peopleContent-people-item__button'>Disable sharing</button>
          </div>
          <div className='ShareTrackingBoardModal__share-peopleContent-people-item'>
            <p className='ShareTrackingBoardModal__share-peopleContent-people-item__name'>Name 3 </p>
            <button className='ShareTrackingBoardModal__share-peopleContent-people-item__button'>Disable sharing</button>
          </div>
          <div className='ShareTrackingBoardModal__share-peopleContent-people-item'>
            <p className='ShareTrackingBoardModal__share-peopleContent-people-item__name'>Name 3 </p>
            <button className='ShareTrackingBoardModal__share-peopleContent-people-item__button'>Disable sharing</button>
          </div>
          <div className='ShareTrackingBoardModal__share-peopleContent-people-item'>
            <p className='ShareTrackingBoardModal__share-peopleContent-people-item__name'>Name 3 </p>
            <button className='ShareTrackingBoardModal__share-peopleContent-people-item__button'>Disable sharing</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShareTrackingBoard;
