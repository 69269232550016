import React from "react";
import CandidateDetailsPool from "../B2BDashboard/CandidateDetailsPool.jsx";
import "./CandidateDetailModal.scss";

const CandidateDetailsModal = ({ candidateId, onClose }) => {
  console.log("Candidate ID in modal:", candidateId);
  if (!candidateId) return null;

  return (
    <div className="candidate-modal-overlay" onClick={onClose}>
      <div
        className="candidate-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <button className="modal-close-button" onClick={onClose}>
          ×
        </button>
        {/* Utilisation du composant CandidateDetailsPool */}
        <CandidateDetailsPool id={candidateId} readOnly={true} />
      </div>
    </div>
  );
};

export default CandidateDetailsModal;
