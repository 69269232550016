import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { FaArrowLeft, FaUpload } from "react-icons/fa";
import { throttle } from "lodash";
import { API_URL } from "../shared.js";
import "./JobApplication.scss";

const jobBoardPath = (() => {
  const path = window.location.pathname;
  
  if (path.startsWith('/b2b/dashboard/job-board')) {
    return '/b2b/dashboard/job-board';
  }
  if (path.startsWith('/b2b')) {
    return '/b2b/job-board';
  }
  return '/b2c/job-board';
})();

// Fonction utilitaire pour gérer le fichier
const validateAndSetFile = (file, setFileError, setFormData) => {
  const validTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  if (!file) {
    setFileError("No file selected");
    return false;
  }

  if (!validTypes.includes(file.type)) {
    setFileError("Invalid file format. Only PDF, DOC, and DOCX are allowed.");
    return false;
  }

  if (file.size > 5 * 1024 * 1024) {
    setFileError("File size exceeds 5MB.");
    return false;
  }

  setFileError("");
  setFormData((prev) => ({
    ...prev,
    resume: file,
  }));

  return true;
};

const JobApplication = ({ jobTitle, companyName }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    resume: null,
    linkedin_url: "",
    desired_salary: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileError, setFileError] = useState("");
  const [shouldSubmit, setShouldSubmit] = useState(false); // État pour déclencher l'envoi

  const validateForm = () => {
    const newErrors = {};
    if (!formData.first_name.trim())
      newErrors.first_name = t("jobApplication.errors.firstNameRequired");
    if (!formData.last_name.trim())
      newErrors.last_name = t("jobApplication.errors.lastNameRequired");
    if (!formData.email.trim())
      newErrors.email = t("jobApplication.errors.emailRequired");
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = t("jobApplication.errors.emailInvalid");
    if (!formData.phone.trim())
      newErrors.phone = t("jobApplication.errors.phoneRequired");
    if (!formData.resume)
      newErrors.resume = t("jobApplication.errors.resumeRequired");

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const throttledHandleInputChange = throttle((name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  }, 500);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    throttledHandleInputChange(name, value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    validateAndSetFile(file, setFileError, setFormData);
  };

  useEffect(() => {
    const handleSending = async (e) => {
      if (e) e.preventDefault();
      if (!shouldSubmit) return; // Si shouldSubmit est false, ne rien faire
      if (isSubmitting || !validateForm()) {
        setShouldSubmit(false); // Réinitialiser pour éviter des boucles infinies
        return;
      }

      setIsSubmitting(true);

      const resumeFormData = new FormData();
      resumeFormData.append("resume", formData.resume);
      resumeFormData.append("first_name", formData.first_name);
      resumeFormData.append("last_name", formData.last_name);
      resumeFormData.append("email", formData.email);
      resumeFormData.append("phone", formData.phone);
      resumeFormData.append("id", id);
      resumeFormData.append("linkedin_url", formData.linkedin_url);
      resumeFormData.append("desired_salary", formData.desired_salary);

      try {
        const response = await fetch(`${API_URL}/job-applications`, {
          method: "POST",
          body: resumeFormData,
          // credentials: "include",
        });

        console.log("Statut HTTP :", response.status, response.statusText);
        console.log("En-têtes de la réponse :", response.headers);

        if (!response.ok) {
          const errorDetails = await response.json(); // Ou response.text() selon le backend
          const errorMessage = JSON.stringify(errorDetails.message);
          console.log("Détails de l'erreur :", errorMessage);
          if (
            errorMessage.includes("Candidate already applied to this job offer")
          ) {
            setErrors({
              ...errors,
              resume: "You have already applied to this job offer",
            });
            setIsSubmitting(false);
            setShouldSubmit(false);
            return;
          }
          throw new Error(
            `Submission failed: ${response.status} ${
              response.statusText
            }. Details: ${JSON.stringify(errorDetails.message)}`
          );
        }

        setIsSubmitting(false);
        navigate(`${jobBoardPath}/thank-you/${id}`, { replace: true });
      } catch (error) {
        console.error("Error details:", error.message);
        console.error("Full error object:", error);
        setIsSubmitting(false);
      }
    };
    handleSending();
  }, [shouldSubmit]);

  useEffect(() => {
    return () => {
      throttledHandleInputChange.cancel();
    };
  }, []);

  return (
    <div className="job-application-page">
      <div className="job-application-container">
        <button
          type="button"
          onClick={() => {
            const path = window.location.pathname;
            if (path.includes("/b2c")) {
              navigate(`/b2c/job-board/${id}`);
            } else if (path.includes("/b2b/dashboard")) {
              navigate(`/b2b/dashboard/job-board/${id}`);
            } else {
              navigate(`/b2b/job-board/${id}`);
            }
          }}
          className="back-button"
        >
          <FaArrowLeft /> {t("jobBoard.details.backToJob")}
        </button>

        <div className="application-header">
          <h1>{t("jobApplication.title")}</h1>
          <div className="job-info">
            <h2>{jobTitle}</h2>
            <p>{companyName}</p>
          </div>
        </div>

        <div className="application-formulary">
          <div className="form-group">
            <label htmlFor="first_name">
              {t("jobApplication.firstName")} *
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={formData.first_name}
              onChange={handleInputChange}
              className={errors.first_name ? "error" : ""}
            />
            {errors.first_name && (
              <span className="error-message">{errors.first_name}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="last_name">{t("jobApplication.lastName")} *</label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={formData.last_name}
              onChange={handleInputChange}
              className={errors.last_name ? "error" : ""}
            />
            {errors.last_name && (
              <span className="error-message">{errors.last_name}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="email">{t("jobApplication.email.email")} *</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className={errors.email ? "error" : ""}
            />
            {errors.email && (
              <span className="error-message">{errors.email}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="phone">{t("jobApplication.phone")} *</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className={errors.phone ? "error" : ""}
            />
            {errors.phone && (
              <span className="error-message">{errors.phone}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="linkedin">{t("jobApplication.linkedin")}</label>
            <input
              type="text"
              id="linkedin_url"
              name="linkedin_url"
              value={formData.linkedin_url}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="desired_salary">
              {t("jobApplication.desiredSalary")}
            </label>
            <input
              type="number"
              id="desired_salary"
              name="desired_salary"
              value={formData.desired_salary}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label>{t("jobApplication.resume")} *</label>
            <div className="file-upload-container">
              <input
                type="file"
                id="resume"
                name="resume"
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx"
                className="file-input"
              />
              <div className="file-upload-button">
                <FaUpload />
                <span>{t("jobApplication.uploadResume")}</span>
              </div>
              {formData.resume && (
                <div className="file-name">{formData.resume.name}</div>
              )}
            </div>
            {fileError && <span className="error-message">{fileError}</span>}
            <p className="file-info">{t("jobApplication.fileInfo")}</p>
          </div>
          {errors.resume && (
            <span className="error-message">{errors.resume}</span>
          )}

          <button
            onClick={() => setShouldSubmit(true)}
            className="submit-button"
            disabled={isSubmitting}
          >
            {isSubmitting
              ? t("jobApplication.submitting")
              : t("jobApplication.submit")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobApplication;