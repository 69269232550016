import "./ModalAddToTrackingBoard.scss";
import React from "react";
import { useEffect, useState } from "react";
import { API_URL } from "../../../../shared.js";
import { set } from "date-fns";
import { useTranslation } from "react-i18next";

// fetch tracking boards
const fetchTrackingBoards = async () => {
  try {
    const response = await fetch(`${API_URL}/v1/kanban/boards`, {
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error("Failed to fetch tracking boards");
    }
    const trackingBoardsData = await response.json();
    console.log("Fetched tracking boards:", trackingBoardsData);
    return trackingBoardsData;
  } catch (error) {
    console.error("Error fetching tracking boards:", error);
  }
};

// fetching boards détails
const fetchBoardDetails = async (boardId) => {
  try {
    const response = await fetch(`${API_URL}/v1/kanban/boards/${boardId}`, {
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Failed to fetch board details");
    }

    const boardDetails = await response.json();

    // Définit `KanbanLists` comme tableau vide si non défini ou incorrect
    boardDetails.KanbanLists = Array.isArray(boardDetails.KanbanLists)
      ? boardDetails.KanbanLists
      : [];
    console.log("Fetched board details:", boardDetails);
    return boardDetails;
  } catch (error) {
    console.error("Error fetching board details:", error);
    return null;
  }
};


const addCandidateCardToSpecificList = async (
  boardId,
  listId,
  candidate,
  existingCards,
  t
) => {
  try {
    console.log("candidate", candidate);
    const description =
      `Name: ${candidate.firstName || "N/A"} ${candidate.lastName || "N/A"}\n` +
      `Email: ${candidate.email || "N/A"}\n` +
      `Phone: ${candidate.phone || "N/A"}\n` +
      `LinkedIn: ${candidate.linkedin || "#"}`;

    const response = await fetch(`${API_URL}/v1/kanban/cards`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        title: "Candidate",
        list_id: listId,
        description: description,
        position: existingCards.length + 1,
        candidate_id: candidate.id,
      }),
    });

    const cardData = await response.json();
    console.log("Message controller :", cardData.message);

    if (!response.ok) {
      if (
        response.status === 400 &&
        cardData.message ===
          "This candidate already exists in the specified board."
      ) {
        console.log("Candidate already exists in the specified board.");
        alert(t("jobApplication.receivedApplication.alertAlreadyBoard"));
        return null;
      }
      throw new Error(
        cardData.message || "Failed to add candidate card to list"
      );
    }

    console.log("Added candidate card to list:", cardData);
    return cardData;
  } catch (error) {
    console.error("Error caught in catch:", error);
    alert(
      error.message ||
        "An error occurred while adding the candidate to the list."
    );
    return null;
  }
};

const ModalAddToTrackingBoard = ({ candidate, closeModal }) => {
  const { t } = useTranslation();
  const [trackingBoards, setTrackingBoards] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [boardLists, setBoardLists] = useState([]);
  const [selectedList, setSelectedList] = useState(null);

  useEffect(() => {
    const loadingTrackingBoards = async () => {
      const trackingBoardsData = await fetchTrackingBoards();
      console.log("trackingBoardsData", trackingBoardsData);

      if (trackingBoardsData.length === 0) {
        console.log("No tracking boards found");
        return;
      } else {
        setTrackingBoards(trackingBoardsData);
      }
    };

    loadingTrackingBoards();
  }, []);

  useEffect(() => {
    const loadBoardDetails = async () => {
      if (selectedBoard) {
        const boardDetails = await fetchBoardDetails(selectedBoard.id);
        console.log("boardDetails", boardDetails);
        if (boardDetails) {
          setBoardLists(boardDetails.KanbanLists);
        } else {
          console.warn("No board details found");
        }
      } else {
        setBoardLists([]); // reset board lists if no board is selected
      }
    };

    loadBoardDetails();
  }, [selectedBoard]);

  const handleAddToTrackingBoard = async () => {
    if (!selectedBoard || !selectedList) {
      alert(t('modals.trackingBoard.selectBothBoardAndList'));
      return;
    }

    try {
      console.log(
        `Adding candidate to board ${selectedBoard.name} in list ${selectedList.name}`
      );

      const addedCard = await addCandidateCardToSpecificList(
        selectedBoard.id,
        selectedList.id,
        candidate,
        selectedList.KanbanCards || [],
        t
      );

      if (addedCard) {
        alert(t('modals.trackingBoard.successMessage'));
        closeModal();
      }
    } catch (error) {
      console.error("Error adding candidate to tracking board:", error);
    }
  };

  return (
    <div className="AddToTrackingBoardModal">
      <div className="AddToTrackingBoardModal__content">
        <h2 className="AddToTrackingBoardModal__content-title">
          {t('modals.trackingBoard.title')}
        </h2>
        <div className="AddToTrackingBoardModal__content-container">
          <div className="AddToTrackingBoardModal__content-container-item">
            <h3 className="AddToTrackingBoardModal__content-container-item-title">
              {t('modals.trackingBoard.selectBoard')}:
            </h3>
            <select
              className="AddToTrackingBoardModal__content-container_select"
              value={selectedBoard?.id}
              onChange={(e) => {
                const boardId = e.target.value;
                const board = trackingBoards.find((b) => b.id === boardId);
                setSelectedBoard(board);
              }}
            >
              <option value="">{t('modals.trackingBoard.selectBoardOption')}</option>
              {trackingBoards.map((board) => (
                <option key={board.id} value={board.id}>
                  {board.name}
                </option>
              ))}
            </select>
          </div>
          <div className="AddToTrackingBoardModal__content-container-item">
            <h3 className="AddToTrackingBoardModal__content-container-item-title">
              {t('modals.trackingBoard.selectList')}:
            </h3>
            <select
              className="AddToTrackingBoardModal__content-container_select"
              value={selectedList?.id || ""}
              onChange={(e) => {
                const listId = e.target.value;
                const list = boardLists.find((l) => l.id === listId);
                setSelectedList(list || null);
              }}
              disabled={!selectedBoard}
            >
              <option value="">{t('modals.trackingBoard.selectListOption')}</option>
              {boardLists.map((list) => (
                <option key={list.id} value={list.id}>
                  {list.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="AddToTrackingBoardModal__content-buttons_container">
          <button
            className="AddToTrackingBoardModal__content-buttons_container-buttonAdd"
            onClick={handleAddToTrackingBoard}
          >
            {t('modals.trackingBoard.addButton')}
          </button>
          <button
            className="AddToTrackingBoardModal__content-buttons_container-buttonCancel"
            onClick={closeModal}
          >
            {t('modals.trackingBoard.cancelButton')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalAddToTrackingBoard;
