import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "./ScheduledInterviews.scss";
import { API_URL } from "../../../shared";

const ScheduledInterviews = () => {
  const [upcomingInterviews, setUpcomingInterviews] = useState([]);
  const [pastInterviews, setPastInterviews] = useState([]);
  const [interviewToDelete, setInterviewToDelete] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [customEmails, setCustomEmails] = useState({});
  const navigate = useNavigate();
  const isDashboard = location.pathname === "/b2b/dashboard";

  useEffect(() => {
    fetchInterviews();
    fetchCustomEmails();
  }, []);
  const fetchInterviews = async () => {
    try {
      console.log("fetchInterviews appelé !");
      const response = await fetch(`${API_URL}/interviews`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      const data = await response.json();
      console.log("Interviews data:", data); // Vérification des données

      const now = new Date();
      setUpcomingInterviews(
        data.filter((interview) => new Date(interview.scheduled_at) > now)
      );
      setPastInterviews(
        data.filter((interview) => new Date(interview.scheduled_at) <= now)
      );
    } catch (error) {
      console.error("Error fetching interviews:", error);
    }
  };

  const fetchCustomEmails = async () => {
    try {
      const response = await fetch(`${API_URL}/interviews/custom-emails`, {
        credentials: "include",
      });
      const data = await response.json();
      setCustomEmails(data);
    } catch (error) {
      console.error("Error fetching custom emails:", error);
    }
  };

  const handleActionChange = (e, interviewId) => {
    const action = e.target.value;

    switch (action) {
      case "details":
        navigate(`/interview/${interviewId}`); // Navigate to the interview details page (not implemented)
        break;

      case "update":
        navigate(`/interview/${interviewId}/update`); // Navigate to the interview update page (not implemented)
        break;

      case "delete":
        setInterviewToDelete(interviewId);
        setShowDeleteConfirm(true);
        break;

      default:
        break;
    }
    e.target.value = "default";
  };

  const handleDeleteInterview = async (id) => {
    try {
      const response = await fetch(`${API_URL}/interview/${id}`, {
        method: "DELETE",
      });
      if (response.ok) {
        fetchInterviews();
      }
    } catch (error) {
      console.error("Error deleting Interview:", error);
    }
  };

  const confirmDelete = async () => {
    if (interviewToDelete) {
      await handleDeleteInterview(interviewToDelete);
      setShowDeleteConfirm(false);
      setInterviewToDelete(null);
    }
  };

  const renderInterviewList = (interviews, isPast = false) => (
    <table className="interviews-table">
      <thead>
        <tr>
          <th>Date & Time</th>
          <th>Candidate</th>
          <th>Job Title</th>
          <th>Video Link</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {interviews.map((interview) => (
          <tr key={interview.id}>
            <td>{new Date(interview.scheduled_at).toLocaleString()}</td>
            <td>
              <Link to={`/b2b/dashboard/candidates/${interview.Candidate.id}`}>
                {interview.Candidate.first_name} {interview.Candidate.last_name}
              </Link>
            </td>
            <td>
              <Link
                to={`/b2b/dashboard/job-openings/${interview.Job_Offer.id}`}
              >
                {interview.Job_Offer.role}
              </Link>
            </td>
            <td>
              <a
                href={interview.videoLink || "#"} // Add the video link here when available
                target="_blank"
                rel="noopener noreferrer"
              >
                Join Video Call
              </a>
            </td>
            <td className="actions-column">
              <select
                onChange={(e) => handleActionChange(e, interview.id)}
                defaultValue="default"
                className="action-select"
              >
                <option value="default" disabled>
                  Choose an action
                </option>
                <option value="details">See details</option>
                <option value="update">Update</option>
                <option value="delete">Delete</option>
              </select>
            </td>
          </tr>
        ))}
      </tbody>
      {showDeleteConfirm && (
        <div className="delete-confirmation-overlay">
          <div className="delete-confirmation-dialog">
            <p>Do you really want to delete this candidate?</p>
            <div className="dialog-actions">
              <button
                type="button"
                className="cancel-button"
                onClick={() => {
                  setShowDeleteConfirm(false);
                  setInterviewToDelete(null);
                }}
              >
                Cancel
              </button>
              <button className="delete-button" onClick={confirmDelete}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </table>
  );

  const sendCustomEmail = async (interviewId) => {
    try {
      const emailContent = prompt("Enter the content of your custom email:");
      if (emailContent) {
        const response = await fetch(
          `${API_URL}/interviews/${interviewId}/send-email`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ content: emailContent }),
          }
        );
        if (response.ok) {
          alert("Custom email sent successfully");
          fetchCustomEmails(); // Refresh the list of custom emails
        } else {
          throw new Error("Failed to send custom email");
        }
      }
    } catch (error) {
      console.error("Error sending custom email:", error);
      alert("Failed to send custom email");
    }
  };

  return (
    <div className="scheduled-interviews">
      <h2>Scheduled Interviews</h2>
      <h3>Upcoming Interviews</h3>
      {renderInterviewList(upcomingInterviews)}
      {!isDashboard && (
        <>
          <h3>Past Interviews</h3>
          {renderInterviewList(pastInterviews, true)}
        </>
      )}
    </div>
  );
};

export default ScheduledInterviews;
