import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import "./TalexaChatB2B.scss";

import plusIcon from "../../../assets/plusBlue.png";

import { logError } from "../../../services/errorLogging";

import ReactMarkdown from "react-markdown";

import remarkGfm from "remark-gfm";

import { API_URL } from "../../../shared";

import { addCandidateToPipeline } from '../../../api/candidateApi';

// Add the detectLanguage helper function here, before the component definition
const detectLanguage = (text) => {
  const languagePatterns = {
    en: /\b(Role|Company Name|Skills)\b/i,
    fr: /\b(Rôle|Nom de l'entreprise|Compétences)\b/i,
    es: /\b(Rol|Nombre de la empresa|Habilidades)\b/i,
    it: /\b(Ruolo|Nome dell'azienda|Competenze)\b/i,
    de: /\b(Rolle|Firmenname|Fähigkeiten)\b/i,
    pt: /\b(Cargo|Nome da empresa|Habilidades)\b/i
  };

  for (const [lang, pattern] of Object.entries(languagePatterns)) {
    if (pattern.test(text)) {
      return lang;
    }
  }
  return 'unknown';
};

const TalexaB2B = ({ user, navigate }) => {

  const { t, i18n } = useTranslation();

  const [messages, setMessages] = useState([]);

  const [inputMessage, setInputMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [conversations, setConversations] = useState([]);

  const [currentConversationId, setCurrentConversationId] = useState(null);

  const [hiringRequests, setHiringRequests] = useState([]);

  const [regenerating, setRegenerating] = useState(false);

  const messagesEndRef = useRef(null);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const fileInputRef = useRef(null);

  const [showActionButtons, setShowActionButtons] = useState(true);

  const [manualQuestionField, setManualQuestionField] = useState(null);

  const [jobOfferData, setJobOfferData] = useState({});

  const [jobOpenings, setJobOpenings] = useState([]);

  const [isSearchingCandidates, setIsSearchingCandidates] = useState(false);

  const [candidateResults, setCandidateResults] = useState([]);

  const [selectedJob, setSelectedJob] = useState(null);

  const AIURL = process.env.REACT_APP_AI_URL || "http://localhost:5001";

  // Function to save job offer data

  const saveJobOfferData = async (data) => {

    try {

      // Prepare AI-specific metadata

      const aiJobOfferData = {

        ...data,

        is_ai_generated: true,

        source: "TalexaChatB2B", // Adding source metadata for identification

        timestamp: new Date().toISOString(),

      };



      console.log("Prepared AI job offer data:", aiJobOfferData);



      const response = await fetch(`${API_URL}/save-job-offer`, {

        method: "POST",

        headers: {

          "Content-Type": "application/json",

        },

        credentials: "include",

        body: JSON.stringify({

          jobOfferData: aiJobOfferData,

        }),

      });



      if (!response.ok) {

        console.error("API request failed with status:", response.status);

        throw new Error("Failed to process job offer data");

      }



      const result = await response.json();

      console.log("API response:", result);



      // Store the data with the AI metadata in localStorage

      localStorage.setItem(

        result.storageKey,

        JSON.stringify(result.dataToSave)

      );

      console.log("Stored data in localStorage with key:", result.storageKey);



      // Also store the pre-filled data for further use

      localStorage.setItem(

        "preFilledJobOfferData",

        JSON.stringify(result.dataToSave)

      );

      console.log("Stored pre-filled job offer data in localStorage");



      // Redirect to the next page after successful data save

      window.location.href = "/b2b/dashboard/job-openings/create";

      console.log("Redirecting to job creation page...");

    } catch (error) {

      console.error("Error saving job offer data:", error);

    }

  };


  // Language detection and i18n configuration
  useEffect(() => {
    const browserLang = navigator.language.split("-")[0];
    const supportedLanguages = ['en', 'fr', 'es', 'it', 'de', 'pt'];

    if (browserLang && supportedLanguages.includes(browserLang)) {
      i18n.changeLanguage(browserLang);
    }
  }, []);

  // Message initial dans la langue détectée

  useEffect(() => {

    setMessages([

      {

        role: "assistant",

        content: t("talexaB2B.initialPrompt"),

      },

    ]);

  }, [t, i18n.language]); // Dépendance à t et à la langue



  // Scroll to bottom of messages

  const scrollToBottom = () => {

    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

  };



  useEffect(() => {

    scrollToBottom();

  }, [messages]);



  // Load conversations on mount

  useEffect(() => {

    const loadConversations = async () => {

      try {

        const token = localStorage.getItem("token");

        const response = await fetch("/api/conversations", {

          headers: {

            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",

          },

        });



        if (!response.ok) {

          throw new Error(`HTTP error! status: ${response.status}`);

        }



        const data = await response.json();

        setConversations(data);

      } catch (error) {

        console.error("Error loading conversations:", error);

        logError(error);

      }

    };



    if (user?.id) {

      loadConversations();

    }

  }, [user]);


  // Fetch job openings

  useEffect(() => {

    const fetchJobOpenings = async () => {

      try {

        const response = await fetch('/api/job-offers', {

          headers: {

            Authorization: `Bearer ${localStorage.getItem("token")}`,

          },

        });

        if (response.ok) {

          const data = await response.json();

          setJobOpenings(data);

        }

      } catch (error) {

        console.error("Error fetching job openings:", error);

      }

    };



    fetchJobOpenings();

  }, []);



  // Handle search candidates action

  const handleSearchCandidates = () => {

    setIsSearchingCandidates(true);

    const jobsList = jobOpenings

      .map((job, index) => {

        return `${index + 1}) ${job.role} ${job.is_ai_generated ? '(AI Generated)' : ''}`;

      })

      .join("\n\n");

    const searchMessage = `${t("talexaB2B.manualQuestions.availableJobOpenings")}\n\n${jobsList}\n\n${t("talexaB2B.manualQuestions.selectJob")}`;



    setMessages((prev) => [

      ...prev,

      { role: "assistant", content: searchMessage },

    ]);

  };

  // Fetch hiring requests

  useEffect(() => {

    const fetchHiringRequests = async () => {

      try {

        const response = await fetch("/api/hiring-requests");

        if (response.ok) {

          const data = await response.json();

          setHiringRequests(data);

        } else {

          throw new Error("Failed to fetch hiring requests");

        }

      } catch (error) {

        console.error("Error fetching hiring requests:", error);

        logError(error);

      }

    };



    fetchHiringRequests();

  }, []);

  // Move this before any useEffect that uses it
  const handleAddToPipeline = useCallback(async (candidate) => {
    try {
      // Validate candidate data
      if (!candidate || typeof candidate !== 'object') {
        throw new Error('Invalid candidate data');
      }

      // Handle name parsing with fallbacks
      let firstName = '', lastName = '';
      if (candidate.Name) {
        const nameParts = candidate.Name.split(' ');
        firstName = nameParts[0] || '';
        lastName = nameParts.slice(1).join(' ') || '';
      }

      const candidateData = {
        firstName,
        lastName,
        currentTitle: candidate["Most Recent Experience"] || '',
        currentCompany: '-',
        sourcedFor: "General Pool",
        sourcedFrom: "AI Search",
        skills: Array.isArray(candidate.Skills) ? candidate.Skills : [],
        headline: candidate.Headline || '',
        linkedinUrl: candidate["LinkedIn Profile Link"] || ''
      };

      console.log('Sending candidate to pipeline:', candidateData); // Debug log
      await addCandidateToPipeline(candidateData);

      // Show success message
      setMessages(prev => [
        ...prev,
        {
          role: "assistant",
          content: t("talexaB2B.candidateAddedToPipeline", {
            name: candidate.Name || 'Candidate'
          })
        }
      ]);
    } catch (error) {
      console.error("Error adding candidate to pipeline:", error);
      setMessages(prev => [
        ...prev,
        {
          role: "assistant",
          content: `${t("talexaB2B.errorAddingCandidate")} (${error.message})`
        }
      ]);
    }
  }, [t]);

  const handleNewChat = async () => {

    try {

      setLoading(true);

      const token = localStorage.getItem("token");



      const response = await fetch("/api/conversations", {

        method: "POST",

        headers: {

          "Content-Type": "application/json",

          Authorization: `Bearer ${token}`,

        },

        body: JSON.stringify({

          tenantId: user?.tenantId,

        }),

      });



      if (response.ok) {

        const newConversation = await response.json();

        setCurrentConversationId(newConversation.id);

        setMessages([

          {

            role: "assistant",

            content: t("talexaB2B.welcomeMessage"),

          },

        ]);

        setConversations([newConversation, ...conversations]);

      }

    } catch (error) {

      logError(error);

    } finally {

      setLoading(false);

      setInputMessage("");

    }

  };

  const handleSendMessage = async (e) => {

    e.preventDefault();

    if (!inputMessage.trim() || loading) return;



    try {

      setLoading(true);

      const newMessages = [

        ...messages,

        { role: "user", content: inputMessage },

      ];

      setMessages(newMessages);

      setInputMessage("");



      // Check if input matches a job selection (e.g., "1", "2", etc.)

      const selectedIndex = parseInt(inputMessage.trim()) - 1;



      // In your handleSendMessage function

      if (isSearchingCandidates && !isNaN(selectedIndex) && selectedIndex >= 0 && selectedIndex < jobOpenings.length) {
        const selectedJob = jobOpenings[selectedIndex];

        try {
          // Show initial message
          setMessages((prev) => [
            ...prev,
            {
              role: "assistant",
              content: t("talexaB2B.findCandidates", { role: selectedJob.role }),
            },
          ]);

          // Hardcoded candidates data
          const candidates = [
            [
              {
                "Headline": "Associate Software Engineer | Full Stack Developer ( Python/ Django/React JS/Next JS) | Front-end web developer | Looking for on-site or remote job with good opportunities to grow",
                "LinkedIn Profile Link": "https://www.linkedin.com/in/amandurranidev?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAC1lHt4BPURlf_t3axHpBto66iXU_lyNOow",
                "Most Recent Experience": "Web Developer",
                "Name": "Aman Ullah Khan",
                "Skills": [
                  "Engineering",
                  "Project Management",
                  "English",
                  "Research Skills",
                  "Marketing"
                ]
              },
              {
                "Headline": "Entrepreneur | Technologist | Team Builder | Convergence Minded | Digital Transformation | Strategic Thinker | E-Commerce |",
                "LinkedIn Profile Link": "https://www.linkedin.com/in/ACoAAAA0kNQB86XviFDf5HALdLZPNUjzjD0hxJg?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAAA0kNQB86XviFDf5HALdLZPNUjzjD0hxJg",
                "Most Recent Experience": "Founder & CEO",
                "Name": "Muhammad Umar Farooq Rana",
                "Skills": [
                  "IT Infrastructure Design",
                  "Bluetooth Low Energy",
                  "Content Delivery",
                  "Mobile Application Design",
                  "Technology Incubation"
                ]
              },
              {
                "Headline": "CEO @ Knowledge Streams. Training talent for the Digital Economy",
                "LinkedIn Profile Link": "https://www.linkedin.com/in/ACoAAAIygTwBY2sdl0XDeWIgMvkVjMHWVYP0OU8?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAAIygTwBY2sdl0XDeWIgMvkVjMHWVYP0OU8",
                "Most Recent Experience": "Chief Executive Officer",
                "Name": "Syed Sohail Hussain Naqvi",
                "Skills": [
                  "Higher Education Policy",
                  "Research",
                  "Teaching",
                  "Higher Education",
                  "Problem Solving"
                ]
              },
              {
                "Headline": "Web Developer | Wordpress developer | Frontend Developer | Graduated'23",
                "LinkedIn Profile Link": "https://www.linkedin.com/in/hifza-tasneem?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAEwuKigBgqG-Df7UU5LZ9UUIh8kgYkizRQQ",
                "Most Recent Experience": "Web Developer",
                "Name": "Hifza Tasneem",
                "Skills": [
                  "Web Development",
                  "Calculus",
                  "HTML",
                  "JavaScript",
                  "Probability"
                ]
              },
              {
                "Headline": "ACCA (UK) | ACPA (Pakistan) | B.Sc. (Hons) Oxford Brookes University (UK) | CA Intermediate (ICAP) | Fintech | Chargebacks | Banking | Finance",
                "LinkedIn Profile Link": "https://www.linkedin.com/in/ACoAAAPzgOwBoyf-xWubBa7ASCfB3rK4z-zxnpI?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAAPzgOwBoyf-xWubBa7ASCfB3rK4z-zxnpI",
                "Most Recent Experience": "i2c Inc.",
                "Name": "Faraz Akram",
                "Skills": [
                  "Banking",
                  "Chargeback",
                  "Fraud analyst",
                  "Internal Controls",
                  "Financial Accounting"
                ]
              },
              {
                "Headline": "Transform Your Digital Presence with Expert Software Solutions | Boosted Businesses to 7-Figure Revenues | Trusted by 100+ Clients",
                "LinkedIn Profile Link": "https://www.linkedin.com/in/ACoAABsXr7ABYgzHwdomq9n2QDRru9fACcdr6MM?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAABsXr7ABYgzHwdomq9n2QDRru9fACcdr6MM",
                "Most Recent Experience": "N/A",
                "Name": "Saif Ali",
                "Skills": [
                  "IT Consulting",
                  "Software Development",
                  "Project Implementation",
                  "Software Project Management",
                  "Project Planning"
                ]
              }
            ],
          ]

          // Display no candidates message if the hardcoded array is empty
          if (!candidates || candidates.length === 0) {
            setMessages((prev) => [
              ...prev,
              {
                role: "assistant",
                content: t("talexaB2B.noCandidatesFound"),
              },
            ]);
            return;
          }

          // Add a summary message
          setMessages((prev) => [
            ...prev,
            {
              role: "assistant",
              content: t("talexaB2B.candidatesFound", { count: candidates[0].length }),
            },
          ]);

          // Display each candidate's details
          candidates[0].forEach((candidate, index) => {  // Added index parameter
            setMessages((prev) => [
              ...prev,
              {
                role: "assistant",
                content: `**Candidate ${index + 1}:**\n${t("talexaB2B.experience", {
                  experience: candidate["Most Recent Experience"] || t("notSpecified"),
                })}
${t("talexaB2B.skills", {
                  skills: candidate.Skills?.join(", ") || t("notListed"),
                })}`,
              },
              {
                role: "assistant",
                content: "",
                component: "button",
                className: "add-pipeline-btn",
                onClick: () => handleAddToPipeline(candidate),
                text: t("talexaB2B.addCandidate", { name: `Candidate ${index + 1}` })
              }
            ]);
          });
          
        } catch (error) {
          console.error("Search error:", error);
          setMessages((prev) => [
            ...prev,
            {
              role: "assistant",
              content: `Search error: ${error.message}. Please try again.`,
            },
          ]);
        } finally {
          setIsSearchingCandidates(false);
        }
      }




      if (!isNaN(selectedIndex) && selectedIndex >= 0 && selectedIndex < hiringRequests.length && !isSearchingCandidates) {

        const job = hiringRequests[selectedIndex];

        const res = await fetch(`/api/hiring-requests/${job.id}`);

        const det = await res.json();

        setJobOfferData({

          role: det.role,

          department: det.department,

          costCenter: det.cost_center,

          contractType: det.contract_type,

          employmentType: det.employment_type

        });


        setMessages(prev => [...prev, { role: "assistant", content: t("talexaB2B.manualQuestions.enterCity") }]);
        setManualQuestionField("city");

        return;

      }



      // Handle responses to manual questions

      if (manualQuestionField) {

        const updatedJobOfferData = { ...jobOfferData };

        updatedJobOfferData[manualQuestionField] = inputMessage;

        setJobOfferData(updatedJobOfferData);



        // Determine next field to ask

        let nextField = null;

        let nextQuestion = "";



        switch (manualQuestionField) {

          case "city":

            nextField = "startHour";


            nextQuestion = t("talexaB2B.manualQuestions.enterStartHour");
            break;

          case "startHour":

            nextField = "endHour";
            nextQuestion = t("talexaB2B.manualQuestions.enterEndHour");
            break;

          case "endHour":

            nextField = "minSalary";
            nextQuestion = t("talexaB2B.manualQuestions.enterMinSalary");
            break;

          case "minSalary":

            nextField = "maxSalary";

            nextQuestion = t("talexaB2B.manualQuestions.enterMaxSalary");
            break;

          case "maxSalary":

            nextField = null;

            // Update the job offer data with the final field

            const finalJobOfferData = {

              ...updatedJobOfferData,

              maxSalary: inputMessage,

            };

            setJobOfferData(finalJobOfferData);



            try {

              const response = await fetch(`${API_URL}/generate-job-offer`, {

                method: "POST",

                headers: {

                  "Content-Type": "application/json",

                },

                credentials: "include",

                body: JSON.stringify({

                  role: finalJobOfferData.role,

                  department: finalJobOfferData.department,

                  costCenter: finalJobOfferData.costCenter,

                  employmentType: finalJobOfferData.employmentType,

                  contractType: finalJobOfferData.contractType,

                  city: finalJobOfferData.city,

                  startHour: finalJobOfferData.startHour,

                  endHour: finalJobOfferData.endHour,

                  minSalary: finalJobOfferData.minSalary,

                  maxSalary: finalJobOfferData.maxSalary,

                }),

              });



              if (response.ok) {

                const generatedOffer = await response.json();

                if (generatedOffer.jobOffer) {

                  // Parse the AI response to extract fields

                  const aiResponse = generatedOffer.jobOffer;

                  const detectedLanguage = detectLanguage(aiResponse);
                  console.log("Detected Language:", detectedLanguage);
                  console.log("Original AI Response:", aiResponse);

                  // Updated multilingual regex patterns
                  const createMultilingualPattern = (field) => {
                    return new RegExp(
                      `\\*\\*(${field}|Rôle|Rol|Ruolo|Rolle|Cargo)\\*\\*:\\s*(.*?)(?=\\n|$)`,
                      'i'
                    );
                  };

                  const patterns = {
                    role: createMultilingualPattern('Role|Rôle|Rol|Ruolo|Rolle|Cargo'),
                    companyName: createMultilingualPattern('Company Name|Nom de l\'entreprise|Nombre de la empresa|Nome dell\'azienda|Firmenname|Nome da empresa'),
                    companyDescription: createMultilingualPattern('Company Description|Description de l\'entreprise|Descripción de la empresa|Descrizione dell\'azienda|Firmenbeschreibung|Descrição da empresa'),
                    employmentType: createMultilingualPattern('Employment Type|Type d\'emploi|Tipo de empleo|Tipo di impiego|Beschäftigungsart|Tipo de emprego'),
                    contractType: createMultilingualPattern('Contract Type|Type de contrat|Tipo de contrato|Tipo di contratto|Vertragsart|Tipo de contrato'),
                    missions: createMultilingualPattern('Missions|Missions|Misiones|Missioni|Aufgaben|Missões'),
                    responsibilities: createMultilingualPattern('Responsibilities|Responsabilités|Responsabilidades|Responsabilità|Verantwortlichkeiten|Responsabilidades'),
                    requirements: createMultilingualPattern('Requirements|Exigences|Requisitos|Requisiti|Anforderungen|Requisitos'),
                    minYearExperience: createMultilingualPattern('Minimum Years of Experience|Années d\'expérience minimales|Años mínimos de experiencia|Anni minimi di esperienza|Mindestberufserfahrung|Anos mínimos de experiência'),
                    roleLevel: createMultilingualPattern('Role Level|Niveau du poste|Nivel del puesto|Livello del ruolo|Position Level|Nível do cargo'),
                    skills: createMultilingualPattern('Skills|Compétences|Habilidades|Competenze|Fähigkeiten|Habilidades'),
                    offerContent: createMultilingualPattern('Offer Content|Contenu de l\'offre|Contenido de la oferta|Contenuto dell\'offerta|Stellenangebot|Conteúdo da oferta'),
                    perks: createMultilingualPattern('Perks|Avantages|Beneficios|Benefit|Vorteile|Benefícios'),
                    hiringProcess: createMultilingualPattern('Hiring Process|Processus de recrutement|Proceso de contratación|Processo di selezione|Einstellungsprozess|Processo de contratação'),
                    tone: createMultilingualPattern('Tone|Ton|Tono|Tono|Ton|Tom')
                  };

                  // Update the parseAIResponse function
                  const parseAIResponse = (aiResponse) => {
                    const parsedFields = {};

                    Object.entries(patterns).forEach(([field, pattern]) => {
                    const match = aiResponse.match(pattern);
                      if (match && match[2]) {
                        parsedFields[field] = match[2].trim();
                        
                        // Special handling for numeric fields
                        if (field === 'minYearExperience') {
                          const numericMatch = match[2].match(/\d+/);
                          if (numericMatch) {
                            parsedFields[field] = numericMatch[0];
                          }
                        }
                      }
                    });

                    // Add additional validation and cleanup
                    if (parsedFields.skills) {
                      parsedFields.skills = parsedFields.skills.split(',').map(skill => skill.trim()).filter(Boolean);
                    }

                    return parsedFields;
                  };

                  const parsedFields = parseAIResponse(aiResponse);
                  console.log("Parsed AI Fields:", parsedFields);

                  if (Object.keys(parsedFields).length === 0) {
                    console.warn("No fields were parsed from AI response. Response:", aiResponse);
                    // Optionally show an error message to the user
                    return;
                  }

                  // Combine manual and AI fields
                  const combinedJobOfferData = {

                    // Manual fields with their original names

                    role: finalJobOfferData.role,

                    department: finalJobOfferData.department,

                    costCenter: finalJobOfferData.costCenter,

                    employmentType: finalJobOfferData.employmentType,

                    contractType: finalJobOfferData.contractType,

                    city: finalJobOfferData.city,

                    startHour: finalJobOfferData.startHour,

                    endHour: finalJobOfferData.endHour,

                    minSalary: finalJobOfferData.minSalary,

                    maxSalary: finalJobOfferData.maxSalary,

                    // AI fields

                    ...parsedFields,

                  };



                  console.log("Final Combined Data:", combinedJobOfferData);



                  // Update the jobOfferData state with combined data

                  setJobOfferData(combinedJobOfferData);



                  setMessages((prev) => [

                    ...prev,

                    {

                      role: "assistant",

                      content: generatedOffer.jobOffer,

                      showRegenerateButton: true,

                    },

                  ]);



                  // Reset other states

                  setManualQuestionField(null);

                  setShowActionButtons(true);

                  return;

                }

              } else {

                const errorData = await response.json();

                if (errorData.missingFields) {

                  // Handle missing fields

                  const nextMissingField = Object.keys(

                    errorData.missingFields

                  )[0];

                  setManualQuestionField(nextMissingField);

                  setMessages((prev) => [

                    ...prev,

                    {

                      role: "assistant",

                      content: errorData.missingFields[nextMissingField],

                    },

                  ]);

                  return;

                }

                throw new Error(

                  errorData.error || "Failed to generate job offer"

                );

              }

            } catch (error) {

              logError("Error submitting job offer:", error);

              setMessages((prev) => [

                ...prev,

                {

                  role: "assistant",

                  content:

                    "There was an error processing your job offer. Please try again.",

                },

              ]);

            }

            break;

        }



        if (nextField) {

          setManualQuestionField(nextField);

          setMessages((prev) => [

            ...prev,

            { role: "assistant", content: nextQuestion },

          ]);

          return;

        }

        // Reset states if no next field

        setManualQuestionField(null);

        setShowActionButtons(true);

      }



      // Default behavior for other messages

      const token = localStorage.getItem("token");

      const endpoint = currentConversationId

        ? `/api/conversations/${currentConversationId}/messages`

        : "/api/chat";



      const response = await fetch(endpoint, {

        method: "POST",

        headers: {

          "Content-Type": "application/json",

          Authorization: `Bearer ${token}`,

        },

        body: JSON.stringify({

          messages: newMessages,

          userId: user?.id,

          tenantId: user?.tenant_id,

          message: inputMessage,

        }),

      });



      if (!response.ok) {

        const errorData = await response.json();

        console.error("Server error:", errorData);

        throw new Error(

          errorData.error || errorData.details || "chat.networkError"

        );

      }



      const data = await response.json();

      if (data.message) {

        setMessages((prev) => [

          ...prev,

          { role: "assistant", content: data.message },

        ]);

        if (data.conversationId && !currentConversationId) {

          setCurrentConversationId(data.conversationId);

        }

      }

    } catch (error) {

      console.error("Full error details:", error);

      logError(error);

      setMessages((prev) => [

        ...prev,

        {

          role: "assistant",

          content:

            "An error occurred while processing your request. Please try again.",

        },

      ]);

    } finally {

      setLoading(false);

    }

  };



  const handleKeyPress = (e) => {

    if (e.key === "Enter" && !e.shiftKey) {

      e.preventDefault();

      handleSendMessage(e);

    }

  };



  const handleFileSelect = (event) => {

    const files = Array.from(event.target.files);

    if (files.length > 2) {

      alert(t("talexaB2B.maxTwoFiles"));

      return;

    }

    setSelectedFiles(files);

  };



  const handleFileUpload = async () => {

    if (selectedFiles.length !== 2) {

      alert(t("talexaB2B.needTwoFiles"));

      return;

    }



    try {

      setLoading(true);

      const formData = new FormData();



      selectedFiles.forEach((file) => {

        formData.append("documents", file);

      });



      const token = localStorage.getItem("token");

      const response = await fetch("/api/chat/upload", {

        method: "POST",

        headers: {

          Authorization: `Bearer ${token}`,

        },

        body: formData,

      });



      const data = await response.json();



      if (data.success) {

        setMessages((prev) => [

          ...prev,

          {

            role: "assistant",

            content: data.message,

          },

        ]);



        // Clear files

        setSelectedFiles([]);

        if (fileInputRef.current) {

          fileInputRef.current.value = "";

        }

      } else {

        throw new Error(data.error || "Upload failed");

      }

    } catch (error) {

      console.error("File upload error:", error);

      setMessages((prev) => [

        ...prev,

        {

          role: "assistant",

          content: `Error: ${error.message}`,

        },

      ]);

    } finally {

      setLoading(false);

    }

  };



  // Move actionPrompts inside component to access t function

  const actionPrompts = useMemo(

    () => ({

      searchCandidates: {

        icon: t("talexaB2B.actions.searchCandidates.icon"),

        label: t("talexaB2B.actions.searchCandidates.label"),

        prompt: `Please help me search for candidates with these criteria:

1. Required skills and qualifications
2. Years of experience
3. Location preferences
4. Contract type preferences
5. Specific industry experience

Please provide a structured search strategy and candidate requirements.`,

      },

      compareResume: {

        icon: t("talexaB2B.actions.compareResume.icon"),

        label: t("talexaB2B.actions.compareResume.label"),

        prompt: `Please analyze the provided CV and job offer to:

1. Calculate overall match percentage
2. Identify key matching skills
3. List missing requirements
4. Highlight candidate's strengths
5. Point out areas for improvement
6. Provide final recommendation`,

      },

      generateInterview: {

        icon: t("talexaB2B.actions.generateInterview.icon"),

        label: t("talexaB2B.actions.generateInterview.label"),

        prompt: `Based on the job offer and candidate's resume, please generate:

1. 5 technical questions specific to required skills
2. 3 behavioral questions related to job responsibilities
3. 2 cultural fit questions
4. 2 scenario-based questions
5. Recommended follow-up questions for each category`,

      },

    }),

    [t]

  ); // Memoize and update when language changes



  const handleActionClick = async (action) => {

    try {

      setLoading(true);



      const response = await fetch("/api/chat", {

        method: "POST",

        headers: {

          "Content-Type": "application/json",

          Authorization: `Bearer ${localStorage.getItem("token")}`,

        },

        body: JSON.stringify({

          message: actionPrompts[action].prompt,

          conversationId: currentConversationId,

        }),

      });



      if (!response.ok) {

        throw new Error("Failed to get AI response");

      }



      const data = await response.json();

      setMessages((prev) => [

        ...prev,

        {

          role: "assistant",

          content: data.message,

        },

      ]);

    } catch (error) {

      logError(error);

      setMessages((prev) => [

        ...prev,

        {

          role: "assistant",

          content: `Error: ${error.message}`,

        },

      ]);

    } finally {

      setLoading(false);

    }

  };



  // Generate a single job offer message

  const handleGenerateJobOffer = () => {

    const jobOffersMessage = hiringRequests

      .map((request, index) => {

        return `${index + 1}) ${request.role} at ${request.department}`;

      })

      .join("\n\n");


    const jobOfferMessage = `${t("talexaB2B.manualQuestions.availableJobOpenings")}\n\n${jobOffersMessage}\n\n${t("talexaB2B.manualQuestions.selectJob")}`;



    setMessages((prev) => [

      ...prev,

      { role: "assistant", content: jobOfferMessage },

    ]);

  };



  const handleRegenerateResponse = async () => {

    setRegenerating(true);

    try {

      // Use the preserved jobOfferData which contains all fields

      const response = await fetch(`${API_URL}/generate-job-offer`, {

        method: "POST",

        headers: {

          "Content-Type": "application/json",

        },

        credentials: "include",

        body: JSON.stringify({

          role: jobOfferData.role || "Software Engineer",

          department: jobOfferData.department || "Engineering",

          costCenter: jobOfferData.costCenter,

          city: jobOfferData.city,

          startHour: jobOfferData.startHour,

          endHour: jobOfferData.endHour,

          minSalary: jobOfferData.minSalary,

          maxSalary: jobOfferData.maxSalary,

        }),

      });



      if (response.ok) {

        const generatedOffer = await response.json();

        if (generatedOffer.jobOffer) {

          // Replace the last message with the new response

          setMessages((prev) => [

            ...prev.slice(0, -1),

            {

              role: "assistant",

              content: generatedOffer.jobOffer,

              showRegenerateButton: true,

            },

          ]);

        }

      } else {

        const errorData = await response.json();

        throw new Error(errorData.error || "Failed to regenerate job offer");

      }

    } catch (error) {

      logError("Error regenerating job offer:", error);

      setMessages((prev) => [

        ...prev,

        {

          role: "assistant",

          content:

            "There was an error regenerating your job offer. Please try again.",

        },

      ]);

    } finally {

      setRegenerating(false);

    }

  };



  const MessageContent = ({ message }) => {

    if (message.showRegenerateButton) {

      return (

        <div>

          <div style={{ whiteSpace: "pre-line" }}>{message.content}</div>

          <div style={{ display: "flex", gap: "10px" }}>

            <button

              onClick={handleRegenerateResponse}

              disabled={regenerating}

              style={{

                padding: "8px 16px",

                backgroundColor: "#007bff",

                color: "#ffffff",

                border: "none",

                borderRadius: "4px",

                cursor: "pointer",

                display: "flex",

                alignItems: "center",

                gap: "8px",

                marginTop: "10px",

              }}

            >

              {regenerating ? ( 

                <>

                  <div

                    style={{

                      width: "16px",

                      height: "16px",

                      border: "2px solid #ffffff",

                      borderTop: "2px solid transparent",

                      borderRadius: "50%",

                      animation: "spin 1s linear infinite",

                      marginRight: "8px",

                    }}

                  />

                  Regenerating...

                </>

              ) : (

                t("talexaB2B.regenerateResponse")

              )}

            </button>

            <button

              onClick={() => saveJobOfferData(jobOfferData)}

              style={{

                padding: "8px 16px",

                backgroundColor: "#28a745",

                color: "#ffffff",

                border: "none",

                borderRadius: "4px",

                cursor: "pointer",

                marginTop: "10px",

                display: "inline-flex",

                alignItems: "center",

              }}

            >

              {t("talexaB2B.addJobOpening")}

            </button>

          </div>

        </div>

      );

    }


    if (message.role === "assistant") {
      return (
        <div>
          {message.component === "button" ? (
            <button
              className={message.className}
              onClick={message.onClick}
              style={{
                padding: "8px 16px",
                backgroundColor: "#28a745",
                color: "#ffffff",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                marginTop: "10px"
              }}
            >
              {message.text}
            </button>
          ) : (
            <div style={{ whiteSpace: "pre-line" }}
              dangerouslySetInnerHTML={{ __html: message.content }}
            />
          )}
        </div>
      );
    }

    return <div style={{ whiteSpace: "pre-line" }}>{message.content}</div>;

  };



  return (

    <div className="talexa-page">

      <div className="title-banner">

        <h1 className="talexa-title">{t("talexaB2B.title")}</h1>

      </div>

      <div className="chat-container">

        <div className="sidebar">

          <button

            className="new-chat-button"

            onClick={handleNewChat}

            disabled={loading}

          >

            <img src={plusIcon} alt="+" className="plus-icon" />

            {t("talexaB2B.newChat")}

          </button>

          <div className="conversations-list">

            {conversations.map((conv) => (

              <div

                key={conv.id}

                className={`conversation-item ${conv.id === currentConversationId ? "active" : ""

                  }`}

                onClick={() => setCurrentConversationId(conv.id)}

              >

                {conv.messages[0]?.content.substring(0, 30)}...

              </div>

            ))}

          </div>

        </div>

        <div className="chat-interface">

          {showActionButtons && (

            <div className="action-buttons">

              {Object.entries(actionPrompts).map(([key, action]) => (

                <button

                  key={key}

                  className="action-button"

                  onClick={

                    key === 'generateJobOffer'

                      ? handleGenerateJobOffer

                      : key === 'searchCandidates'

                        ? handleSearchCandidates :

                        () => handleActionClick(key)

                  }

                  disabled={loading}

                >

                  <span className="action-icon typing-dot">{action.icon}</span>

                  {action.label}

                </button>

              ))}

            </div>

          )}

          <div className="chat-messages">

            {messages.map((message, index) => (

              <div

                key={index}

                className={`message ${message.role === "assistant" ? "assistant" : "user"

                  }`}

              >

                <div className="message-content">

                  <MessageContent message={message} />

                </div>

              </div>

            ))}

            {loading && (

              <div className="message assistant">

                <div className="message-content typing">

                  <span>.</span>

                  <span>.</span>

                  <span>.</span>

                </div>

              </div>

            )}

            <div ref={messagesEndRef} />

          </div>



          <form onSubmit={handleSendMessage} className="chat-input">

            <input

              type="text"

              value={inputMessage}

              onChange={(e) => setInputMessage(e.target.value)}

              onKeyPress={handleKeyPress}

              placeholder={t("talexaB2B.typeYourMessage")}

              disabled={loading}

            />

            <button type="submit" disabled={loading || !inputMessage.trim()}>

              {t("talexaB2B.send")}

            </button>

          </form>

        </div>

      </div>

    </div>

  );

};



export default TalexaB2B;