import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import DashboardHeader from "./B2BDashboardHeader";
import "./B2BDashboardLayout.scss";
import WelcomeMessage from "../../WelcomeMessage";
import JobOpenings from "./JobOpenings";
import TrackingBoards from "./TrackingBoards";
import CandidatePool from "./CandidatePool";
import ScheduledInterviews from "./ScheduledInterviews";
import { API_URL } from "../../../shared";
import TrackingBoardListing from "./TrackingBoardListing";
import { ThemeProvider } from './ThemeContext';
import { useTranslation } from 'react-i18next';
import Joyride from 'react-joyride';

const B2BDashboardLayout = ({ userAddOns, children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [dashboardData, setDashboardData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
 
  console.log("userAddOns:", userAddOns); 
 const [runTour, setRunTour] = useState(false);
  const [steps] = useState([


    {
      target: '.menu-item:nth-child(2)', // My Account
      content: t('onboarding.myAccount'),
      disableBeacon: true
    },
    {
      target: '.menu-item:nth-child(3)', // Job Openings
      content: t('onboarding.jobOpenings'),
      disableBeacon: true
    },
    {
      target: '.menu-item:nth-child(4)', // Tracking Boards
      content: t('onboarding.trackingBoards'),
      disableBeacon: true
    },
    {
      target: '.menu-item:nth-child(5)', // Candidates
      content: t('onboarding.candidates'),
      disableBeacon: true
    },
    {
      target: '.menu-item:nth-child(6)', // Talexa
      content: t('onboarding.talexa'),
      disableBeacon: true
    },
    {
      target: '.menu-item:nth-child(7)', // Job Board
      content: t('onboarding.jobBoard'),
      disableBeacon: true
    },
    {
      target: '.menu-item:nth-child(8)', // Add-ons
      content: t('onboarding.addons'),
      disableBeacon: true
    },
    {
      target: '.menu-item:nth-child(9)', // Help
      content: t('onboarding.help'),
      disableBeacon: true
    },
  ]);



 // useEffect(() => {
     //Activer le tour à chaque chargement de la page pour le test.
  //setRunTour(true);
  //}, []);


  useEffect(() => {
    const isDashboardPage = location.pathname === "/b2b/dashboard" || location.pathname === "/b2b/dashboard/";
  
    if (isDashboardPage && !isLoading && dashboardData) {
      console.log("✅ Données chargées, vérification du tour");
  
      // Vérifie que l'ID utilisateur est bien récupéré
      const userId = dashboardData?.userId || dashboardData?.firstName || `user_${new Date().getTime()}`;
      const hasVisitedKey = `hasVisitedDashboard_${userId}`;
  
      console.log("🆔 Clé utilisée :", hasVisitedKey);
  
      const isFirstVisitSession = !sessionStorage.getItem(hasVisitedKey);
      const isFirstVisitOverall = !localStorage.getItem(hasVisitedKey);
  
      console.log("🔍 Première visite session :", isFirstVisitSession);
      console.log("🔍 Première visite globale :", isFirstVisitOverall);
  
      if (isFirstVisitSession && isFirstVisitOverall) {
        setTimeout(() => {
          const targetElement = document.querySelector('.menu-item:nth-child(2)');
          console.log("🔍 Élément ciblé trouvé :", targetElement);
  
          if (targetElement) {
            setRunTour(true);
            sessionStorage.setItem(hasVisitedKey, 'true');
            localStorage.setItem(hasVisitedKey, 'true');
            console.log("🚀 Tour lancé !");
          } else {
            console.log("❌ Élément ciblé introuvable.");
          }
        }, 1500);
      }
    }
  }, [isLoading, dashboardData, location.pathname]);
  
  



  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setIsLoading(true);

        const response = await fetch(`${API_URL}/dashboard-data`, {
          credentials: "include",
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(
            errorData.message || `HTTP error! status: ${response.status}`
          );
        }
        const data = await response.json();
        console.log('Fetched settings:', data);
        
        console.log(data);
        setDashboardData(data);

        // Commented out because it's not used just test for confirm data contain schedule interview
        // console.log("Dashboard Data:", dashboardData);
        // if (!dashboardData) {
        //   console.log("No dashboard data received.");
        //   return null;
        // }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  useEffect(() => {
    // Remplacer l'entrée actuelle dans l'historique
    window.history.replaceState(null, "", window.location.href);
  }, [navigate]);

  const isMyProfilePage = location.pathname.includes("/my-profile");
  const isTalexaPage = location.pathname.includes("/talexa");
  const isDashboardMainPage =
    location.pathname === "/b2b/dashboard" ||
    location.pathname === "/b2b/dashboard/";

  if (isLoading) return <div>{t('common.loading')}</div>;
  if (error) return <div>{t('common.error', { message: error })}</div>;
  if (!dashboardData) return null;

  return (
    <ThemeProvider>
      <div className="dashboardcompany-layout">

      <Joyride
          steps={steps}
          run={runTour}
          continuous
          showSkipButton
          disableBeacon={true}
          spotlightClicks={true}
          styles={{
            options: {
              primaryColor: '#34c759',
              zIndex: 10000,
            },
          }}
        />
        <DashboardHeader userAddOns={userAddOns} />
        <main className="dashboardcompany-content">
          {isDashboardMainPage && !isTalexaPage && (
            <>
              <WelcomeMessage firstName={dashboardData.firstName || t('common.defaultUser')} />
              <div className="dashboard-summary">
                {dashboardData.jobOpeningsCount > 0 ? (
                  <JobOpenings
                    viewMode="list"
                    count={dashboardData.jobOpeningsCount}
                  />
                ) : (
                  <div className="no-data">{t('dashboard.noJobOpenings')}</div>
                )}
                {dashboardData.candidatesCount > 0 ? (
                  <CandidatePool count={dashboardData.candidatesCount} />
                ) : (
                  <div className="no-data">{t('dashboard.noCandidates')}</div>
                )}
              </div>
              <div className="dashboard-secondary">
                {dashboardData.trackingBoards && dashboardData.trackingBoards.length > 0 ? (
                  <TrackingBoardListing boards={dashboardData.trackingBoards} />
                ) : (
                  <div className="no-data">{t('dashboard.noTrackingBoards')}</div>
                )}
                {dashboardData.scheduledInterviews && dashboardData.scheduledInterviews.length > 0 ? (
                  <ScheduledInterviews interviews={dashboardData.scheduledInterviews} />
                ) : (
                  <div className="no-data">{t('dashboard.noScheduledInterviews')}</div>
                )}
              </div>
            </>
          )}
          {!isDashboardMainPage && (children || <Outlet />)}
        </main>
      </div>
    </ThemeProvider>
  );
};

export default B2BDashboardLayout;
