import React, { useState, useContext, useEffect  } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './B2BDashboardHeader.scss';
import { logError } from '../../../services/errorLogging'; 
import { API_URL } from '../../../shared';
import { FaHome } from 'react-icons/fa';
import { ThemeContext } from './ThemeContext';  
import './theme.scss';
import Settings from './Settings';

const DashboardHeader = ({ userAddOns }) => {
  const { customColors, logo } = useContext(ThemeContext);
  const [currentColors, setCurrentColors] = useState(customColors);
  const [currentLogo, setCurrentLogo] = useState(logo);
 
  const { t } = useTranslation();
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isDashboardMainPage = location.pathname === '/b2b/dashboard' || location.pathname === '/b2b/dashboard/';


  useEffect(() => {
    const handleThemeChange = (event) => {
      setCurrentColors(event.detail);
    };

    const handleLogoChange = (event) => {
      console.log('Logo updated:', event.detail.logo);  
      setCurrentLogo(event.detail.logo);  
    };

    window.addEventListener('themeChange', handleThemeChange);
    window.addEventListener('logoChange', handleLogoChange);

    return () => {
      window.removeEventListener('themeChange', handleThemeChange);
      window.removeEventListener('logoChange', handleLogoChange);
    };
  }, []);
 

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        activeDropdown &&  
        !event.target.closest('.menu-item.has-dropdown')  
      ) {
        setActiveDropdown(null);  
      }
    };
  
    document.addEventListener('click', handleOutsideClick);
  
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [activeDropdown]);

  const toggleDropdown = (menu) => {
    try {
      setActiveDropdown(prevState => prevState === menu ? null : menu);
    } catch (error) {
      logError(error); 
    }
  };

  const handleLogout = async () => {
    try {

      const response = await fetch(`${API_URL}/logout`, {
        credentials: 'include',
      });
  
      if (!response.ok) {
        throw new Error('Authentication check failed');
      }
      // Supprimez les informations de l'utilisateur du sessionStorage
      sessionStorage.removeItem('user');
      sessionStorage.clear();
      // Supprimez le token d'authentification (si vous en utilisez un)
      localStorage.removeItem('authToken');
      // Redirigez l'utilisateur vers la page de connexion
      navigate('/login');
    } catch (error) {
      logError(error);
    }
  };

  const handleNavigation = (path) => {
    console.log("Navigating to:", path);
    navigate(path);
  };
  const { addOnWhiteLabel } = useContext(ThemeContext);
  const menuItems = [
    {
      name: 'myAccount',
      dropdown: [
        { 
          name: 'mySuperProfile', 
          link: '/b2b/dashboard/my-profile'
        },
        { 
          name: 'subscription', 
          link: '/b2b/dashboard/subscription'
        },
        // suppression de la page billing => tout est géré dans la page subscription
        // { 
        //   name: 'billing', 
        //   link: '/b2b/dashboard/billing'
        // },

        addOnWhiteLabel && {  
          name: 'mySettings',
          link: '/b2b/dashboard/settings',
          isAddOn: true,
          addOnKey: 'whiteLabel'
        },

        { name: 'logOut', action: handleLogout }
      ].filter(Boolean),
    },
    {
      name: 'jobOpenings',
      dropdown: [
        { name: 'createJobOpening', link: '/b2b/dashboard/job-openings/create' },
        { name: 'hiringRequests', link: '/b2b/dashboard/hiring-requests' },
        { name: 'jobOpeningsList', link: '/b2b/dashboard/job-openings' }
      ]
    },
    {
      name: 'trackingBoards',
      link: '/b2b/dashboard/tracking-boards'
    },
    {
      name: 'candidates',
      dropdown: [
        { name: 'addCandidate', link: '/b2b/dashboard/create-candidate' },
        { name: 'myPool', link: '/b2b/dashboard/candidates' },
        { name: 'sharedPool', link: '/b2b/dashboard/candidates/shared' },
        { name: 'receivedApplications', link: '/b2b/dashboard/applications' },
        { name: 'candidatePipeline', link: '/b2b/dashboard/candidates/pipeline' },
        { name: 'emailTemplates', link: '/b2b/dashboard/email-templates' },
        { name: 'interviewsScheduled', link: '/b2b/dashboard/scheduled-interviews' }
      ]
    },
    {
      name: 'talexa',
      onClick: () => handleNavigation('/b2b/dashboard/talexa')
    },
    {
      name: 'jobBoard',
      link: '/b2b/dashboard/job-board'
    },
    {
      name: 'addons',
      dropdown: [
        { 
          name: 'aiPhoneInterviews', 
          link: '/b2b/dashboard/ai-phone-interviews',
          isAddOn: true,
          addOnKey: 'aiPhoneCall'
        },
        { 
          name: 'assessments', 
          link: '/b2b/dashboard/assessments',
          isAddOn: true,
          addOnKey: 'add_on_skills_assessment'
        },
        { 
          name: t('analytics.companyHiring'), 
          link: '/b2b/dashboard/company-hiring-trends',
          isAddOn: true,
          addOnKey: 'analytics'
        },
        { 
          name: t('analytics.hiringMarket'), 
          link: '/b2b/dashboard/hiring-market-trends',
          isAddOn: true,
          addOnKey: 'analytics'
        }
      ]
    },
    {
      name: 'help',
      dropdown: [
        { 
          name: 'eLearningVideos', 
          link: '/b2b/dashboard/e-learning'
        },
        { 
          name: 'clientSupport', 
          link: '/b2b/dashboard/support-tickets'
        }
      ]
    }
  ];

 

  return (
<header className="dashboard-header" style={{ backgroundColor: currentColors.primaryColor }} key={currentLogo}>
{}
       
      
      <nav>

     
        <ul className="menu">

        <div className="header-left">


    <div className="logo-container">
      {currentLogo && (
        <img src={currentLogo} alt={t('dashboard.companyLogo')} className="company-logo-circle" />
      )}
    </div>
   

          {!isDashboardMainPage && (
            <li className="menu-item home-icon">
              <Link to="/b2b/dashboard" className="menu-link">
                <FaHome className="dashboard-home-icon" />
              </Link>
            </li>



          )} </div>
          {menuItems.map((item) => {
            const shouldDisplay = item.isAddOn ? true : true;
  
            return shouldDisplay ? (
              <li
                key={item.name}
                className={`menu-item ${item.dropdown ? 'has-dropdown' : ''}`}
              >
                {item.dropdown ? (
                  <>
                    <button
                      className="menu-link dropdown-toggle"
                      onClick={() => toggleDropdown(item.name)}
                      aria-haspopup="true"
                      aria-expanded={activeDropdown === item.name}
                    >
                      {t(`dashboard.${item.name}`)}
                    </button>
                    <ul
                      className={`dropdown-menu ${
                        activeDropdown === item.name ? 'active' : ''
                      }`}
                    >
                      {item.dropdown.map((subItem) => {
                        const isDisabled = subItem.isAddOn ? false : false;
                        return (
                          <li
                            key={
                              typeof subItem === 'string'
                                ? subItem
                                : subItem.name
                            }
                          >
                            {subItem.action ? (
                              <button
                                onClick={subItem.action}
                                className={`dropdown-item ${
                                  isDisabled ? 'disabled' : ''
                                }`}
                                disabled={isDisabled}
                              >
                                {t(`dashboard.${subItem.name}`)}
                              </button>
                            ) : subItem.link ? (
                              <Link
                                to={subItem.link}
                                className={`dropdown-item ${
                                  isDisabled ? 'disabled' : ''
                                }`}
                                onClick={() => setActiveDropdown(null)}
                                tabIndex={isDisabled ? -1 : undefined}
                                style={
                                  isDisabled ? { pointerEvents: 'none' } : undefined
                                }
                              >
                                {t(`dashboard.${subItem.name}`)}
                              </Link>
                            ) : (
                              <span
                                className={`dropdown-item ${
                                  isDisabled ? 'disabled' : ''
                                }`}
                              >
                                {t(`dashboard.${subItem}`)}
                              </span>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ) : item.link ? (
                  <Link to={item.link} className="menu-link">
                    {t(`dashboard.${item.name}`)}
                  </Link>
                ) : (
                  <button className="menu-link" onClick={item.onClick}>
                    {t(`dashboard.${item.name}`)}
                  </button>
                )}
              </li>
            ) : null;
          })}
        </ul>
      </nav>
    </header>
  );
};

export default DashboardHeader;