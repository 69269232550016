import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../../shared.js";
import "./CreateAssessment.scss";

const CreateSoftSkillsAssessment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [jobOpenings, setJobOpenings] = useState([]);
  const [selectedJob, setSelectedJob] = useState("");

  // use effect to fetch job openings
  useEffect(() => {
    const fetchJobOpenings = async () => {
      try {
        const response = await fetch(`${API_URL}/job-offers`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Job Openings:", data);
        setJobOpenings(data);
      } catch (err) {
        console.error("Error fetching job openings:", err);
      }
    };

    fetchJobOpenings(); // Appeler la fonction
  }, []); // Tableau de dépendances vide : exécution unique au montage

  // Mock data for job openings
  // const jobOpenings = [
  //   { id: 1, title: "Senior Software Engineer" },
  //   { id: 2, title: "Product Manager" },
  // ];

  const handleSubmit = (e) => {
    e.preventDefault();

    const selectedJobOpening = jobOpenings.find(
      (job) => job.id === selectedJob
    );

    const type = "Soft-Skills";

    if (selectedJobOpening) {
      console.log(
        "Generate Soft Skills Assessment for job:",
        selectedJobOpening.role
      );
      console.log("Type:", type);

      const payload = {
        title: selectedJobOpening.role,
        type,
      };

      console.log("Payload:", payload);
      console.log("Generate Soft Skills Assessment for job:");
      navigate(`/b2b/dashboard/assessments/soft-skills`, {
        state: { title: selectedJobOpening.role, type },
      });
    } else {
      console.error("No job opening selected");
    }
  };

  return (
    <div className="create-assessment">
      <div className="create-assessment-header">
        <h2>{t("assessments.createSoftSkills")}</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="jobOpening">{t("assessments.selectJob")}</label>
          <select
            id="jobOpening"
            value={selectedJob}
            onChange={(e) => setSelectedJob(e.target.value)}
            required
          >
            <option value="" disabled>
              {t("assessments.selectJobOption")}
            </option>
            {jobOpenings.map((job) => (
              <option key={job.id} value={job.id}>
                {job.role}
              </option>
            ))}
          </select>
        </div>
        <div className="form-actions">
          <button type="submit" className="submit-button">
            {t("assessments.generateSoftSkills")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateSoftSkillsAssessment;
