import React from 'react';
import './Spinner.scss';
import { useTranslation } from 'react-i18next';

const Spinner = ({ size = 'medium' }) => {
  const { t } = useTranslation();
  return (
    <div className={`spinner-overlay ${size}`}>
      <div className="spinner"></div>
      <div className="spinner-text">{t('common.loading')}</div>
    </div>
  );
};

export default Spinner; 