import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FooterMenuGeneral from "./FooterMenuGeneral";

import "../i18n";
import "./404Page.scss";
import logo from "../assets/Logo.png";
import logo404 from "../assets/logo404.jpg";
import ukFlag from "../assets/flags/en-flag.png";
import frFlag from "../assets/flags/fr-flag.png";
import esFlag from "../assets/flags/es-flag.png";
import itFlag from "../assets/flags/it-flag.png";
import ptFlag from "../assets/flags/pt-flag.png";
import deFlag from "../assets/flags/de-flag.png";

const NotFoundPage = () => {
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const langMenuRef = useRef(null);

  const toggleLangMenu = () => {
    setIsLangMenuOpen((prev) => !prev);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsLangMenuOpen(false);
  };

  // Fermer le menu si on clique à l'extérieur
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (langMenuRef.current && !langMenuRef.current.contains(event.target)) {
        setIsLangMenuOpen(false); // Ferme le menu si le clic est à l'extérieur
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getCurrentLanguageFlag = () => {
    switch (i18n.language) {
      case "fr":
        return frFlag;
      case "es":
        return esFlag;
      case "it":
        return itFlag;
      case "pt":
        return ptFlag;
      case "de":
        return deFlag;
      default:
        return ukFlag;
    }
  };

  return (
    <div className="not-found-page">
      <header className="header-nav">
        <div className="logo-container">
          <Link to="/" className="logo-link">
            <img src={logo} alt="Logo" className="logo" />
          </Link>
        </div>
        <ul className="nav-links">
          <li className="language-selector" ref={langMenuRef}>
            <button onClick={toggleLangMenu} className="lang-toggle">
              <img
                src={getCurrentLanguageFlag()}
                alt={i18n.language}
                className="flag-icon"
              />
              <span className="current-lang">
                {i18n.language.toUpperCase()}
              </span>
            </button>
            <ul className={`lang-dropdown ${isLangMenuOpen ? "open" : ""}`}>
              <li>
                <button onClick={() => changeLanguage("en")}>
                  <img src={ukFlag} alt="EN" />
                  EN
                </button>
              </li>
              <li>
                <button onClick={() => changeLanguage("fr")}>
                  <img src={frFlag} alt="FR" />
                  FR
                </button>
              </li>
              <li>
                <button onClick={() => changeLanguage("es")}>
                  <img src={esFlag} alt="ES" />
                  ES
                </button>
              </li>
              <li>
                <button onClick={() => changeLanguage("it")}>
                  <img src={itFlag} alt="IT" />
                  IT
                </button>
              </li>
              <li>
                <button onClick={() => changeLanguage("pt")}>
                  <img src={ptFlag} alt="PT" />
                  PT
                </button>
              </li>
              <li>
                <button onClick={() => changeLanguage("de")}>
                  <img src={deFlag} alt="DE" />
                  DE
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </header>

      <div className="logo-404-container">
        <img src={logo404} alt="404 Logo" className="logo-404" />
      </div>

      <main className="main-content">
        <section className="404-section">
          <h1>{t("404.title", "Page Not Found")}</h1>
          <p>
            {t(
              "404.message",
              "Oops! The page you are looking for does not exist."
            )}
          </p>

          <div className="link-container">
            <Link to="/" className="home-link">
              {t("404.returnHome", "Return to Home")}
            </Link>

            <Link to="/login" className="login-link">
              {t("404.goLogin", "Go to Login")}
            </Link>
          </div>
        </section>
        <FooterMenuGeneral />
      </main>
    </div>
  );
};

export default NotFoundPage;
