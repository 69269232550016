import { Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { checkAuthStatus } from './services/authService';

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const verifyAuth = async () => {
      const result = await checkAuthStatus();
      setIsAuthenticated(result.isAuthenticated);
      setLoading(false);
    };
    verifyAuth();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (!isAuthenticated) return <Navigate to="/login" />;
  
  return children;
};

export default ProtectedRoute;
