// const API_URL = process.env.REACT_APP_API_URL;
import { API_URL } from "../shared.js";

const headers = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${localStorage.getItem('token')}`
};

export const getUserProfile = async () => {
  try {
    const response = await fetch(`${API_URL}/profile`, {
      credentials: 'include',
    });
    console.log('Response status:', response.status);
    console.log('Response headers:', response.headers);
    const text = await response.text();
    console.log('Response text:', text);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = JSON.parse(text);
    return data;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

export const uploadProfilePicture = async (file) => {
  const formData = new FormData();
  formData.append("photo", file);

  try {
    const response = await fetch(`${API_URL}/upload-profile-picture`, {
      method: "POST",
      body: formData,
      credentials: "include",  
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result.photoUrl;
  } catch (error) {
    console.error("Error uploading profile picture:", error.message);
    throw error;
  }
};







export const updateUserProfile = async (profileData) => {
  try {
    console.log('Updating profile with data:', profileData);
    const response = await fetch(`${API_URL}/profile`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(profileData),
      credentials: 'include',
    });
    console.log('Update profile response:', response);
    if (!response.ok) {
      const data = await response.json();
      throw new Error(JSON.stringify(data));
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};

export const deleteUserAccount = async () => {
  try {
    const response = await fetch(`${API_URL}/account`, {
      method: 'DELETE',
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error deleting user account:', error);
    throw error;
  }
};
