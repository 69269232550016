import "./JobApplicationDetails.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../../../shared.js";
import ModalAddToTrackingBoard from "./ModalAddToTrackingBoard";


const refuseACandidate = async (jobApplicationId, active) => {
  const response = await fetch(`${API_URL}/refuse-job-application`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ id: jobApplicationId, active }),
  });
  if (!response.ok) {
    throw new Error("Failed to refuse candidate");
  }
  const data = await response.json();
  console.log("Data for refused candidate", data);
  return data;
};

 
const JobApplicationDetails = ({candidate: initialCandidate, onUpdateCandidates}) => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);
  const [candidate, setCandidates] = useState(initialCandidate || null);
  
  if (!candidate) return <p>{t('jobApplicationDetails.noData')}</p>;
  console.log(candidate);

  const handleRefuseClick = async () => {
    if (!candidate) return;

    try {
      console.log(
        `Sending request for JobApplication ID: ${candidate.jobAppId} with active: false`
      );
      await refuseACandidate(candidate.jobAppId, false);

      // Update parent state via callback
      const updatedCandidate = { ...candidate, active: false };
      onUpdateCandidates(updatedCandidate);

      setIsModalOpen(false); // Ferme la modal après confirmation

       closeModal(); // Ferme la modal après confirmation
    } catch (error) {
      console.error("Failed to refuse candidate:", error);
    }
  };

  const closeModal = () => {

    setIsModalOpen(false);
    setIsModalOpen(false);
  };

  useEffect(() => {
    console.log("candidate", candidate);

    if (candidate) {
      const candidateId = candidate.id;

      const fetchDocuments = async () => {
        try {
          const response = await fetch(`${API_URL}/documents/${candidateId}`, {
            credentials: "include",
          });
          const dataDocument = await response.json();
          console.log("documents", dataDocument);

          setDocuments(dataDocument);
        } catch (error) {
          console.error("Error fetching documents", error);
        }
      };

      fetchDocuments();
    }
  }, [candidate]);

  return (
    <div className="job-application-details">
      <div className="job-application-details__header">
        <h2 className="job-application-details__header-title">
          {t('jobApplication.jobApplicationDetails.title')}
        </h2>
      </div>
      <div className="job-application-details__body">
        <div className="job-application-details__body__section">
          <h3 className="job-application-details__body__section-title">
            {t('jobApplication.jobApplicationDetails.sections.applicant.title')}
          </h3>
          <div className="job-application-details__body__section__content">
            <div className="job-application-details__body__section__content__item">
              <span className="job-application-details__body__section__content__item-text">
                {t('jobApplication.jobApplicationDetails.sections.applicant.name')} :
              </span>
              <span className="job-application-details__body__section__content__item-text">
                {candidate.firstName} {candidate.lastName}
              </span>
            </div>
            <div className="job-application-details__body__section__content__item">
              <span className="job-application-details__body__section__content__item-text">
                {t('jobApplication.jobApplicationDetails.sections.applicant.email')} :
              </span>
              <span className="job-application-details__body__section__content__item-text">
                {candidate.email}
              </span>
            </div>
            <div className="job-application-details__body__section__content__item">
              <span className="job-application-details__body__section__content__item-text">
                {t('jobApplication.jobApplicationDetails.sections.applicant.phone')} :
              </span>
              <span className="job-application-details__body__section__content__item-text">
                {candidate.phone}
              </span>
            </div>
            <div className="job-application-details__body__section__content__item">
              <span className="job-application-details__body__section__content__item-text">
                {t('jobApplication.jobApplicationDetails.sections.applicant.resume')} :
              </span>
              <span className="job-application-details__body__section__content__item-text">
                {documents.length > 0
                  ? documents.map((doc) => (
                      <a key={doc.id} href={doc.doc} target="_blank" rel="noopener noreferrer">
                        {doc.title}
                      </a>
                    ))
                  : t('jobApplication.jobApplicationDetails.sections.applicant.noDocuments')}
              </span>
            </div>
          </div>
        </div>
        <div className="job-application-details__body__section">
          <h3 className="job-application-details__body__section-title">
            {t('jobApplication.jobApplicationDetails.sections.job.title')}
          </h3>
          <div className="job-application-details__body__section__content">
            <div className="job-application-details__body__section__content__item">
              <span className="job-application-details__body__section__content__item-text">
                {t('jobApplication.jobApplicationDetails.sections.job.position')} :
              </span>
              <span className="job-application-details__body__section__content__item-text">
                {candidate.jobRole}
              </span>
            </div>
            <div className="job-application-details__body__section__content__item">
              <span className="job-application-details__body__section__content__item-text">
                {t('jobApplication.jobApplicationDetails.sections.job.company')} :
              </span>
              <span className="job-application-details__body__section__content__item-text">
                {candidate.companyName}
              </span>
            </div>
            <div className="job-application-details__body__section__content__item">
              <span className="job-application-details__body__section__content__item-text">
                {t('jobApplication.jobApplicationDetails.sections.job.dateApplied')} :
              </span>
              <span className="job-application-details__body__section__content__item-text">
                {new Date(candidate.createdAt).toLocaleString()}
              </span>
            </div>
          </div>
        </div>
        <div className="job-application-details__body__section__buttons-container">
          <button 
            className="job-application-details__body__section__buttons-container__button-refuse" 
            type="button" 
            onClick={() => setIsModalOpen(true)}
          >
            {t('jobApplication.jobApplicationDetails.actions.refuse')}
          </button>
          <button 
            className="job-application-details__body__section__buttons-container__button-add" 
            type="button" 
            onClick={() => setIsBoardModalOpen(true)}
          >
            {t('jobApplication.jobApplicationDetails.actions.addToBoard')}
          </button>
        </div>
      </div>
      {/* Modal */}
      {isModalOpen && (
        <div className="modal-ReceivedApplications-modal-overlay">
          <div className="modal-ReceivedApplications-refuseCandidate">
            <h3 className="modal-ReceivedApplications-title">
              {t('jobApplication.jobApplicationDetails.modal.refuse.title')}
            </h3>
            <p className="modal-ReceivedApplications-textName">
              {t('jobApplication.jobApplicationDetails.modal.refuse.confirmText', {
                firstName: candidate.firstName,
                lastName: candidate.lastName,
                jobRole: candidate.jobRole
              })}
            </p>
            <p className="modal-ReceivedApplications-text">
              {t('jobApplication.jobApplicationDetails.modal.refuse.warning')}
            </p>
            <div className="modal-ReceivedApplications-email">
              <p className="modal-ReceivedApplications-greating">
                {t('jobApplication.jobApplicationDetails.modal.refuse.email.greeting', {
                  firstName: candidate.firstName,
                  lastName: candidate.lastName
                })}
              </p>
              <p className="modal-ReceivedApplications-email-content">
                {t('jobApplication.jobApplicationDetails.modal.refuse.email.content', {
                  jobRole: candidate.jobRole,
                  companyName: candidate.companyName
                })}
              </p>
              <p className="modal-ReceivedApplications-email-content">
                {t('jobApplication.jobApplicationDetails.modal.refuse.email.thanks')}
              </p>
              <p className="modal-ReceivedApplications-email-content">
                {t('jobApplication.jobApplicationDetails.modal.refuse.email.future')}
              </p>
              <p className="modal-ReceivedApplications-email-end">
                {t('jobApplication.jobApplicationDetails.modal.refuse.email.closing')}
              </p>
            </div>

            <div className="modal-ReceivedApplications-actions">
              <button
                onClick={handleRefuseClick}
                className="modal-ReceivedApplications-actions-buttons"
              >
                {t('jobApplication.jobApplicationDetails.modal.refuse.actions.confirm')}
              </button>
              <button
                onClick={closeModal}
                className="modal-ReceivedApplications-actions-buttons"
              >
                {t('jobApplication.jobApplicationDetails.modal.refuse.actions.cancel')}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* TrackingBoardModal */}
      {
        isBoardModalOpen && (
          <ModalAddToTrackingBoard candidate={candidate} closeModal={() => setIsBoardModalOpen(false)}/>
        ) 
      }
    </div>
  );
};

export default JobApplicationDetails;
