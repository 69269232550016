import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { FiPlus, FiArrowLeft, FiCopy, FiTrash2, FiSave, FiCheck } from 'react-icons/fi';
import './GenerateEmailTemplate.scss';
import { API_URL } from '../../../shared';
import { useTranslation } from 'react-i18next';

const GenerateEmailTemplate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [candidates, setCandidates] = useState([]);
  const [jobOffers, setJobOffers] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedJobOffer, setSelectedJobOffer] = useState(null);
  const [emailType, setEmailType] = useState('first');
  const [isLoading, setIsLoading] = useState(false);
  const [generatedEmail, setGeneratedEmail] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [language, setLanguage] = useState('english');

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch candidates from My Pool
        const candidatesRes = await fetch(`${API_URL}/candidates`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        });
        const candidatesData = await candidatesRes.json();

        // Format candidates data for react-select
        const formattedCandidates = candidatesData.map(c => ({
          value: c.id,
          label: `${c.first_name} ${c.last_name}`,
          email: c.email
        }));
        setCandidates(formattedCandidates);

        // Fetch job offers
        const jobOffersRes = await fetch(`${API_URL}/job-offers`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        });
        const jobOffersData = await jobOffersRes.json();

        // Format job offers data for react-select, showing only the role
        const formattedJobOffers = jobOffersData.map(job => ({
          value: job.id,
          label: job.role,
          company: job.Company?.name
        }));
        setJobOffers(formattedJobOffers);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setGeneratedEmail(null);

    try {
      console.log('Sending preview request with data:', {
        candidate_id: selectedCandidate.value,
        job_offer_id: selectedJobOffer.value,
        emailType,
        language,
        preview: true
      });

      const response = await fetch(`${API_URL}/email-templates`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          candidate_id: selectedCandidate.value,
          job_offer_id: selectedJobOffer.value,
          emailType,
          language,
          preview: true
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to generate email');
      }

      const data = await response.json();
      console.log('Generated email:', data);
      setGeneratedEmail(data);
    } catch (error) {
      console.error('Error generating email:', error);
      alert(error.message || 'An unexpected error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(generatedEmail.content);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  const handleDeleteEmail = () => {
    setGeneratedEmail(null);
  };

  const handleSaveEmail = async () => {
    if (!generatedEmail || !generatedEmail.content || !generatedEmail.subject) {
      alert('No email content to save');
      return;
    }

    try {
      const emailData = {
        name: `${emailType} - ${selectedJobOffer.label}`,
        subject: generatedEmail.subject,
        content: generatedEmail.content,
        candidate_id: selectedCandidate.value,
        job_offer_id: selectedJobOffer.value,
        is_template: false,
        is_sent: false,
        preview: false
      };

      console.log('Saving email with data:', emailData);

      const response = await fetch(`${API_URL}/email-templates`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(emailData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to save email');
      }

      setSaveSuccess(true);
      setTimeout(() => {
        setSaveSuccess(false);
        navigate('/b2b/dashboard/email-templates');
      }, 2000);
    } catch (error) {
      console.error('Error saving email:', error);
      alert(error.message || 'An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div className="generate-email-container">
      <div className="generate-email-box">
        <h1>{t('emailTemplates.generate.title')}</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>{t('emailTemplates.generate.fields.candidate')}</label>
            <Select
              options={candidates}
              value={selectedCandidate}
              onChange={setSelectedCandidate}
              isSearchable
              placeholder={t('emailTemplates.generate.placeholders.selectCandidate')}
              noOptionsMessage={() => t('emailTemplates.generate.noOptions.candidates')}
            />
          </div>

          <div className="form-group">
            <label>{t('emailTemplates.generate.fields.jobOpening')}</label>
            <Select
              options={jobOffers}
              value={selectedJobOffer}
              onChange={setSelectedJobOffer}
              isSearchable
              placeholder={t('emailTemplates.generate.placeholders.selectJob')}
              noOptionsMessage={() => t('emailTemplates.generate.noOptions.jobs')}
            />
          </div>

          <div className="form-group">
            <label>{t('emailTemplates.generate.fields.emailType')}</label>
            <select
              value={emailType}
              onChange={(e) => setEmailType(e.target.value)}
            >
              <option value="first">{t('emailTemplates.generate.types.first')}</option>
              <option value="interview">{t('emailTemplates.generate.types.interview')}</option>
              <option value="followup">{t('emailTemplates.generate.types.followup')}</option>
            </select>
          </div>

          <div className="form-group">
            <label>{t('emailTemplates.generate.fields.language')}</label>
            <select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              {['english', 'french', 'spanish', 'portuguese', 'italian', 'german'].map((lang) => (
                <option key={lang} value={lang}>
                  {t(`emailTemplates.generate.languages.${lang}`)}
                </option>
              ))}
            </select>
          </div>

          <button 
            type="submit" 
            className="generate-button"
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="loading-spinner">
                <div className="spinner"></div>
                <span>{t('emailTemplates.generate.generating')}</span>
              </div>
            ) : (
              t('emailTemplates.generate.button')
            )}
          </button>
        </form>

        {generatedEmail && (
          <div className="generated-email-container">
            <div className="generated-email-header">
              <h3>{t('emailTemplates.generate.generatedEmail')}</h3>
              <div className="email-actions">
                <button 
                  className={`copy-button ${copySuccess ? 'success' : ''}`}
                  onClick={handleCopyToClipboard}
                >
                  <FiCopy /> {copySuccess ? t('emailTemplates.generate.actions.copy.success') : t('emailTemplates.generate.actions.copy.copy')}
                </button>
                <button 
                  className={`save-button ${saveSuccess ? 'success' : ''}`}
                  onClick={handleSaveEmail}
                >
                  <FiSave /> {saveSuccess ? t('emailTemplates.generate.actions.save.success') : t('emailTemplates.generate.actions.save.save')}
                </button>
                <button 
                  className="delete-button"
                  onClick={handleDeleteEmail}
                >
                  <FiTrash2 />
                </button>
              </div>
            </div>
            <div className="email-content">
              <h4>Subject: {generatedEmail.subject}</h4>
              <div className="email-body">
                {generatedEmail.content}
              </div>
            </div>
          </div>
        )}

        <div className="return-button" onClick={() => navigate("/b2b/dashboard/email-templates")}>
          <FiArrowLeft className="return-icon" />
          <span>{t('emailTemplates.generate.returnToAllEmails')}</span>
        </div>
      </div>
    </div>
  );
};

export default GenerateEmailTemplate; 