import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import parse from "html-react-parser"; // librairy to parse html content
import * as sanitizeHtml from "sanitize-html"; // librairy to sanitize html content
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import { FaEdit, FaSave, FaUserPlus } from "react-icons/fa";
import "./AssessmentForm.scss";
import { API_URL } from "../../../../shared.js";



const AssessmentForm = () => {
  const { t } = useTranslation();
  const [htmlContent, setHtmlContent] = useState("");
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isTraining, setIsTraining] = useState(false);
  const [candidateContent, setcandidateContent] = useState([]);
  const [email, setEmail] = useState("");
  const { id } = useParams();

  const handleSaveCandidateContent = async (e) => {
    e.preventDefault();
    // console.log("click")
    try {
      const response = await fetch(`${API_URL}/assessment/candidate-content`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          candidate_content: candidateContent,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      if (result.success) {
        alert("Données sauvegardées avec succès.");
      } else {
        console.error("Erreur lors de la sauvegarde :", result.message);
      }
    } catch (error) {
      console.error("Erreur lors de la sauvegarde :", error);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      const tokenAssessmentId = id;
      try {
        const response = await fetch(`${API_URL}/assessment/${tokenAssessmentId}/content`, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const result = await response.json();
        console.log("result", result.analysis_content);
        const sanitized = result.analysis_content;
  
        // Parse HTML content into a DOM structure
        const parser = new DOMParser();
        const doc = parser.parseFromString(sanitized, "text/html");
  
        // Extract title
        const title = doc.querySelector("h1")?.textContent;
  
        // Extract sections
        const sections = Array.from(doc.querySelectorAll("section")).map((section, index) => {
          const sectionTitle = section.querySelector("h2")?.textContent || `Section ${index + 1}`;
          const questions = Array.from(section.querySelectorAll("li")).map((li) => {
            const questionText = li.querySelector("p")?.textContent;
            const options = Array.from(li.querySelectorAll("input[type='radio']")).map((input) => {
              const labelNode = input.nextSibling?.nodeValue?.trim() || input.parentElement?.textContent?.trim();
              return {
                label: labelNode || "Option",
                value: input.value,
              };
            });
            
            return {
              text: questionText,
              options,
            };
          });
  
          return {
            title: sectionTitle,
            questions,
          };
        });

        console.log("Sections extraites :", sections);
        sections.forEach((section, index) => {
          console.log(`Section ${index + 1} - ${section.title}`, section.questions);
        });
  
        // Save structured data
        const jsonContent = { title, sections };
        console.log("Parsed JSON content:", jsonContent);
        setData(jsonContent);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [id]);
  

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const tokenAssessmentId = id;
  //     console.log("assessmentId", tokenAssessmentId);
  //     // console.log("use params", req.params);
  //     try {
  //       const response = await fetch(`${API_URL}/assessment/${tokenAssessmentId}/content`, {
  //         method: "GET",
  //         headers: { "Content-Type": "application/json" },
  //         // credentials: "include",
  //       });
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }
  //       const result = await response.json();
  //       console.log("result", result);

  //       let sanitized = result.analysis_content;
  //       console.log("sanitized", sanitized);
  //       // Vérification simple
  //       if (sanitized.startsWith('"') && sanitized.endsWith('"')) {
  //         // Supprimer les guillemets et déséchapper
  //         sanitized = sanitized
  //           .slice(1, -1)
  //           .replace(/\\"/g, '"')
  //           .replace(/\\n/g, "");
  //       }
  //       setHtmlContent(sanitized);
  //       setData(result);
  //     } catch (err) {
  //       console.error("Error fetching data:", err);
  //       setError(err.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [id]);

  const handleCandidateSelection = (selectedContent) => {
    setcandidateContent((prevContent) => [...prevContent, selectedContent]);
  };

  const handleElementClick = (e) => {
    const textContent = e.target.textContent || "";

    const targetValue = e.target.value;
    console.log("target", e.target)
    console.log("textContent", textContent);

    console.log("targetValue", targetValue);
    handleCandidateSelection(textContent);
  };
    
  return (
    <div className="assessmentForm-page">
      <div className="header">
        <h1>{data?.title || "Chargement..."}</h1>
      </div>
  
      <div className="content-section">
        {loading && <ClipLoader color="#6920b0" size={60} />}
        {error && <p className="error">Error: {error}</p>}
  
        {!loading && !error && data?.sections?.map((section, index) => (
          <section key={index}>
            <h2>{section.title}</h2>
            
              <ol>
                {section.questions.map((q, qIndex) => (
                  <li key={qIndex}>
                    <p>{q.text}</p>
                    {q.options.map((option, oIndex) => (
                      <label key={oIndex}>
                        <input
                          type="radio"
                          name={`q${qIndex}`}
                          value={option.value}
                          onChange={() => handleSelection(`q${qIndex}`, option.value)}
                        />
                        {option.label}
                      </label>
                    ))}
                  </li>
                ))}
              </ol>
            
          </section>
        ))}
      </div>
  
      <div className="actions">
        <button type="submit" className="save-btn" onClick={handleSaveCandidateContent}>
          <FaSave /> Send
        </button>
      </div>
    </div>
  );
      

  // return (
  //   <>
  //   <div className="assessmentForm-page">
  //     <div className="header">
  //       <h1>Language-Skills Assessment</h1>
  //     </div>

  //     <div className="content-section">
  //       {loading && (
  //         <div className="loading-spinner">
  //           <ClipLoader color="#6920b0" size={60} />
  //         </div>
  //       )}
  //       {error && <p className="error">Error: {error}</p>}

  //       {/* AFFICHAGE DIRECT DU HTML */}
  //       {htmlContent && <div className="html-render-assessmentForm" onClick={handleElementClick}>{parse(htmlContent)}</div>}
  //       {console.log("selection", candidateContent)}
  //     </div>

  //     <div className="actions">
  //       <button type="submit" className="save-btn" onClick={handleSaveCandidateContent}>
  //         <FaSave /> Send
  //       </button>
  //     </div>
  //   </div>
  // </>
  // );
};

export default AssessmentForm;
