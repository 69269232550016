import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./ResetPasswordPage.scss";
import { API_URL } from "../shared";


const ResetPasswordPage = () => {
  const { token } = useParams();  
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");


    const validateToken = async () => {
      try {
        console.log("Sending the request to the API to validate the token...");
        const response = await fetch(`${API_URL}/reset-password/${token}`);
        const data = await response.json();

        console.log("API response:", response.status, data);
        console.log("Token used:", token);
        console.log("Loading : ", loading);
        console.log("Error detected:", error);


        if (response.status === 200) {
          setIsValid(true);  
        } else {
          setError(data.message || "Invalid or expired token.");
        }
      } catch (err) {

        console.error("Error during token validation:", err);
        setError("Error during validation.");
      } finally {
        setLoading(false); 
      }
    };

    validateToken();
 

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      
      const response = await fetch(`${API_URL}/reset-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, newPassword }),
      });




      const data = await response.json();
      console.log("Réponse API :", data);

      if (response.ok) {
        setSuccessMessage("Password reset successfully!");
        setTimeout(() => navigate("/login"), 3000);
        setError(null);  
      
      } else {
        setError(data.message || "Error during reset.");
      }


    } catch (err) {
      console.error("Error during reset :", err);
      setError("Error during reset.");
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }
  
  if (successMessage) {
     
    return <p className="success-mdp">{successMessage}</p>;
  }
  
  if (error) {
    
    return <p>{error}</p>;
  }
  
  if (isValid) {
     
    return (
      <div className="reset-password-page">
        <form onSubmit={handleResetPassword}>
          <h2>Reset Password</h2>
          <input
            type="password"
            placeholder="New password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <button type="submit">Reset</button>
        </form>
      </div>
    );
  }
  
 
  return <p>An unexpected error has occurred.</p>;
};

export default ResetPasswordPage;
