import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './CandidateDetailsPool.scss';
import { useTranslation } from 'react-i18next';
import { API_URL } from '../../../shared.js';
import { W_API_URL } from '../../../sharedWithoutApi.js';
import JobApplicationDetails from "./Modals/JobApplicationDetails";



const CandidateDetailsPool = ({ id: propId, readOnly = false }) => {
  const { id: paramId } = useParams();
  const [jobApplications, setJobApplications] = useState([]);

  const id = propId || paramId;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [candidate, setCandidate] = useState({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    zip_code: "",
    country: "",
    linkedin_url: "",
    summary: "",
    skills: [],
    education: "",
    experience: "",
    certifications: "",
    languages: "",
    interests: "",
    availability: "",
    desired_salary: "",
    notice_period: "",
    visa_status: "",
    relocation_willingness: "",
    work_arrangement: "",
    resume: "",
  });
  const [academicExperiences, setAcademicExperiences] = useState([]);
  const [workExperiences, setWorkExperiences] = useState([]);
  const [languages, setLanguages] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    fetchCandidate();
  }, [id]);

  const fetchCandidate = async () => {
    try {
      console.log("Fetching candidate with ID:", id);
      const response = await fetch(`${API_URL}/candidates/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch candidate");
      }

      const data = await response.json();

      console.log("data", data);

      const responseJobApplication = await fetch(`${API_URL}/job-applications/${data.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      const dataApplication = await responseJobApplication.json();
      console.log("responseJobApplication", dataApplication);
      
      if (dataApplication.length > 0 && dataApplication[0].Job_Offer) {
        setCandidate({
          ...data,
          resume: data.resume || "",  
          jobId: dataApplication[0].Job_Offer.id,
          jobRole: dataApplication[0].Job_Offer.role,
          companyName: dataApplication[0].Job_Offer.company_name,
          createdAt: dataApplication[0].Job_Offer.created_at || dataApplication[0].created_at,
        });
      
        setJobApplications(dataApplication);   
      } else {
        setCandidate({
          ...data,
          resume: data.resume || "",
        });
      
        setJobApplications([]);   
      }





      setAcademicExperiences(data.AcademicExperiences || []);
      setWorkExperiences(data.WorkExperiences || []);
      setLanguages(data.Languages || []);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

   






  const handleChange = (e) => {
    const { name, value } = e.target;
    setCandidate((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDownload = () => {
    if (candidate.resume) {
      const filePath = `${W_API_URL.replace(/\/$/, "")}/${candidate.resume.replace(/^\//, "")}`;

      console.log("URL générée :", filePath); // Vérifiez cette sortie
      window.open(filePath, "_blank");
    } else {
      alert("No CV available");
    }
  };

 const handleFileChange = async (e) => {
  const file = e.target.files[0];
  if (!file) return;

  const formData = new FormData();
  formData.append("resume", file);
  formData.append("candidateId", candidate.id);  // Envoi de l'ID du candidat

  try {
    const response = await fetch(`${API_URL}/uploadResume`, {
      method: "POST",
      body: formData,
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Failed to upload resume");
    }

    const data = await response.json();
    alert("Resume updated successfully!");

    // Met à jour localement le candidat avec le nouveau CV
    setCandidate((prev) => ({
      ...prev,
      resume: data.resumeUrl.startsWith("http")
        ? data.resumeUrl.replace(`${W_API_URL}/`, "")
        : data.resumeUrl,
    }));

    // Recharge les données pour s'assurer que tout est à jour
    await fetchCandidate();
  } catch (error) {
    console.error("Error uploading file:", error);
    alert("Error uploading file. Please try again.");
  }
};

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const response = await fetch(`${API_URL}/candidates/${id}`, {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...candidate,
          academicExperiences,
          workExperiences,
          languages,
        }),
        credentials: "include",
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to update candidate");
      }

      setCandidate(data);
      setTimeout(() => {
        navigate("/b2b/dashboard/candidates");
      }, 500);
    } catch (error) {
      console.error("Error updating candidate:", error);
      if (!candidate) {
        setError(error.message || "Error updating candidate");
      } else {
        navigate("/b2b/dashboard/candidates");
      }
    }
  };

  if (loading)
    return <div className="loading">{t("candidateDetails.loading")}</div>;
  if (error)
    return (
      <div className="error">
        {t("candidateDetails.error", { message: error })}
      </div>
    );
  if (!candidate)
    return <div className="not-found">{t("candidateDetails.notFound")}</div>;

  return (
    <div className="candidate-details-pool">
      <div className="candidate-details-header">
        <h2>
          {readOnly
            ? t("candidateDetails.title.view")
            : t("candidateDetails.title.edit")}
        </h2>
      </div>

      <div className="candidate-details-body">
        {/* Autres détails */}



        <div className="form-group resume-section">
  {candidate.resume ? (
    <button className="download-button" onClick={handleDownload}>
      {t("candidateDetails.resume.download", "Display CV")}
    </button>
  ) : (
    <p className="no-resume-text">
      {t("candidateDetails.resume.noFile", "No CV available")}
    </p>
  )}


   
  <input
    type="file"
    accept=".pdf"
    id="fileInput"
    onChange={handleFileChange}
    disabled={readOnly}
    style={{ display: 'none' }}  
  />
  <button
  className={`download-button ${readOnly ? 'disabled-button' : ''}`}
  type="button"
  onClick={() => document.getElementById('fileInput').click()}
  disabled={readOnly}
>
  Replace CV
</button>
</div>

      </div>





      <form onSubmit={handleSubmit}>
        <div className="form-grid">
          <div className="form-group">
            <label htmlFor="first_name">
              {t("candidateDetails.personalInfo.firstName")}
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={candidate.first_name}
              onChange={handleChange}
              disabled={readOnly}
            />
          </div>

          <div className="form-group">
            <label htmlFor="last_name">
              {t("candidateDetails.personalInfo.lastName")}
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={candidate.last_name}
              onChange={handleChange}
              disabled={readOnly}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">
              {t("candidateDetails.personalInfo.email")}
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={candidate.email}
              onChange={handleChange}
              disabled={readOnly}
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone">
              {t("candidateDetails.personalInfo.phone")}
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={candidate.phone || ""}
              onChange={handleChange}
              disabled={readOnly}
            />
          </div>

          <div className="form-group full-width">
            <label htmlFor="linkedin_url">
              {t("candidateDetails.personalInfo.linkedinUrl")}
            </label>
            <input
              type="url"
              id="linkedin_url"
              name="linkedin_url"
              value={candidate.linkedin_url || ""}
              onChange={handleChange}
              disabled={readOnly}
            />
          </div>

          <div className="form-group full-width">
            <label htmlFor="summary">
              {t("candidateDetails.personalInfo.summary")}
            </label>
            <textarea
              id="summary"
              name="summary"
              value={candidate.summary || ""}
              onChange={handleChange}
              disabled={readOnly}
            />
          </div>
        </div>

        <div className="form-section">
          <h3>{t("candidateDetails.academicExperience.title")}</h3>
          {academicExperiences.map((exp, index) => (
            <div key={exp.id || index} className="experience-item">
              <input
                type="text"
                value={exp.school_name || ""}
                onChange={(e) => {
                  const newExps = [...academicExperiences];
                  newExps[index].school_name = e.target.value;
                  setAcademicExperiences(newExps);
                }}
                disabled={readOnly}
                placeholder={t(
                  "candidateDetails.academicExperience.schoolName"
                )}
              />
              <input
                type="text"
                value={exp.degree || ""}
                onChange={(e) => {
                  const newExps = [...academicExperiences];
                  newExps[index].degree = e.target.value;
                  setAcademicExperiences(newExps);
                }}
                disabled={readOnly}
                placeholder={t("candidateDetails.academicExperience.degree")}
              />
              <input
                type="text"
                value={exp.field_of_study || ""}
                onChange={(e) => {
                  const newExps = [...academicExperiences];
                  newExps[index].field_of_study = e.target.value;
                  setAcademicExperiences(newExps);
                }}
                disabled={readOnly}
                placeholder={t(
                  "candidateDetails.academicExperience.fieldOfStudy"
                )}
              />
              <input
                type="date"
                value={
                  exp.start_date
                    ? new Date(exp.start_date).toISOString().split("T")[0]
                    : ""
                }
                onChange={(e) => {
                  const newExps = [...academicExperiences];
                  newExps[index].start_date = e.target.value;
                  setAcademicExperiences(newExps);
                }}
                disabled={readOnly}
              />
              <input
                type="date"
                value={
                  exp.end_date
                    ? new Date(exp.end_date).toISOString().split("T")[0]
                    : ""
                }
                onChange={(e) => {
                  const newExps = [...academicExperiences];
                  newExps[index].end_date = e.target.value;
                  setAcademicExperiences(newExps);
                }}
                disabled={readOnly}
              />
              {!readOnly && (
                <button
                  type="button"
                  className="remove-button"
                  onClick={() => {
                    const newExps = academicExperiences.filter(
                      (_, i) => i !== index
                    );
                    setAcademicExperiences(newExps);
                  }}
                >
                  {t("candidateDetails.academicExperience.removeButton")}
                </button>
              )}
            </div>
          ))}
          {!readOnly && (
            <button
              type="button"
              className="add-button"
              onClick={() => {
                setAcademicExperiences([
                  ...academicExperiences,
                  {
                    school_name: "",
                    degree: "",
                    field_of_study: "",
                    start_date: null,
                    end_date: null,
                  },
                ]);
              }}
            >
              {t("candidateDetails.academicExperience.addButton")}
            </button>
          )}
        </div>

        <div className="form-section">
          <h3>{t("candidateDetails.workExperience.title")}</h3>
          {workExperiences.map((exp, index) => (
            <div key={exp.id || index} className="experience-item">
              <input
                type="text"
                value={exp.current_jobtitle || ""}
                onChange={(e) => {
                  const newExps = [...workExperiences];
                  newExps[index].current_jobtitle = e.target.value;
                  setWorkExperiences(newExps);
                }}
                disabled={readOnly}
                placeholder={t("candidateDetails.workExperience.jobTitle")}
              />
              <input
                type="text"
                value={exp.company || ""}
                onChange={(e) => {
                  const newExps = [...workExperiences];
                  newExps[index].company = e.target.value;
                  setWorkExperiences(newExps);
                }}
                disabled={readOnly}
                placeholder={t("candidateDetails.workExperience.company")}
              />
              <input
                type="date"
                value={
                  exp.work_start_date
                    ? new Date(exp.work_start_date).toISOString().split("T")[0]
                    : ""
                }
                onChange={(e) => {
                  const newExps = [...workExperiences];
                  newExps[index].work_start_date = e.target.value;
                  setWorkExperiences(newExps);
                }}
                disabled={readOnly}
              />
              <input
                type="date"
                value={
                  exp.work_end_date
                    ? new Date(exp.work_end_date).toISOString().split("T")[0]
                    : ""
                }
                onChange={(e) => {
                  const newExps = [...workExperiences];
                  newExps[index].work_end_date = e.target.value;
                  setWorkExperiences(newExps);
                }}
                disabled={readOnly}
              />
              {!readOnly && (
                <button
                  type="button"
                  className="remove-button"
                  onClick={() => {
                    const newExps = workExperiences.filter(
                      (_, i) => i !== index
                    );
                    setWorkExperiences(newExps);
                  }}
                >
                  {t("candidateDetails.workExperience.removeButton")}
                </button>
              )}
            </div>
          ))}
          {!readOnly && (
            <button
              type="button"
              className="add-button"
              onClick={() => {
                setWorkExperiences([
                  ...workExperiences,
                  {
                    current_jobtitle: "",
                    company: "",
                    work_start_date: null,
                    work_end_date: null,
                  },
                ]);
              }}
            >
              {t("candidateDetails.workExperience.addButton")}
            </button>
          )}
        </div>

        <div className="form-section">
          <h3>{t("candidateDetails.languages.title")}</h3>
          {languages.map((lang, index) => (
            <div key={lang.id || index} className="experience-item">
              <input
                type="text"
                value={lang.language || ""}
                onChange={(e) => {
                  const newLangs = [...languages];
                  newLangs[index].language = e.target.value;
                  setLanguages(newLangs);
                }}
                disabled={readOnly}
                placeholder={t("candidateDetails.languages.language")}
              />
              <select
                value={lang.language_level || ""}
                onChange={(e) => {
                  const newLangs = [...languages];
                  newLangs[index].language_level = e.target.value;
                  setLanguages(newLangs);
                }}
                disabled={readOnly}
              >
                <option value="">
                  {t("candidateDetails.languages.level")}
                </option>
                <option value="beginner">
                  {t("candidateDetails.languages.levels.beginner")}
                </option>
                <option value="intermediate">
                  {t("candidateDetails.languages.levels.intermediate")}
                </option>
                <option value="advanced">
                  {t("candidateDetails.languages.levels.advanced")}
                </option>
                <option value="native">
                  {t("candidateDetails.languages.levels.native")}
                </option>
              </select>
              {!readOnly && (
                <button
                  type="button"
                  className="remove-button"
                  onClick={() => {
                    const newLangs = languages.filter((_, i) => i !== index);
                    setLanguages(newLangs);
                  }}
                >
                  {t("candidateDetails.languages.removeButton")}
                </button>
              )}
            </div>
          ))}
          {!readOnly && (
            <button
              type="button"
              className="add-button"
              onClick={() => {
                setLanguages([
                  ...languages,
                  {
                    language: "",
                    language_level: "",
                  },
                ]);
              }}
            >
              {t("candidateDetails.languages.addButton")}
            </button>
          )}





          
        </div>

        
        {jobApplications.length > 0 && (
  <div className="job-application-container">
    {console.log("Job2", jobApplications)}
    <JobApplicationDetails candidate={{ 
      ...candidate, 
      firstName: candidate.first_name, 
      createdAt: candidate.createdAt,
    }} />
  </div>
)}


        <div className="form-actions">
          <button
            type="button"
            className="cancel-button"
            onClick={() => navigate("/b2b/dashboard/candidates")}
          >
            {readOnly
              ? t("candidateDetails.actions.back")
              : t("candidateDetails.actions.cancel")}
          </button>
          {!readOnly && (
            <button type="submit" className="save-button">
              {t("candidateDetails.actions.save")}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default CandidateDetailsPool;