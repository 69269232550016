import React, { useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { getUserProfile } from '../../services/userService';

import { ThemeContext } from "./ThemeContext"; 
import AdminDashboardUsersListing from "./SidebarPages/AdminDashboardUsersListing";
import AdminDashboardSubscriptionListing from "./SidebarPages/AdminDashboardSubscriptionListing";
import AdminDashboardCompaniesListing from "./SidebarPages/AdminDashboardCompaniesListing";
import BulkUploadUsers from "./SidebarPages/BulkUploadUsers";

const AdminDashboardRoutes = () => {
  const { i18n } = useTranslation();
  
  useEffect(() => {
    const setLanguage = async () => {
      try {
        const browserLang = navigator.language.split('-')[0];
        console.log('Browser language detected:', browserLang);

        const userProfile = await getUserProfile();
        console.log('User profile language preference:', userProfile?.language_preference);
        console.log('User profile:', userProfile);

        const languageMap = {
          'english': 'en',
          'french': 'fr',
          'spanish': 'es',
          'german': 'de',
          'italian': 'it',
          'portuguese': 'pt',
          'en': 'en',
          'fr': 'fr',
          'es': 'es',
          'de': 'de',
          'it': 'it',
          'pt': 'pt'
        };

        let targetLanguage;

        if (userProfile?.language_preference) {
          targetLanguage = languageMap[userProfile.language_preference.toLowerCase()];
          console.log('Language from user preference:', targetLanguage);
        }

        if (!targetLanguage && ['en', 'fr', 'es', 'de', 'it', 'pt'].includes(browserLang)) {
          targetLanguage = browserLang;
          console.log('Using browser language:', targetLanguage);
        }

        if (!targetLanguage) {
          targetLanguage = 'en';
          console.log('Defaulting to English');
        }

        console.log('Setting language to:', targetLanguage);
        
        if (i18n.language !== targetLanguage) {
          await i18n.changeLanguage(targetLanguage);
          console.log('Language changed successfully to:', i18n.language);
          document.documentElement.lang = targetLanguage;
        }

      } catch (error) {
        console.error('Error setting language:', error);
        await i18n.changeLanguage('en');
      }
    };

    setLanguage();
  }, [i18n]);

  const { addOnWhiteLabel } = useContext(ThemeContext); 
  if (addOnWhiteLabel === undefined) {
    return <div>Loading settings...</div>;  
  }

  return (
    <Routes>
      {/* Admin Routes */}
      <Route path="users" element={<AdminDashboardUsersListing/>} />
      <Route path="users/upload" element={<BulkUploadUsers/>} />
      <Route path="subscriptions" element={<AdminDashboardSubscriptionListing />} />
      <Route path="companies" element={<AdminDashboardCompaniesListing />} />
    </Routes>
  );
};

export default AdminDashboardRoutes;
