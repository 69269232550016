import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './SupportTicketCreate.scss';
import { API_URL } from "../../../shared";

const SupportTicketCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const [ticketData, setTicketData] = useState({
    title: '',
    category: '',
    priority: 'Medium',
    description: '',
    attachments: []
  });

  const categories = [
    'Technical Issue',
    'Billing Question',
    'Feature Request',
    'Talexa AI Assistant',
    'Account Management',
    'Other'
  ];

  const priorities = ['Low', 'Medium', 'High'];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTicketData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setTicketData(prev => ({
      ...prev,
      attachments: [...prev.attachments, ...files]
    }));
  };

  const handleRemoveFile = (index) => {
    setTicketData(prev => ({
      ...prev,
      attachments: prev.attachments.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    
    // Append regular form data (title, category, etc.)
    formData.append('title', ticketData.title);
    formData.append('category', ticketData.category);
    formData.append('priority', ticketData.priority);
    formData.append('description', ticketData.description);
    
    // Append the attachments (you can add multiple files)
    if (ticketData.attachments && ticketData.attachments.length > 0) {
      ticketData.attachments.forEach(file => {
        formData.append('attachments', file); 
      });
    }
    
    try {
        const response = await fetch(`${API_URL}/create-support-ticket`, {
            method: 'POST',
            body: formData,  
            credentials: 'include',
        });

        if (response.ok) {
            alert("Ticket submitted successfully.");
            navigate('/b2b/dashboard/support-tickets');
        } else {
            throw new Error("Failed to submit the support ticket.");
        }
    } catch (error) {
        console.error("Error submitting ticket:", error);
        alert(error.message);
    }
};


  const handleCancel = () => {
    navigate('/b2b/dashboard/support-tickets');
  };

  return (
    <div className="support-ticket-create">
      <div className="header-section">
        <h2>{t('dashboard.createSupportTicket')}</h2>
      </div>

      <form onSubmit={handleSubmit} className="ticket-form">
        <div className="form-group">
          <label htmlFor="title">{t('dashboard.ticketTitle')}*</label>
          <input
            type="text"
            id="title"
            name="title"
            value={ticketData.title}
            onChange={handleInputChange}
            required
            placeholder={t('dashboard.enterTicketTitle')}
          />
        </div>

        <div className="form-group">
          <label htmlFor="category">{t('dashboard.category')}*</label>
          <select
            id="category"
            name="category"
            value={ticketData.category}
            onChange={handleInputChange}
            required
          >
            <option value="">{t('dashboard.selectCategory')}</option>
            {categories.map(category => (
              <option key={category} value={category}>
                {t(`dashboard.categories.${category.toLowerCase().replace(/\s+/g, '')}`)}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="priority">{t('dashboard.priority')}</label>
          <select
            id="priority"
            name="priority"
            value={ticketData.priority}
            onChange={handleInputChange}
          >
            {priorities.map(priority => (
              <option key={priority} value={priority}>
                {t(`dashboard.priorities.${priority.toLowerCase()}`)}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="description">{t('dashboard.description')}*</label>
          <textarea
            id="description"
            name="description"
            value={ticketData.description}
            onChange={handleInputChange}
            required
            placeholder={t('dashboard.describeYourIssue')}
            rows="6"
          />
        </div>

        <div className="form-group">
          <label htmlFor="attachments">{t('dashboard.attachments')}</label>
          <div className="file-upload">
            <button type="button" className="file-select-btn">
              {t('dashboard.chooseFiles')}
            </button>
            <input
              type="file"
              id="attachments"
              multiple
              onChange={handleFileChange}
              accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
            />
            <span className="file-hint">
              {t('dashboard.maxFileSize')} (5MB) upto 5 files
            </span>
          </div>
          {ticketData.attachments.length > 0 && (
            <div className="attached-files">
              {ticketData.attachments.map((file, index) => (
                <div key={index} className="file-item">
                  <span>{file.name}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveFile(index)}
                    className="remove-file"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="form-actions">
          <button type="button" className="cancel-btn" onClick={handleCancel}>
            {t('dashboard.cancel')}
          </button>
          <button type="submit" className="submit-btn">
            {t('dashboard.submitTicket')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SupportTicketCreate;
