import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronDown } from "react-icons/bi";
import { API_URL } from "../../../shared.js";
import "./ReceivedApplications.scss";
import JobApplicationDetails from "./Modals/JobApplicationDetails";

const fetchCandidates = async () => {
  const response = await fetch(`${API_URL}/candidates-with-application`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error("Failed to fetch candidates data");
  }
  const dataCandidates = await response.json();

  console.log("Data candidates", dataCandidates);
  return dataCandidates;
};

const refuseACandidate = async (jobApplicationId, active) => {
  const response = await fetch(`${API_URL}/refuse-job-application`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ id: jobApplicationId, active }),
  });
  if (!response.ok) {
    throw new Error("Failed to refuse candidate");
  }
  const data = await response.json();
  console.log("Data for refused candidate", data);
  return data;
};

const ReceivedApplications = () => {
  const { t } = useTranslation();
  const [isJobDropdownOpen, setIsJobDropdownOpen] = useState(false);
  const [jobSearchTerm, setJobSearchTerm] = useState("");
  const [filteredJobOptions, setFilteredJobOptions] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);

  const [candidateSearchTerm, setCandidateSearchTerm] = useState("");
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [refusedCandidates, setRefusedCandidates] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [jobOfferOptions, setJobOfferOptions] = useState([]);

  const [actionDropdownVisible, setActionDropdownVisible] = useState({});
  const jobDropdownRef = useRef(null);
  const actionDropdownRefs = useRef({});

  useEffect(() => {
    const loadCandidates = async () => {
      try {
        const data = await fetchCandidates();

        // Extraction des candidats et des offres d'emploi
        const parsedCandidates = data.candidates.flatMap((candidate) =>
          candidate.JobApplications.map((jobApp) => ({
            id: candidate.id,
            firstName: candidate.first_name,
            lastName: candidate.last_name,
            email: candidate.email || "N/A",
            phone: candidate.phone || "N/A",
            linkedin: candidate.linkedin_url || "#",
            createdAt: candidate.created_at,
            jobAppId: jobApp.id || "N/A",
            jobId: jobApp.Job_Offer?.id || "N/A",
            jobRole: jobApp.Job_Offer?.role || "Unknown Role",
            companyName: jobApp.Job_Offer?.company_name || "Unknown Company",
            active: jobApp.active,
          }))
        );

        setCandidates(parsedCandidates);
        setFilteredCandidates(parsedCandidates.filter((c) => c.active));
        setRefusedCandidates(parsedCandidates.filter((c) => !c.active));

        const parsedJobOffers = data.candidates.flatMap((candidate) =>
          candidate.JobApplications.map((jobApp) => ({
            value: jobApp.Job_Offer?.id,
            label: jobApp.Job_Offer?.role || "Unknown Role",
          }))
        );

        const uniqueJobOffers = Array.from(
          new Map(parsedJobOffers.map((offer) => [offer.value, offer])).values()
        );

        setJobOfferOptions(uniqueJobOffers);
        setFilteredJobOptions(uniqueJobOffers);
      } catch (error) {
        console.error("Failed to fetch candidates:", error);
      }
    };

    loadCandidates();
  }, []);

  const toggleJobDropdown = () => {
    setIsJobDropdownOpen((prev) => !prev);
  };

  const handleJobSearchChange = (e) => {
    const value = e.target.value;
    setJobSearchTerm(value);
    setIsJobDropdownOpen(true);

    if (value === "") {
      setSelectedJob(null);
      setFilteredJobOptions(jobOfferOptions);
      updateFilteredCandidates(candidateSearchTerm, null);
      return;
    }

    const filtered = jobOfferOptions.filter((option) =>
      option.label.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredJobOptions(filtered);
  };

  const handleJobOptionClick = (option) => {
    setSelectedJob(option);
    setJobSearchTerm(option.label);
    setIsJobDropdownOpen(false);
    updateFilteredCandidates(candidateSearchTerm, option.value);
  };

  const handleCandidateSearchChange = (e) => {
    const value = e.target.value;
    setCandidateSearchTerm(value);
    updateFilteredCandidates(value, selectedJob?.value);
  };

  const updateFilteredCandidates = (searchTerm, jobFilter) => {
    let filtered = [...candidates]; // Toujours repartir de la liste d'origine

    // Filtrer par job si un job est sélectionné
    if (jobFilter) {
      filtered = filtered.filter((candidate) => candidate.jobId === jobFilter);
    }

    // Filtrer par terme de recherche
    if (searchTerm) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((candidate) => {
        return Object.values(candidate).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(lowerCaseSearchTerm)
        );
      });
    }

    // Mettre à jour la liste des candidats filtrés
    setFilteredCandidates(filtered.filter((c) => c.active));
    setRefusedCandidates(filtered.filter((c) => !c.active));
  };

  // State pour la modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [viewedCandidate, setViewedCandidate] = useState(null);

  const openViewModal = (candidate) => {
    setViewedCandidate(candidate);
    setIsViewModalOpen(true);
  };

  const closeViewModal = () => {
    console.log("fermeture modal");
    setViewedCandidate(null);
    setIsViewModalOpen(false);
  };

  // Fonction pour ouvrir la modal
  const openModal = (candidate) => {
    setSelectedCandidate(candidate);
    setIsModalOpen(true);
  };

  // Fonction pour fermer la modal
  const closeModal = () => {
    setSelectedCandidate(null);
    setIsModalOpen(false);
  };

  const handleRefuseClick = async () => {
    if (!selectedCandidate) return;

    try {
      console.log(
        `Sending request for JobApplication ID: ${selectedCandidate.jobAppId} with active: false`
      );
      await refuseACandidate(selectedCandidate.jobAppId, false);

      setCandidates((prevCandidates) => {
        const updatedCandidates = prevCandidates.map((candidate) =>
          candidate.jobAppId === selectedCandidate.jobAppId
            ? { ...candidate, active: false }
            : candidate
        );
        setFilteredCandidates(updatedCandidates.filter((c) => c.active));
        setRefusedCandidates(updatedCandidates.filter((c) => !c.active));
        return updatedCandidates;
      });

      // Fermez tous les menus déroulants
      setActionDropdownVisible({});
      closeModal(); // Ferme la modal après confirmation
    } catch (error) {
      console.error("Failed to refuse candidate:", error);
    }
  };

  const toggleActionDropdown = (uniqueKey) => {
    setActionDropdownVisible((prev) => {
      if (prev[uniqueKey]) {
        return {};
      }
      return { [uniqueKey]: true };
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        jobDropdownRef.current &&
        !jobDropdownRef.current.contains(event.target)
      ) {
        setIsJobDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutsidee = (event) => {
      const clickedInsideDropdownOrButton = Object.keys(
        actionDropdownRefs.current
      ).some((key) => {
        return (
          actionDropdownRefs.current[key]?.contains(event.target) ||
          event.target.closest(
            ".received-applications__content-candidate-list-row--item-buttonContainer"
          ) // Vérifie si le clic est sur un bouton
        );
      });

      if (!clickedInsideDropdownOrButton) {
        setActionDropdownVisible({}); // Ferme tous les dropdowns
      }
    };

    document.addEventListener("mousedown", handleClickOutsidee);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsidee);
    };
  }, []);

  useEffect(() => {
    setActionDropdownVisible({});
  }, [candidates]);

  useEffect(() => {
    setFilteredCandidates(candidates.filter((c) => c.active));
    setRefusedCandidates(candidates.filter((c) => !c.active));
  }, [candidates]);

  useEffect(() => {
    return () => {
      actionDropdownRefs.current = {}; // Nettoyage
    };
  }, [candidates, refusedCandidates]);

  const handleCancelRefuseClick = async (jobApplicationId) => {
    try {
      console.log(
        `Cancelling refusal for JobApplication ID: ${jobApplicationId}`
      );
      await refuseACandidate(jobApplicationId, true);

      setCandidates((prevCandidates) => {
        const updatedCandidates = prevCandidates.map((candidate) =>
          candidate.jobAppId === jobApplicationId
            ? { ...candidate, active: true }
            : candidate
        );
        setFilteredCandidates(updatedCandidates.filter((c) => c.active));
        setRefusedCandidates(updatedCandidates.filter((c) => !c.active));
        return updatedCandidates;
      });

      // Fermez tous les menus déroulants
      setActionDropdownVisible({});
    } catch (error) {
      console.error("Failed to cancel refusal for candidate:", error);
    }
  };

  const handleUpdateCandidates = (updatedCandidate) => {
    setCandidates((prevCandidates) =>
      prevCandidates.map((candidate) =>
        candidate.jobAppId === updatedCandidate.jobAppId
          ? { ...candidate, ...updatedCandidate }
          : candidate
      )
    );
  };

  useEffect(() => {
    setFilteredCandidates(candidates.filter((c) => c.active));
    setRefusedCandidates(candidates.filter((c) => !c.active));
  }, [candidates]);

  const addToPool = async (candidateId) => {
    try {
      const responseAddToPool = await fetch(
        `${API_URL}/add-candidate-to-pool`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ id: candidateId }),
        }
      );

      if (!responseAddToPool.ok) {
        if (responseAddToPool.status === 400) {
          alert(t("jobApplication.receivedApplication.alertAlreadyPool"));
          return;
        }
        throw new Error("Failed to add candidate to the pool");
      }

      const candidateAddedToMyPool = await responseAddToPool.json();
      console.log("Candidate added to pool:", candidateAddedToMyPool);

      alert(t('modals.trackingBoard.successMessage'));
    } catch (error) {
      console.error("Error adding candidate to the pool:", error);
      alert("Failed to add candidate to the pool. Please try again.");
    }
  };

  console.log("isViewModalOpen", isViewModalOpen);

  return (
    <div className="received-applications">
      {/* Modal for View */}
      {isViewModalOpen && (
        <div
          className="modal-ReceivedApplications-modal-overlay"
          onClick={closeViewModal}
        >
          <div
            className="modal-ReceivedApplications-contentview modal-ReceivedApplications-refuseCandidate"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="modal-close" onClick={closeViewModal}>
              {t('jobApplication.jobApplicationDetails.actions.close')}
            </button>
            <JobApplicationDetails
              candidate={viewedCandidate}
              closeModal={closeViewModal}
              onUpdateCandidates={handleUpdateCandidates}
            />
          </div>
        </div>
      )}

       {/* Modal */}
       {isModalOpen && (
                    <div className="modal-ReceivedApplications-modal-overlay">
                      <div className="modal-ReceivedApplications-refuseCandidate">
                        <h3 className="modal-ReceivedApplications-title">
                          {t('jobApplication.jobApplicationDetails.modal.refuse.title')}
                        </h3>
                        <p className="modal-ReceivedApplications-textName">
                          {t('jobApplication.jobApplicationDetails.modal.refuse.warning')}
                          <span className="modal-ReceivedApplications-candidateName">
                            {selectedCandidate.firstName}{" "}
                            {selectedCandidate.lastName}
                          </span>
                          for the position of
                          <span className="modal-ReceivedApplications-candidateName">
                            {selectedCandidate.jobRole}
                          </span>
                          ?
                        </p>
                        <p className="modal-ReceivedApplications-text">
                          {t('jobApplication.jobApplicationDetails.modal.refuse.warning')}
                        </p>
                        <div className="modal-ReceivedApplications-email">
                          <p className="modal-ReceivedApplications-greating">
                            {t('jobApplication.jobApplicationDetails.modal.refuse.email.greeting', {
                              firstName: selectedCandidate.firstName,
                              lastName: selectedCandidate.lastName
                            })}
                          </p>
                          <p className="modal-ReceivedApplications-email-content">
                            {t('jobApplication.jobApplicationDetails.modal.refuse.email.content', {
                              jobRole: selectedCandidate.jobRole,
                              companyName: selectedCandidate.companyName
                            })}
                          </p>
                          <p className="modal-ReceivedApplications-email-content">
                            {t('jobApplication.jobApplicationDetails.modal.refuse.email.thanks')}
                          </p>
                          <p className="modal-ReceivedApplications-email-content">
                            {t('jobApplication.jobApplicationDetails.modal.refuse.email.future')}
                          </p>
                          <p className="modal-ReceivedApplications-email-end">
                            {t('jobApplication.jobApplicationDetails.modal.refuse.email.closing')}
                          </p>
                        </div>

                        <div className="modal-ReceivedApplications-actions">
                          <button
                            onClick={handleRefuseClick}
                            className="modal-ReceivedApplications-actions-buttons"
                          >
                            {t('jobApplication.jobApplicationDetails.modal.refuse.actions.confirm')}
                          </button>
                          <button
                            onClick={closeModal}
                            className="modal-ReceivedApplications-actions-buttons"
                          >
                            {t('jobApplication.jobApplicationDetails.modal.refuse.actions.cancel')}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
      <div className="received-applications__header">
        <h2 className="received-applications__header-title">
          {t('receivedApplications.title')}
        </h2>

        <div className="received-applications__header-filters">
          {/* Job Dropdown */}
          <div
            className="received-applications__header-filter received-applications__header-filter--jobs"
            ref={jobDropdownRef}
          >
            <div
              className={`received-applications__header-filter-dropdown ${
                isJobDropdownOpen ? "is-active" : ""
              } ${selectedJob || jobSearchTerm ? "has-selection" : ""}`}
            >
              <input
                type="text"
                placeholder={t('receivedApplications.filters.jobSearch.placeholder')}
                value={jobSearchTerm}
                onChange={handleJobSearchChange}
                onClick={toggleJobDropdown}
                className="received-applications__header-filter-dropdown-search"
              />
              <BiChevronDown
                className={`chevron-icon ${isJobDropdownOpen ? "rotated" : ""}`}
                onClick={toggleJobDropdown}
              />
              {isJobDropdownOpen && (
                <div className="received-applications__header-filter-dropdown-options">
                  {filteredJobOptions.length > 0 ? (
                    filteredJobOptions.map((option) => (
                      <div
                        key={option.value}
                        className={`received-applications__header-filter-dropdown-options-option ${
                          selectedJob?.value === option.value
                            ? "is-selected"
                            : ""
                        }`}
                        onClick={() => handleJobOptionClick(option)}
                      >
                        {option.label}
                      </div>
                    ))
                  ) : (
                    <div className="dropdown-no-options">{t('receivedApplications.filters.jobSearch.noResults')}</div>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Candidate Search Bar */}
          <div className="received-applications__header-filter received-applications__header-filter--candidates">
            <input
              type="text"
              placeholder={t('receivedApplications.filters.candidateSearch.placeholder')}
              value={candidateSearchTerm}
              onChange={handleCandidateSearchChange}
              className="received-applications__header-filter-candidate-search"
            />
          </div>
        </div>
      </div>
      <h3 className="received-applications__middle-title">
        {t('receivedApplications.table.columns.newJobApplication')}
      </h3>
      <div className="received-applications__content">
        <div className="received-applications__content-candidate-list-header">
          <div>{t('receivedApplications.table.columns.jobRole')}</div>
          <div>{t('receivedApplications.table.columns.firstName')}</div>
          <div>{t('receivedApplications.table.columns.lastName')}</div>
          <div>{t('receivedApplications.table.columns.email')}</div>
          <div>{t('receivedApplications.table.columns.phone')}</div>
          <div>{t('receivedApplications.table.columns.linkedin')}</div>
          <div>{t('receivedApplications.table.columns.createdAt')}</div>
          <div>Actions</div>
        </div>

        <div className="received-applications__content-candidate-list">
          {filteredCandidates.length > 0 ? (
            filteredCandidates.map((candidate) => {
              // Clé unique basée sur Candidate ID et JobApplication ID
              const uniqueKey = `${candidate.id}-${candidate.jobAppId}-${candidate.createdAt}`;
              return (
                <div
                  key={uniqueKey}
                  className="received-applications__content-candidate-list-row"
                  ref={(el) => (actionDropdownRefs.current[uniqueKey] = el)}
                >
                  <div className="received-applications__content-candidate-list-row--item">
                    {candidate.jobRole}
                  </div>
                  <div className="received-applications__content-candidate-list-row--item">
                    <p>{candidate.firstName}</p>
                  </div>
                  <div className="received-applications__content-candidate-list-row--item">
                    {candidate.lastName}
                  </div>
                  <div className="received-applications__content-candidate-list-row--item">
                    <p>{candidate.email}</p>
                  </div>
                  <div className="received-applications__content-candidate-list-row--item">
                    {candidate.phone}
                  </div>
                  <div className="received-applications__content-candidate-list-row--item">
                    <a
                      href={candidate.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('receivedApplications.table.actions.viewProfile')}
                    </a>
                  </div>
                  <div className="received-applications__content-candidate-list-row--item">
                    {new Date(candidate.createdAt).toLocaleDateString()}
                  </div>
                  <div className="received-applications__content-candidate-list-row--item-buttonContainer">
                    <button onClick={() => toggleActionDropdown(uniqueKey)}>
                      {t("jobApplication.Actions")} <BiChevronDown />
                    </button>
                    {actionDropdownVisible[uniqueKey] && (
                      <div className="received-applications__content-candidate-action-dropdown-menu">
                        <div
                          className="received-applications__content-candidate-action-dropdown-menu_action-item"
                          onClick={() => openViewModal(candidate)}
                        >
                          {t('receivedApplications.table.actions.viewDetails')}
                        </div>
                        <div
                          className="received-applications__content-candidate-action-dropdown-menu_action-item"
                          onClick={() => addToPool(candidate.id)}
                        >
                          {t('receivedApplications.table.actions.addToPool')}
                        </div>
                        <div
                          className="received-applications__content-candidate-action-dropdown-menu_action-item"
                          onClick={() => openModal(candidate)}
                        >
                          {t('receivedApplications.table.actions.refuse')}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="received-applications__content-no-candidates">
              {t('receivedApplications.table.noCandidates')}
            </div>
          )}
        </div>
      </div>
      <h3 className="received-applications__middle-title">
        {t('receivedApplications.table.columns.refusedJobApplication')}
      </h3>
      <div className="received-applications__content">
        <div className="received-applications__content-candidate-list-header">
          <div>{t('receivedApplications.table.columns.jobRole')}</div>
          <div>{t('receivedApplications.table.columns.firstName')}</div>
          <div>{t('receivedApplications.table.columns.lastName')}</div>
          <div>{t('receivedApplications.table.columns.email')}</div>
          <div>{t('receivedApplications.table.columns.phone')}</div>
          <div>{t('receivedApplications.table.columns.linkedin')}</div>
          <div>{t('receivedApplications.table.columns.createdAt')}</div>
          <div>{t('receivedApplications.table.columns.actions')}</div>
        </div>

        <div className="received-applications__content-candidate-list">
          {refusedCandidates.length > 0 ? (
            refusedCandidates.map((candidate) => {
              const uniqueKey = `${candidate.id}-${candidate.jobAppId}-${candidate.createdAt}`;
              return (
                <div
                  key={uniqueKey}
                  className="received-applications__content-candidate-list-row"
                  ref={(el) => (actionDropdownRefs.current[uniqueKey] = el)}
                >
                  <div className="received-applications__content-candidate-list-row--item">
                    {candidate.jobRole}
                  </div>
                  <div className="received-applications__content-candidate-list-row--item">
                    <p>{candidate.firstName}</p>
                  </div>
                  <div className="received-applications__content-candidate-list-row--item">
                    {candidate.lastName}
                  </div>
                  <div className="received-applications__content-candidate-list-row--item">
                    <p>{candidate.email}</p>
                  </div>
                  <div className="received-applications__content-candidate-list-row--item">
                    {candidate.phone}
                  </div>
                  <div className="received-applications__content-candidate-list-row--item">
                    <a
                      href={candidate.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('receivedApplications.table.actions.viewProfile')}

                    </a>
                  </div>
                  <div className="received-applications__content-candidate-list-row--item">
                    {new Date(candidate.createdAt).toLocaleDateString()}
                  </div>
                  <div className="received-applications__content-candidate-list-row--item-buttonContainer">
                    <button onClick={() => toggleActionDropdown(uniqueKey)}>
                      {t('receivedApplications.table.actions.actions')} <BiChevronDown />

                    </button>
                    {actionDropdownVisible[uniqueKey] && (
                      <div className="received-applications__content-candidate-action-dropdown-menu">
                        <div
                          className="received-applications__content-candidate-action-dropdown-menu_action-item"
                          onClick={() => {
                            console.log(
                              `Refusing Candidate with ID : ${candidate.id}, JobAppID : ${candidate.jobAppId}`
                            ),
                              handleCancelRefuseClick(candidate.jobAppId, true);
                          }}
                        >
                          {t('receivedApplications.table.actions.cancel')}
                          {t('receivedApplications.table.actions.refuse')}

                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="received-applications__content-no-candidates">
                {t('receivedApplications.table.columns.noRefusedCandidates')}
              </div>

          )}
        </div>
      </div>
    </div>
  );
};

export default ReceivedApplications;
