import React, { useState, useEffect } from "react";
import "./Subscription.scss";
import UpdateSubscriptionModal from "./Modals/UpdateSubscriptionModal.jsx";
import { API_URL } from "../../../shared.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";




const Subscription = () => {
  const [subscription, setSubscription] = useState(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const addons = [
    { name: t("Talexa.titleCoreFeatures"), key: "talexa_subscription", isCore: true },
    { name: t("Talexa.addons.add_on_analysis.title"), key: "analysis" },
    { name: t("Talexa.addons.add_on_skills_assessment.title"), key: "skills_assessment" },
    { name: t("Talexa.addons.add_on_language_assessment.title"), key: "language_assessment" },
    { name: t("Talexa.addons.add_on_white_label.title"), key: "white_label" },
    { name: t("Talexa.addons.add_on_ai_phone_call.title"), key: "ai_phone_call" },
  ];

  useEffect(() => {
    fetchSubscription();
  }, []);

  const fetchSubscription = async () => {
    try {
      const response = await fetch(`${API_URL}/subscriptions/user/current`, {
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch subscription data");
      }
      const data = await response.json();
      setSubscription(data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const redirectToStripePortal = async () => {
    try {
      const response = await fetch(`${API_URL}/portal-subscription`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });
  
      if (!response.ok) {
        throw new Error("Failed to create Stripe portal session");
      }
  
      const { url } = await response.json();
      window.location.href = url; // Redirige l'utilisateur vers le portail Stripe
    } catch (error) {
      console.error("Error redirecting to Stripe portal:", error);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    fetchSubscription();
  };

  const cancelSubscription = async () => {

    if (
      window.confirm(
        t("Talexa.subscriptionDashBoardPage.cancelMessage")
      )
    ) {
      try {
        const response = await fetch(
          `${API_URL}/subscriptions/${subscription.id}/cancel`,

          {
            headers: {
              "Content-Type": "application/json",
            },
            method: "PUT",
            credentials: "include",
            body: JSON.stringify({
              stripe_id: subscription.stripe_id,
            }),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to cancel subscription");
        }
        const data = await response.json();
        console.log("retour du cancel", data);
        fetchSubscription();
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const reactivateSubscription = async () => {
    if (
      window.confirm(
        t("Talexa.subscriptionDashBoardPage.reactivateMessage")
      )
    ) {
      try {
        console.log("click test reactivate");
        const response = await fetch(
          `${API_URL}/subscriptions/${subscription.id}/reactivate`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            method: "PUT",
            credentials: "include",
            body: JSON.stringify({
              stripe_id: subscription.stripe_id,
            }),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to reactivate subscription");
        }
        const data = await response.json();
        console.log("retour du reactivate", data);
        alert(t("Talexa.subscriptionDashBoardPage.successReactivate"));
        fetchSubscription();
      } catch (err) {
        setError(err.message);
      }
    }
  };

  if (loading) return <div className="subscription loading">{t("aiPhoneInterview.details.loading")}</div>;
  if (error) return <div className="subscription error">{error}</div>;
  if (!subscription || subscription.length === 0)
    return <div className="subscription error">No subscription found</div>;

  return (
    <div className="subscriptionDashBoard">
      <h2 className="subscriptionDashBoard__title">{t("Talexa.subscriptionDashBoardPage.title")}</h2>
      <div className="subscriptionDashBoard__subscription-details">
        <div className="subscriptionDashBoard__subscription-details-item">
          <p className="subscriptionDashBoard__subscription-details-item-text">
            {t("Talexa.subscriptionDashBoardPage.subscription.subscription")}
          </p>
          <span className="subscriptionDashBoard__subscription-details-item-content">
            {subscription.active
              ? t("Talexa.subscriptionDashBoardPage.subscription.active")
              : t("Talexa.subscriptionDashBoardPage.subscription.inactive")}
          </span>
        </div>
        <div className="subscriptionDashBoard__subscription-details-item">
          <p className="subscriptionDashBoard__subscription-details-item-text">
            {t("Talexa.subscriptionDashBoardPage.subscription.type")}
          </p>
          <span className="subscriptionDashBoard__subscription-details-item-content">
            {subscription.type}
          </span>
        </div>
        <div className="subscriptionDashBoard__subscription-details-item">
          <p className="subscriptionDashBoard__subscription-details-item-text">
            {t("Talexa.subscriptionDashBoardPage.subscription.startDate")}
          </p>
          <span className="subscriptionDashBoard__subscription-details-item-content">
            {new Date(subscription.start_date).toLocaleDateString()}
          </span>
        </div>
        <div className="subscriptionDashBoard__subscription-details-item">
          <p className="subscriptionDashBoard__subscription-details-item-text">
            {t("Talexa.subscriptionDashBoardPage.subscription.endDate")}
          </p>
          <span className="subscriptionDashBoard__subscription-details-item-content">
            {new Date(subscription.end_date).toLocaleDateString()}
          </span>
        </div>
        <div className="subscriptionDashBoard__subscription-details-item">
          <p className="subscriptionDashBoard__subscription-details-item-text">
            {t("Talexa.subscriptionDashBoardPage.subscription.price")}
          </p>
          <span className="subscriptionDashBoard__subscription-details-item-content">
            ${subscription.price}
          </span>
        </div>
        <div className="subscriptionDashBoard__subscription-details-item">
          <p className="subscriptionDashBoard__subscription-details-item-text">
            {t("Talexa.subscriptionDashBoardPage.subscription.paymentTerm")}
          </p>
          <span className="subscriptionDashBoard__subscription-details-item-content">
            {subscription.payment_term}
          </span>
        </div>
        <div className="subscriptionDashBoard__subscription-details-item">
          <p className="subscriptionDashBoard__subscription-details-item-text">
            {t("Talexa.subscriptionDashBoardPage.subscription.paymentType")}
          </p>
          <span className="subscriptionDashBoard__subscription-details-item-content">
            {subscription.payment_type}
          </span>
        </div>
        <div className="subscriptionDashBoard__subscription-details-item">
          <p className="subscriptionDashBoard__subscription-details-item-text">
            {t("Talexa.subscriptionDashBoardPage.subscription.quantity")}
          </p>
          <span className="subscriptionDashBoard__subscription-details-item-content">
            {subscription.quantity}
          </span>{" "}
        </div>
      </div>

      <h3 className="subscriptionDashBoard__subtitle">{t("Talexa.subscriptionDashBoardPage.subtitle")}</h3>
      <div className="subscriptionDashBoard-addOns_container">
        {addons.map((addon) => {
          const currentKey = addon.isCore ? addon.key : `add_on_${addon.key}`;
          const isActive = subscription[currentKey];

          return (
            <div
              className={`add-on ${isActive ? "active" : "inactive"}`}
              key={addon.key}
            >
              <div>
                {isActive ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="add-on-checkmark"
                  />
                ) : (
                  <span className="add-on-placeholder"></span>
                )}
                <span className="add-on-name"> {addon.name}</span>
              </div>
              <p className="add-on-status">
                {isActive ? t("Talexa.subscriptionDashBoardPage.subscription.active") : t("Talexa.subscriptionDashBoardPage.subscription.inactiveAddon")}
              </p>
            </div>
          );
        })}
      </div>

      {subscription.status !== "Cancellation Pending" && (
        <div className="buttons-container_subscription">
          {subscription.active ? (
            <button
              className="cancel-subscription"
              onClick={cancelSubscription}
            >
              {t("Talexa.subscriptionDashBoardPage.cancelSubscription")}
            </button>
          ) : (
            <button
              className="reactivate-subscription"
              onClick={reactivateSubscription}
            >
              {t("Talexa.subscriptionDashBoardPage.reactivateSubscription")}
            </button>
          )}
          {subscription.active && (
            <>
              <button className="update-subscription" onClick={openModal}>
              {t("Talexa.subscriptionDashBoardPage.updateSubscription")}
              </button>
              <button className="updatePaymentSubscription" onClick={redirectToStripePortal}>
              {t("Talexa.subscriptionDashBoardPage.billingAndPaymentMethod")}
              </button>
            </>
          )}
        </div>
      )}

      {isModalOpen && (
        <div className="modal">
          <UpdateSubscriptionModal
            subscription={subscription}
            addons={addons}
            closeModal={closeModal}
          />
        </div>
      )}

      {subscription.status === "Cancellation Pending" && (
        <p className="cancellation-notice">
          Your subscription will be cancelled at the end of the current billing
          period.
        </p>
      )}
    </div>
  );
};

export default Subscription;
