import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./HiringRequestForm.scss";
import { API_URL } from "../../../shared";
import { useTranslation } from 'react-i18next';

const HiringRequestForm = () => {
  const { t } = useTranslation();
  const { formId } = useParams();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    costCenter: "",
    department: "",
    companyDomain: "",
    jobTitle: "",
    roleTitle: "",
    hiringFinality: "",
    contractType: "",
    employmentType: "",
    mission: "",
    responsibilities: "",
    requirements: "",
    minYearsExperience: "",
    roleLevel: "",
    importantInResume: "",
    objectives6Months: "",
    objectives1Year: "",
    skills: "",
    minSkills: "",
    bonusSkills: "",
    differentiatingSkills: "",
    isHeadcountValidated: "",
    isHybrid: "",
    isRemote: "",
    isOnsite: "",
    allowedEmails: [],
    newAllowedEmail: "",
  });

  const [isFormValid, setIsFormValid] = useState(false);

  const checkFormAccess = async () => {
    try {
      const response = await fetch(
        `${API_URL}/hiring-request-forms/${formId}/access`,
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || "Unauthorized access");
      }
    } catch (error) {
      console.error("Error checking form access:", error);
      // Rediriger vers une page d'erreur ou afficher un message
      // Par exemple :
      alert(error.message);
      // Vous pourriez aussi utiliser une redirection ici
      // window.location.href = '/error';
    }
  };

  useEffect(() => {
    // Vérifier si le formId est valide et si l'utilisateur a accès à ce formulaire
    checkFormAccess();
  }, [formId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid) {
      try {
        // Vérifiez à nouveau l'accès juste avant la soumission
        await checkFormAccess();

        const response = await fetch(
          `${API_URL}/hiring-request-forms/${formId}/submit`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        if (response.ok) {
          console.log("Form submitted successfully");
          alert("Form submitted successfully!");

          // Reload the page
          window.location.reload();
        } else {
          const data = await response.json();
          throw new Error(data.message || "Form submission failed");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        alert(error.message);
      }
    }
  };

  // Vérifier la validité du formulaire à chaque changement
  useEffect(() => {
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "jobTitle",
      "department",
      "roleTitle",
      "companyDomain",
      "hiringFinality",
      "contractType",
      "employmentType",
      "mission",
      "responsibilities",
      "requirements",
      "minYearsExperience",
      "roleLevel",
      "importantInResume",
      "objectives6Months",
      "objectives1Year",
      "skills",
      "minSkills",
      "bonusSkills",
      "differentiatingSkills",
      "isHeadcountValidated",
      "isHybrid",
      "isRemote",
      "isOnsite",
    ];
    const isValid = requiredFields.every(
      (field) => formData[field]?.trim() !== ""
    );
    setIsFormValid(isValid);
  }, [formData]);

  const handleAddAllowedEmail = async () => {
    if (formData.newAllowedEmail) {
      try {
        const response = await fetch(
          `${API_URL}/hiring-request-forms/${formId}/allowed-emails`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email: formData.newAllowedEmail }),
          }
        );
        if (response.ok) {
          const updatedHiringRequest = await response.json();
          setFormData((prev) => ({
            ...prev,
            allowedEmails: updatedHiringRequest.allowed_emails,
            newAllowedEmail: "",
          }));
        } else {
          throw new Error("Failed to add allowed email");
        }
      } catch (error) {
        console.error("Error adding allowed email:", error);
        alert(error.message);
      }
    }
  };

  const handleRemoveAllowedEmail = async (email) => {
    try {
      const response = await fetch(
        `${API_URL}/hiring-request-forms/${formId}/allowed-emails`,
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        }
      );
      if (response.ok) {
        const updatedHiringRequest = await response.json();
        setFormData((prev) => ({
          ...prev,
          allowedEmails: updatedHiringRequest.allowed_emails,
        }));
      } else {
        throw new Error("Failed to remove allowed email");
      }
    } catch (error) {
      console.error("Error removing allowed email:", error);
      alert(error.message);
    }
  };

  return (
    <div className="hiring-request-form">
      <h2>{t('hiringRequestForm.title')}</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleInputChange}
          placeholder={t('hiringRequestForm.fields.firstName')}
          required
        />
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleInputChange}
          placeholder={t('hiringRequestForm.fields.lastName')}
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder={t('hiringRequestForm.fields.email')}
          required
        />
        <input
          type="text"
          name="costCenter"
          value={formData.costCenter}
          onChange={handleInputChange}
          placeholder={t('hiringRequestForm.fields.costCenter')}
        />
        <input
          type="text"
          name="department"
          value={formData.department}
          onChange={handleInputChange}
          placeholder={t('hiringRequestForm.fields.department')}
        />
        <input
          type="text"
          name="companyDomain"
          value={formData.companyDomain}
          onChange={handleInputChange}
          placeholder={t('hiringRequestForm.fields.companyDomain')}
        />
        <input
          type="text"
          name="jobTitle"
          value={formData.jobTitle}
          onChange={handleInputChange}
          placeholder={t('hiringRequestForm.fields.jobTitle')}
        />
        <input
          type="text"
          name="roleTitle"
          value={formData.roleTitle}
          onChange={handleInputChange}
          placeholder={t('hiringRequestForm.fields.roleTitle')}
          required
        />
        <textarea
          name="hiringFinality"
          value={formData.hiringFinality}
          onChange={handleInputChange}
          placeholder={t('hiringRequestForm.fields.hiringFinality')}
          required
        />
        <select
          name="contractType"
          value={formData.contractType}
          onChange={handleInputChange}
          required
        >
          <option value="">{t('hiringRequestForm.fields.contractType.label')}</option>
          <option value="fullTime">{t('hiringRequestForm.fields.contractType.fullTime')}</option>
          <option value="partTime">{t('hiringRequestForm.fields.contractType.partTime')}</option>
        </select>
        <select
          name="employmentType"
          value={formData.employmentType}
          onChange={handleInputChange}
          required
        >
          <option value="">{t('hiringRequestForm.fields.employmentType.label')}</option>
          <option value="permanent">{t('hiringRequestForm.fields.employmentType.permanent')}</option>
          <option value="temporary">{t('hiringRequestForm.fields.employmentType.temporary')}</option>
          <option value="contract">{t('hiringRequestForm.fields.employmentType.contract')}</option>
          <option value="internship">{t('hiringRequestForm.fields.employmentType.internship')}</option>
        </select>
        <textarea
          name="mission"
          value={formData.mission}
          onChange={handleInputChange}
          placeholder={t('hiringRequestForm.fields.mission')}
          required
        />
        <textarea
          name="responsibilities"
          value={formData.responsibilities}
          onChange={handleInputChange}
          placeholder={t('hiringRequestForm.fields.responsibilities')}
          required
        />
        <textarea
          name="requirements"
          value={formData.requirements}
          onChange={handleInputChange}
          placeholder={t('hiringRequestForm.fields.requirements')}
          required
        />
        <input
          type="number"
          name="minYearsExperience"
          value={formData.minYearsExperience}
          onChange={handleInputChange}
          placeholder={t('hiringRequestForm.fields.minYearsExperience')}
          required
        />
        <select
          name="roleLevel"
          value={formData.roleLevel}
          onChange={handleInputChange}
          required
        >
          <option value="">{t('hiringRequestForm.fields.roleLevel.label')}</option>
          <option value="firstJob">{t('hiringRequestForm.fields.roleLevel.firstJob')}</option>
          <option value="beginner">{t('hiringRequestForm.fields.roleLevel.beginner')}</option>
          <option value="intermediate">{t('hiringRequestForm.fields.roleLevel.intermediate')}</option>
          <option value="confirmed">{t('hiringRequestForm.fields.roleLevel.confirmed')}</option>
          <option value="senior">{t('hiringRequestForm.fields.roleLevel.senior')}</option>
        </select>
        <textarea
          name="importantInResume"
          value={formData.importantInResume}
          onChange={handleInputChange}
          placeholder={t('hiringRequestForm.fields.importantInResume')}
          required
        />
        <textarea
          name="objectives6Months"
          value={formData.objectives6Months}
          onChange={handleInputChange}
          placeholder={t('hiringRequestForm.fields.objectives6Months')}
          required
        />
        <textarea
          name="objectives1Year"
          value={formData.objectives1Year}
          onChange={handleInputChange}
          placeholder={t('hiringRequestForm.fields.objectives1Year')}
          required
        />
        <input
          type="text"
          name="skills"
          value={formData.skills}
          onChange={handleInputChange}
          placeholder={t('hiringRequestForm.fields.skills')}
          required
        />
        <input
          type="text"
          name="minSkills"
          value={formData.minSkills}
          onChange={handleInputChange}
          placeholder={t('hiringRequestForm.fields.minSkills')}
          required
        />
        <input
          type="text"
          name="bonusSkills"
          value={formData.bonusSkills}
          onChange={handleInputChange}
          placeholder={t('hiringRequestForm.fields.bonusSkills')}
          required
        />
        <input
          type="text"
          name="differentiatingSkills"
          value={formData.differentiatingSkills}
          onChange={handleInputChange}
          placeholder={t('hiringRequestForm.fields.differentiatingSkills')}
          required
        />
        <div>
          <label>{t('hiringRequestForm.fields.isHeadcountValidated.label')}</label>
          <input
            type="radio"
            name="isHeadcountValidated"
            value="yes"
            checked={formData.isHeadcountValidated === "yes"}
            onChange={handleInputChange}
            required
          />{" "}
          {t('hiringRequestForm.fields.isHeadcountValidated.yes')}
          <input
            type="radio"
            name="isHeadcountValidated"
            value="no"
            checked={formData.isHeadcountValidated === "no"}
            onChange={handleInputChange}
            required
          />{" "}
          {t('hiringRequestForm.fields.isHeadcountValidated.no')}
        </div>
        <div>
          <label>{t('hiringRequestForm.fields.isHybrid.label')}</label>
          <input
            type="radio"
            name="isHybrid"
            value="yes"
            checked={formData.isHybrid === "yes"}
            onChange={handleInputChange}
            required
          />{" "}
          {t('hiringRequestForm.fields.isHybrid.yes')}
          <input
            type="radio"
            name="isHybrid"
            value="no"
            checked={formData.isHybrid === "no"}
            onChange={handleInputChange}
            required
          />{" "}
          {t('hiringRequestForm.fields.isHybrid.no')}
        </div>
        <div>
          <label>{t('hiringRequestForm.fields.isRemote.label')}</label>
          <input
            type="radio"
            name="isRemote"
            value="yes"
            checked={formData.isRemote === "yes"}
            onChange={handleInputChange}
            required
          />{" "}
          {t('hiringRequestForm.fields.isRemote.yes')}
          <input
            type="radio"
            name="isRemote"
            value="no"
            checked={formData.isRemote === "no"}
            onChange={handleInputChange}
            required
          />{" "}
          {t('hiringRequestForm.fields.isRemote.no')}
        </div>
        <div>
          <label>{t('hiringRequestForm.fields.isOnsite.label')}</label>
          <input
            type="radio"
            name="isOnsite"
            value="yes"
            checked={formData.isOnsite === "yes"}
            onChange={handleInputChange}
            required
          />{" "}
          {t('hiringRequestForm.fields.isOnsite.yes')}
          <input
            type="radio"
            name="isOnsite"
            value="no"
            checked={formData.isOnsite === "no"}
            onChange={handleInputChange}
            required
          />{" "}
          {t('hiringRequestForm.fields.isOnsite.no')}
        </div>
        {/* <div className="allowed-emails-section">
          <h3>Allowed Emails</h3>
          <ul>
            {formData?.allowedEmails?.map((email, index) => (
              <li key={index}>
                {email}
                <button type="button" onClick={() => handleRemoveAllowedEmail(email)}>Remove</button>
              </li>
            ))}
          </ul>
          <input
            type="email"
            value={formData.newAllowedEmail}
            onChange={(e) => setFormData(prev => ({ ...prev, newAllowedEmail: e.target.value }))}
            placeholder="New allowed email"
          />
          <button type="button" onClick={handleAddAllowedEmail}>Add Email</button>
        </div> */}
        <button type="submit" disabled={!isFormValid}>
          {t('hiringRequestForm.submit')}
        </button>
      </form>
    </div>
  );
};

export default HiringRequestForm;
