import React, { useState } from "react";
import { Outlet } from "react-router-dom"; 
import "./AdminDashboardLayout.scss";
import Sidebar from "./AdminSidebar";
import { ThemeProvider } from './ThemeContext';
import { useTranslation } from 'react-i18next';

const AdminDashboardLayout = ({ children }) => {
  return (
    <ThemeProvider>
      <div className="dashboard-layout">
        <Sidebar />
        <main className="dashboard-content">
          {children} 
        </main>
      </div>
    </ThemeProvider>
  );
};


export default AdminDashboardLayout;
