import React, { useState, useEffect } from "react";
import "./B2BMyProfile.scss";
import {
  getUserProfile,
  deleteUserAccount,
} from "../../../services/userService";
import { useTranslation } from "react-i18next";
import Modal from "../../Modal";
import { Navigate, useNavigate } from "react-router-dom";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { updateUserProfile } from "../../../services/userService";
import { uploadProfilePicture } from "../../../services/userService";
import { API_URL } from "../../../shared";

const B2BMyProfile = () => {
  console.log("Rendering B2BMyProfile component");
  const { t, i18n } = useTranslation();
  const [profile, setProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const [profilePicture, setProfilePicture] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const userData = await getUserProfile();
        setProfile(userData);
        setProfilePicture(userData.photo);
        console.log("Fetched profile:", userData);
        console.log("Profile picture:", userData.photo);
      } catch (error) {
        console.error("Failed to fetch user profile:", error);
      }
    };

    fetchProfile();
  }, []);

  useEffect(() => {
    console.log("Profile state updated:", profile);
  }, [profile]);

  useEffect(() => {
    console.log("isEditing changed:", isEditing);
  }, [isEditing]);

  console.log("Rendering B2BMyProfile, isEditing:", isEditing);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedProfile = await updateUserProfile(profile);
      setProfile(updatedProfile);
      setIsEditing(false);

      if (updatedProfile.language_preference) {
        const languageMap = {
          english: "en",
          french: "fr",
          spanish: "es",
          portuguese: "pt",
          italian: "it",
          german: "de",
        };
        const i18nLang =
          languageMap[updatedProfile.language_preference] || "en";
        i18n.changeLanguage(i18nLang);
      }
      setSuccessMessage(t('dashboard.myProfile.profileUpdateSuccess'));
    } catch (error) {
      try {
        const errorResponse = JSON.parse(error.message); 
        console.error("Error updating user profile:", errorResponse);
        alert(errorResponse.message || t('dashboard.myProfile.profileUpdateError'));
      } catch (parseError) {
        console.error("Unexpected error format:", error);
        alert(t('dashboard.myProfile.profileUpdateError'));
      }
  
      setError(t('dashboard.myProfile.profileUpdateError'));
    }
    
  };

  const handleDeleteAccount = async () => {
    try {
      await deleteUserAccount();
      // Rediriger vers la page de connexion après la suppression du compte
      navigate("/login");
    } catch (error) {
      setError("Failed to delete account: " + error.message);
    }
  };

  const toggleEditing = () => {
    setIsEditing((prevState) => !prevState);
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    const date = parseISO(dateString);
    return format(date, "yyyy-MM-dd");
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value ? new Date(value.replace(/\//g, "-")).toISOString() : null,
    }));
  };

  const handleProfilePictureUpdate = (newPictureUrl) => {
    setProfilePicture(newPictureUrl);
    setProfile((prevProfile) => ({
      ...prevProfile,
      profile_picture: newPictureUrl,
    }));
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files?.[0];
    if (!file) {
      console.error("No file selected.");
      return;
    }

    try {
      const photoUrl = await uploadProfilePicture(file);
      console.log("Profile picture uploaded:", photoUrl);

      setProfilePicture(photoUrl);

      await updateUserProfile({ photo: photoUrl });

      setProfile((prevProfile) => ({
        ...prevProfile,
        photo: photoUrl,
      }));

      console.log("Profile updated with new picture URL:", photoUrl);
    } catch (error) {
      console.error("Error uploading profile picture:", error.message);
    }
  };

  const EditPassword = ({ userId, onSuccess }) => {
    const { t } = useTranslation();
    const [passwordData, setPasswordData] = useState({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handlePasswordChange = async (e) => {
      e.preventDefault();

      if (passwordData.newPassword !== passwordData.confirmPassword) {
        setError(t('dashboard.myProfile.security.motDePasse.mismatch'));
        return;
      }

      try {
        const response = await fetch(`${API_URL}/change-password`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userId,
            currentPassword: passwordData.currentPassword,
            newPassword: passwordData.newPassword,
          }),
        });

        if (response.ok) {
          setSuccessMessage(t("passwordUpdatedSuccessfully"));
          setTimeout(() => setSuccessMessage(""), 3000);
          setPasswordData({
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
          setError("");
          if (onSuccess) onSuccess();
        } else {
          const data = await response.json();
          setError(data.message || t("updatePasswordFailed"));
        }
      } catch {
        setError(t("updatePasswordError"));
      }
    };

    return (
      <div className="passwordbox">
        <form onSubmit={handlePasswordChange}>
          {error && <p className="error-message">{error}</p>}
          {successMessage && (
            <p className="success-message">{successMessage}</p>
          )}
          <br></br>
          <label>{t("Current Password")}</label>
          <input
            type="password"
            value={passwordData.currentPassword}
            onChange={(e) =>
              setPasswordData({
                ...passwordData,
                currentPassword: e.target.value,
              })
            }
            required
          />
          <label>{t("New Password")}</label>
          <input
            type="password"
            value={passwordData.newPassword}
            onChange={(e) =>
              setPasswordData({ ...passwordData, newPassword: e.target.value })
            }
            required
          />
          <label>{t("Confirm New Password")}</label>
          <input
            type="password"
            value={passwordData.confirmPassword}
            onChange={(e) =>
              setPasswordData({
                ...passwordData,
                confirmPassword: e.target.value,
              })
            }
            required
          />{" "}
          <br></br> <br></br>
          <button type="submit" className="update-button">
            {t("update")}
          </button>
        </form>
      </div>
    );
  };

  if (!profile) return <div>{t('common.loading')}</div>;

  const languages = [
    { value: "english", label: "English" },
    { value: "french", label: "Français" },
    { value: "spanish", label: "Español" },
    { value: "portuguese", label: "Português" },
    { value: "italian", label: "Italiano" },
    { value: "german", label: "Deutsch" },
  ];

  const tones = [
    { value: 'professional', label: t('dashboard.myProfile.fields.communicationTone.professional') },
    { value: 'casual', label: t('dashboard.myProfile.fields.communicationTone.casual') },
    { value: 'friendly', label: t('dashboard.myProfile.fields.communicationTone.friendly') },
    { value: 'formal', label: t('dashboard.myProfile.fields.communicationTone.formal') }
  ];

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    handleInputChange(e);
  };

  return (
    <div className="my-profile">
      <h2>{t('dashboard.myProfile.title')}</h2>

      {error && <p className="error-message">{error}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}

      <div className="profile-section">
        <br></br>
        <h3>{t('dashboard.myProfile.security.motDePasse.title')}</h3>
        <EditPassword
          userId={profile?.id}
          onSuccess={() => setSuccessMessage("Password updated successfully!")}
        />
      </div>

      <form onSubmit={handleSubmit} className="profile-form">
        <div className="profile-section">
          
          <h3>{t('dashboard.myProfile.fields.personalInformation')}</h3>
          <div className="profile-picture-section">
  <h3>{t('dashboard.myProfile.fields.profilePicture')}</h3>

  { }
  {profilePicture ? (
    <img
  src={
    profilePicture
      ? profilePicture.startsWith("http")
        ? profilePicture
        : `${process.env.REACT_APP_API_URL || ""}${profilePicture}`
      : "/default-avatar.png"  
  }
  alt="Profile"
  className="profile-picture"
/>
  ) : (
    <p className="no-profile-picture">{t('dashboard.myProfile.fields.noProfilePicture')}</p>
  )}

  { }
  {isEditing && (
    <div className="profile-picture-upload">
      <input
        type="file"
        id="profile-photo-input"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={(e) => handleImageUpload(e)}
      />
      <label htmlFor="profile-photo-input" className="profile-picture-button">
        {t('dashboard.myProfile.fields.chooseFile')}
      </label>
    </div>
  )}
</div>
          <label>
            {t('dashboard.myProfile.fields.firstName')}
            <input
              type="text"
              name="first_name"
              value={profile.first_name || ""}
              onChange={handleInputChange}
              disabled={!isEditing}
            />
          </label>
          <label>

            {t('dashboard.myProfile.fields.lastName')}
            <input
              type="text"
              name="last_name"
              value={profile.last_name}
              onChange={handleInputChange}
              disabled={!isEditing}
            />
          </label>
          <label>

            {t('dashboard.myProfile.fields.email')}
            <input
              type="email"
              name="email"
              value={profile.email}
              onChange={handleInputChange}
              disabled={!isEditing}
            />
          </label>
          <label>

            {t('dashboard.myProfile.fields.phone')}
            <input
              type="tel"
              name="phone"
              value={profile.phone}
              onChange={handleInputChange}
              disabled={!isEditing}
            />
          </label>
          <label>
            {t('dashboard.myProfile.fields.dateOfBirth')}
            <input
              type="date"
              name="date_of_birth"
              value={formatDateForInput(profile.date_of_birth)}
              onChange={handleDateChange}
              disabled={!isEditing}
            />
          </label>
        </div>
        <div className="profile-section">
          <h3>{t('dashboard.myProfile.fields.companyName')}</h3>
          <label>
            {t('dashboard.myProfile.fields.familyCompanyName')}
            <input
              type="text"
              value={profile.Company.name}
              disabled
            />
                
                
          </label>
          <label>
            {t('dashboard.myProfile.fields.companyName')}
            <input
              type="text"
              name="company_name"
              value={profile.company_name}
              onChange={handleInputChange}
              disabled={!isEditing}
            />
          </label>
          <label>
            {t('dashboard.myProfile.fields.jobRole')}
            <input
              type="text"
              name="job_role"
              value={profile.job_role}
              onChange={handleInputChange}
              disabled={!isEditing}
            />
          </label>
          <label>
            {t('dashboard.myProfile.fields.costCenter')}
            <input
              type="text"
              name="cost_center"
              value={profile.cost_center}
              onChange={handleInputChange}
              disabled={!isEditing}
            />
          </label>
          <label>

            {t('dashboard.myProfile.fields.companyDescription')}
            <textarea
              name="company_description"
              value={profile.company_description}
              onChange={handleInputChange}
              disabled={!isEditing}
            />
          </label>
        </div>
        <div className="profile-section">

          <h3>{t('dashboard.myProfile.fields.address')}</h3>
          <label>
            {t('dashboard.myProfile.fields.address')}
            <input
              type="text"
              name="address"
              value={profile.address}
              onChange={handleInputChange}
              disabled={!isEditing}
            />
          </label>
          <label>

            {t('dashboard.myProfile.fields.city')}
            <input
              type="text"
              name="city"
              value={profile.city}
              onChange={handleInputChange}
              disabled={!isEditing}
            />
          </label>
          <label>

            {t('dashboard.myProfile.fields.zipCode')}
            <input
              type="text"
              name="zip_code"
              value={profile.zip_code}
              onChange={handleInputChange}
              disabled={!isEditing}
            />
          </label>
          <label>

            {t('dashboard.myProfile.fields.country')}
            <input
              type="text"
              name="country"
              value={profile.country}
              onChange={handleInputChange}
              disabled={!isEditing}
            />
          </label>
        </div>
        <div className="profile-section">

          <h3>{t('dashboard.myProfile.sections.preferences')}</h3>
          <label>
            {t('dashboard.myProfile.fields.languagePreference')}
            <select
              name="language_preference"
              value={profile?.language_preference || "english"}
              onChange={handleLanguageChange}
              disabled={!isEditing}
              className="language-select"
            >
              {languages.map((lang) => (
                <option key={lang.value} value={lang.value}>
                  {lang.label}
                </option>
              ))}
            </select>
          </label>
          <label>

            {t('dashboard.myProfile.fields.communicationTone.title')}
            <select
              name="tone_preferred"
              value={profile?.tone_preferred || "professional"}
              onChange={handleInputChange}
              disabled={!isEditing}
              className="language-select"
            >
              {tones.map((tone) => (
                <option key={tone.value} value={tone.value}>
                  {tone.label}
                </option>
              ))}
            </select>
          </label>
        </div>

        {isEditing ? (
          <>
            <button type="submit" className="save-profile-button">{t('dashboard.myProfile.fields.saveProfile')}</button>
            <button type="button" onClick={toggleEditing} className="cancel-button">{t('cancel')}</button>
          </>
        ) : (
          <button type="button" onClick={toggleEditing} className="edit-profile-button">{t('dashboard.myProfile.fields.editProfile')}</button>
        )}
      </form>
      <button onClick={() => setShowDeleteModal(true)} className="delete-account-button">{t('dashboard.myProfile.fields.deleteAccount')}</button>
      
      <Modal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('dashboard.myProfile.fields.confirmDeleteAccount')}
      >
        <p>{t('dashboard.myProfile.fields.deleteAccountWarning')}</p>
        <button onClick={handleDeleteAccount} className="confirm-delete-button">{t('dashboard.myProfile.fields.confirmDelete')}</button>
        <button onClick={() => setShowDeleteModal(false)} className="cancel-button">{t('dashboard.myProfile.fields.cancel')}</button>
      </Modal>
    </div>
  );
};

export default B2BMyProfile;
