import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
 
import { FaEdit, FaSave, FaUserPlus } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import "./AssesmentDetailPageHardSkills.scss";
import { API_URL } from "../../../../shared.js";

const AssesmentDetailPageHardSkills = () => {
  const location = useLocation();
  const { title, type } = location.state;
  const [data, setData] = useState(null);
  const [htmlContent, setHtmlContent] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("title et type", title, type);
        const response = await fetch(
          `${API_URL}/hardskillsassessmentgenerated/${title}/${type}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
          }
        );
        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);
        const result = await response.json();
        setHtmlContent(result.data); // Assure que les données sont en HTML
        setData(result);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="assessment-page">
      <div className="header">
        <h1>Hard-Skills Assessment</h1>
        <button type="button" className="invite-btn">
          <FaUserPlus /> Invite
        </button>
      </div>

      <div className="content-section">
        {loading && (
          <div className="loading-spinner">
            <ClipLoader color="#6920b0" size={60} />
          </div>
        )}
        {error && <p className="error">Error: {error}</p>}

        {/* AFFICHAGE DIRECT DU HTML */}
        {htmlContent && (
          <div
            className="html-render"
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          />
        )}
      </div>

      <div className="actions">
        <button type="button" className="edit-btn">
          <FaEdit /> Edit
        </button>
        <button type="button" className="save-btn">
          <FaSave /> Save
        </button>
      </div>
    </div>
  );
};

export default AssesmentDetailPageHardSkills;