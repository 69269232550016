import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./JobOpenings.scss";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../../shared";

// adding a viewMode prop to the JobOpenings component to allow for switching between list and card views on the dashboard
const JobOpenings = ({ viewMode = "cards" }) => {
  const [jobOffers, setJobOffers] = useState([]);
  const [manualJobOffers, setManualJobOffers] = useState([]);
  const [aiJobOffers, setAiJobOffers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterContractType, setFilterContractType] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [closedJobOffers, setClosedJobOffers] = useState([]);
  const [modalData, setModalData] = useState(null);

  // const for deleting button create job on main dashboard
  const isDashboard = location.pathname === "/b2b/dashboard";

  useEffect(() => {
    const fetchJobOffers = async () => {
      try {
        const response = await fetch(`${API_URL}/job-offers`, {
          credentials: "include",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch job offers");
        }

        const data = await response.json();
        console.log("Données reçues :", data);

        const manualOffers = data.filter(
          (offer) => !offer.is_ai_generated && !offer.inactive
        );
        const aiOffers = data.filter(
          (offer) => offer.is_ai_generated && !offer.inactive
        );
        const closedOffers = data.filter((offer) => offer.inactive === true);

        setManualJobOffers(manualOffers);
        setAiJobOffers(aiOffers);
        setClosedJobOffers(closedOffers);
      } catch (error) {
        console.error("Error fetching job offers:", error);
      }
    };

    fetchJobOffers();
  }, []);

  console.log("JobOffers", jobOffers);

  const filterJobs = (jobs) => {
    console.log("Jobs before filtering:", jobs);
    console.log("Current filter:", filterContractType);

    return jobs.filter((job) => {
      const searchTermLower = searchTerm.toLowerCase();

      // Check role match
      const roleMatch = job.role?.toLowerCase().includes(searchTermLower);

      // Check skills match
      const skillsMatch = job.required_skills?.some((skill) =>
        skill.toLowerCase().includes(searchTermLower)
      );

      // Check location match
      const locationMatch = job.location
        ?.toLowerCase()
        .includes(searchTermLower);

      // Check contract type filter
      const contractMatch =
        !filterContractType ||
        job.contract_type?.toLowerCase() === filterContractType.toLowerCase();

      // Return true if any of the searchable fields match AND contract type matches
      return (roleMatch || skillsMatch || locationMatch) && contractMatch;
    });
  };

  // adding new components for display list on dashboard
  const JobList = ({ jobs }) => {
    return (
      <div>
        <table className="job-list-table">
          <thead>
            <tr>
              <th>Titre</th>
              <th>Type de contrat</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {jobs.length > 0 ? (
              jobs.map((job) => {
                return (
                  <tr key={job.id}>
                    <td>{job.role}</td>
                    <td>
                      {t(
                        `jobOpenings.filters.contractType.${
                          job.contract_type?.toLowerCase() === "full-time"
                            ? "full-time"
                            : job.contract_type?.toLowerCase() === "part-time"
                            ? "part-time"
                            : job.contract_type?.toLowerCase() === "contract"
                            ? "contract"
                            : job.contract_type?.toLowerCase() === "internship"
                            ? "internship"
                            : "all"
                        }`
                      )}
                    </td>
                    <td>
                      <button
                        type="button"
                        className="details-button"
                        onClick={() =>
                          navigate(`/b2b/dashboard/job-openings/${job.id}`)
                        }
                      >
                        Voir les détails
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="3">Aucune offre trouvée</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const JobCards = ({ jobs }) => (
    <div className="job-cards-grid">
      {jobs.length > 0 ? (
        jobs.map((job) => (
          <div key={job.id} className="job-card">
            <h4>{job.role}</h4>
            <p>Type de contrat : {job.contract_type}</p>
          </div>
        ))
      ) : (
        <div className="no-jobs-message">Aucune offre trouvée</div>
      )}
    </div>
  );

  const handlePublishToggleManual = (jobId, isPublic) => {
    setManualJobOffers((prevJobs) =>
      prevJobs.map((job) =>
        job.id === jobId ? { ...job, is_public: isPublic } : job
      )
    );
  };

  const handlePublishToggleAI = (jobId, isPublic) => {
    setAiJobOffers((prevJobs) =>
      prevJobs.map((job) =>
        job.id === jobId ? { ...job, is_public: isPublic } : job
      )
    );
  };

  const handlePublishToggleClosed = (jobId, inactive) => {
    setClosedJobOffers((prevJobs) =>
      prevJobs.map((job) =>
        job.id === jobId ? { ...job, inactive: inactive } : job
      )
    );
  };

  const JobCard = ({ job, onDelete, onPublishToggle, onInactiveToggle }) => {
    const formatDate = (dateString) => {
      if (!dateString) return t("jobOpenings.jobCard.noDate");
      try {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
          return t("jobOpenings.jobCard.noDate");
        }
        return date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
      } catch (error) {
        return t("jobOpenings.jobCard.noDate");
      }
    };

    const handleDelete = async (job) => {
      if (window.confirm(t("jobOpenings.jobCard.deleteConfirm"))) {
        try {
          const response = await fetch(`${API_URL}/job-offers/${job.id}`, {
            method: "DELETE",
            credentials: "include",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          });

          if (response.ok) {
            setJobOffers((prevJobs) =>
              prevJobs.filter((prevJob) => prevJob.id !== job.id)
            );
            setManualJobOffers((prevJobs) =>
              prevJobs.filter((prevJob) => prevJob.id !== job.id)
            );
            setAiJobOffers((prevJobs) =>
              prevJobs.filter((prevJob) => prevJob.id !== job.id)
            );
          } else {
            console.error(t("jobOpenings.errors.deleteFailed"));
          }
        } catch (error) {
          console.error(t("jobOpenings.errors.deleteFailed"), error);
        }
      }
    };

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showCloseConfirm, setShowCloseConfirm] = useState(false);

    const handlePublishToggle = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(
          `${API_URL}/job-offers/${job.id}/toggle-publish`,
          {
            method: "PUT",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to toggle publish status");
        }

        const data = await response.json();
        onPublishToggle(job.id, data.is_public);
      } catch (error) {
        setError(t("jobOpenings.errors.togglePublishFailed"));
        console.error("Error toggling job publish status:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const handleEdit = () => {
      navigate(`/b2b/dashboard/job-openings/${job.id}/edit`);
    };

    const handleCloseClick = () => {
      console.log("Close button clicked for job:", job);
      setModalData({ job, onInactiveToggle: handleCloseToggle });
    };

    return (
      <div className="job-card">
        <div className="job-card-header">
          <div className="title-with-badge">
            <h4>{job.role}</h4>
            <span
              className={`status-badge ${
                job.is_public ? "published" : "draft"
              }`}
            >
              {job.is_public
                ? t("jobOpenings.jobCard.status.published")
                : t("jobOpenings.jobCard.status.draft")}
            </span>
          </div>
        </div>
        <div className="job-card-content">
          <div className="job-info">
            <span className="contract-type">
              {t(
                `jobOpenings.filters.contractType.${
                  job.contract_type?.toLowerCase() === "full-time"
                    ? "full-time"
                    : job.contract_type?.toLowerCase() === "part-time"
                    ? "part-time"
                    : job.contract_type?.toLowerCase() === "contract"
                    ? "contract"
                    : job.contract_type?.toLowerCase() === "internship"
                    ? "internship"
                    : "all"
                }`
              )}
            </span>
            <span className="role-level">{job.role_level}</span>
          </div>
          <div className="job-meta">
            <span className="created-date">
              {t("jobOpenings.jobCard.created")}:{" "}
              {formatDate(job.createdAt || job.created_at)}
            </span>
          </div>
        </div>
        <div className="job-card-actions">
          <div className="action-buttons-row">
            <Link
              to={`/b2b/dashboard/job-openings/${job.id}`}
              className="details-button"
            >
              {t("jobOpenings.jobCard.actions.viewDetails")}
            </Link>
            <button
              type="button"
              className="edit-button"
              onClick={() => navigate(`${job.id}/edit`)}
            >
              {t("jobOpenings.jobCard.actions.edit")}
            </button>

            <button className="delete-button" onClick={() => handleDelete(job)}>
              {t("jobOpenings.jobCard.actions.delete")}
            </button>
          </div>
          <button
            type="button"
            className={`publish-button ${job.is_public ? "unpublish" : ""}`}
            onClick={handlePublishToggle}
            disabled={isLoading}
          >
            {isLoading ? (
              t("jobOpenings.jobCard.actions.loading")
            ) : job.is_public ? (
              <>
                <i className="fas fa-eye-slash"></i>
                {t("jobOpenings.jobCard.actions.unpublish")}
              </>
            ) : (
              <>
                <i className="fas fa-globe"></i>
                {t("jobOpenings.jobCard.actions.publish")}
              </>
            )}
          </button>
          <button
            type="button"
            className={`close-button ${job.inactive ? "reopen" : ""}`}
            onClick={handleCloseClick}
          >
            {job.inactive ? (
              <>
                <i className="fas fa-door-open"></i>
                {t("jobOpenings.closeModal.reopenTitle")}
              </>
            ) : (
              <>
                <i className="fas fa-door-closed"></i>
                {t("jobOpenings.closeModal.closeTitle")}
              </>
            )}
          </button>
        </div>
      </div>
    );
  };

  const handleCloseToggle = async (job) => {
    try {
      const response = await fetch(
        `${API_URL}/job-offers/${job.id}/toggle-inactive`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (!response.ok) throw new Error("Failed to toggle inactive status");
      const updatedJob = { ...job, inactive: !job.inactive };
      setManualJobOffers((prev) => prev.filter((j) => j.id !== updatedJob.id));
      setAiJobOffers((prev) => prev.filter((j) => j.id !== updatedJob.id));
      setClosedJobOffers((prev) => prev.filter((j) => j.id !== updatedJob.id));
      if (updatedJob.inactive) {
        setClosedJobOffers((prev) => [...prev, updatedJob]);
      } else {
        if (updatedJob.is_ai_generated) {
          setAiJobOffers((prev) => [...prev, updatedJob]);
        } else {
          setManualJobOffers((prev) => [...prev, updatedJob]);
        }
      }

      setModalData(null);
    } catch (error) {
      console.error("Error toggling inactive status:", error);
    }
  };

  // Add this useEffect to monitor closedJobOffers changes
  useEffect(() => {
    console.log("closedJobOffers updated:", closedJobOffers);
  }, [closedJobOffers]);

  // adding viewMode to display list on dashboard and keeping the cards view
  const JobSection = ({
    jobs,
    title,
    onDeleteJob,
    filteredJobs = filterJobs(jobs),
    onPublishToggle,
    viewMode = "cards",
  }) => {
    useEffect(() => {
      console.log(`JobSection ${title} received jobs:`, jobs);
    }, [jobs, title]);

    return (
      <div className="job-section">
        <h3>{title}</h3>
        {Array.isArray(filteredJobs) && filteredJobs.length > 0 ? (
          viewMode === "list" ? (
            <JobList jobs={filteredJobs} />
          ) : (
            <div className="job-cards-grid">
              {filteredJobs.map((job) => (
                <JobCard
                  key={`${job.id}-${Date.now()}`}
                  job={job}
                  onDelete={onDeleteJob}
                  onPublishToggle={onPublishToggle}
                  onInactiveToggle={handleCloseToggle}
                />
              ))}
            </div>
          )
        ) : (
          <div className="empty-state">
            <p>{t("jobOpenings.noJobsFound.message")}</p>
          </div>
        )}
      </div>
    );
  };

  // const handleCreateClick = () => {
  //   navigate("/b2b/dashboard/create-job-opening");
  // };
  const handleCreateClick = () => {
    navigate("/b2b/dashboard/job-openings/create");
  };

  return (
    <div className="job-openings">
      <div className="job-openings-header">
        <div className="header-content">
          <h2>{t("jobOpenings.title")}</h2>
          <p>{t("jobOpenings.subtitle")}</p>
        </div>
        {!isDashboard && ( // if not on the main dashboard, display the create button
          <div className="header-actions">
            <button className="create-button" onClick={handleCreateClick}>
              <i className="fas fa-plus"></i> {t("jobOpenings.createButton")}
            </button>
          </div>
        )}
      </div>

      <div className="search-filters">
        <div className="search-box">
          <i className="fas fa-search"></i>
          <input
            type="text"
            placeholder={t("jobOpenings.search.placeholder")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="filter-container">
          <select
            value={filterContractType}
            onChange={(e) => setFilterContractType(e.target.value)}
            className="filter-select"
          >
            <option value="">
              {t("jobOpenings.filters.contractType.all")}
            </option>
            <option value="full-time">
              {t("jobOpenings.filters.contractType.full-time")}
            </option>
            <option value="part-time">
              {t("jobOpenings.filters.contractType.part-time")}
            </option>
            <option value="contract">
              {t("jobOpenings.filters.contractType.contract")}
            </option>
            <option value="internship">
              {t("jobOpenings.filters.contractType.internship")}
            </option>
          </select>
        </div>
      </div>

      <div className="job-sections">
        <JobSection
          key="manual"
          jobs={filterJobs(manualJobOffers)}
          title={t("jobOpenings.sections.manual")}
          onDeleteJob={setManualJobOffers}
          onPublishToggle={handlePublishToggleManual}
          onInactiveToggle={handleCloseToggle}
          viewMode={viewMode}
        />
        <JobSection
          key="ai"
          jobs={filterJobs(aiJobOffers)}
          title={t("jobOpenings.sections.ai")}
          onDeleteJob={setAiJobOffers}
          onPublishToggle={handlePublishToggleAI}
          viewMode={viewMode}
        />
        <JobSection
          key="closed"
          jobs={filterJobs(closedJobOffers)}
          title={t("jobOpenings.sections.closed")}
          onDeleteJob={setClosedJobOffers}
          onPublishToggle={handlePublishToggleClosed}
          onInactiveToggle={handleCloseToggle}
          viewMode={viewMode}
        />
      </div>

      {/* Modal rendered at root level */}
      {modalData && (
        <div className="modal-overlay" onClick={() => setModalData(null)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>
              {modalData.job.inactive
                ? t("jobOpenings.closeModal.reopenTitle")
                : t("jobOpenings.closeModal.closeTitle")}
            </h3>
            <p>
              {modalData.job.inactive
                ? t("jobOpenings.closeModal.reopenMessage", {
                    role: modalData.job.role,
                  })
                : t("jobOpenings.closeModal.closeMessage", {
                    role: modalData.job.role,
                  })}
            </p>
            <div className="modal-actions">
              <button onClick={() => setModalData(null)}>
                {t("jobOpenings.closeModal.cancel")}
              </button>
              <button onClick={() => handleCloseToggle(modalData.job)}>
                {t("jobOpenings.closeModal.confirm")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobOpenings;
