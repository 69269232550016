import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from './ThemeContext';
import { MdColorLens } from 'react-icons/md';
import './Settings.scss';

const Settings = () => {
  const { t } = useTranslation();
 
  const { customColors, updateCustomColors, logo, updateLogo, addOnWhiteLabel, clientConsole, isLoading } =
  useContext(ThemeContext);


  console.log('addOnWhiteLabel value in Settings:', addOnWhiteLabel);


  if (isLoading) {
    return <div>Loading settings...</div>;
  }
  
  if (!addOnWhiteLabel) {
    return <div>Access restricted: WhiteLabel settings are not available.</div>;
  }
  const [primaryColor, setPrimaryColor] = useState(customColors.primaryColor);
  const [secondaryColor, setSecondaryColor] = useState(customColors.secondaryColor);
  const [newLogo, setNewLogo] = useState(logo);
  const [isSaving, setIsSaving] = useState(false);

  const backgroundColors = [
    '#6920b0',
    '#3885CC',
    '#05ADA2',
    '#F27802',
     
    '#212121',
  ];

  const textColors = [
    '#fa41cc',
    '#3885CC',
    '#05ADA2',
    '#F27802',
    '#212121',
     
  ];
  const handleColorChange = (color, type) => {
    if (type === 'primary') {
      setPrimaryColor(color);
      updateCustomColors({ primaryColor: color });
    } else if (type === 'secondary') {
      setSecondaryColor(color);
      updateCustomColors({ secondaryColor: color });
    }
  };





  const handleLogoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('logo', file);

      try {
        const response = await fetch('/api/upload-logo', {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          setNewLogo(data.logo);
          updateLogo(data.logo);
        } else {
          console.error('Failed to upload logo');
        }
      } catch (err) {
        console.error('Error uploading logo:', err);
      }
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const response = await fetch('/api/update-user-settings', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          primaryColor,
          secondaryColor,
          logo: newLogo,
        }),
      });

      if (response.ok) {
        alert('Settings saved successfully!');
      } else {
        console.error('Failed to save settings');
      }
    } catch (err) {
      console.error('Error saving settings:', err);
    } finally {
      setIsSaving(false);
    }
  };



    return (
    <div className="settings-page">
      <div className="settings-container">
        <h2>{t('settings.title')}</h2>

        <section className="settings-section">
          <h3>{t('dashboard.settings.colorCustomization.title')}</h3>
          <p>{t('dashboard.settings.colorCustomization.description')}</p>
          <div className="color-picker">
            <div>
              <h4>{t('dashboard.settings.colorCustomization.globalColor')}</h4>
              <div className="palette">
                {backgroundColors.map((color) => (
                  <button
                    key={color}
                    style={{
                      backgroundColor: color,
                      border: color === '#FFFFFF' ? '1px solid #000' : '1px solid #ccc',
                      width: '40px',
                      height: '40px',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleColorChange(color, 'primary')}
                  />
                ))}
                { }
                <button
                  className="color-picker-icon"
                  onClick={() => document.getElementById('primary-color-picker').click()}
                  style={{
                    backgroundColor: primaryColor,
                    border: '2px solid #e0e0e0',
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    marginLeft: '25px',
                  }}
                >
                  <MdColorLens size={24} color="#000" />
                </button>
                <input
                  id="primary-color-picker"
                  type="color"
                  value={primaryColor}
                  onChange={(e) => handleColorChange(e.target.value, 'primary')}
                  style={{ display: 'none' }}
                />
              </div>
            </div>

            <div>
              <h4>{t('dashboard.settings.colorCustomization.headlineColor')}</h4>
              <div className="palette">
                {textColors.map((color) => (
                  <button
                    key={color}
                    style={{
                      backgroundColor: color,
                      border: color === '#FFFFFF' ? '1px solid #000' : '1px solid #ccc',
                      width: '40px',
                      height: '40px',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleColorChange(color, 'secondary')}
                  />
                ))}
                { }
                <button
                  className="color-picker-icon"
                  onClick={() => document.getElementById('secondary-color-picker').click()}
                  style={{
                    backgroundColor: secondaryColor,
                    border: '2px solid #e0e0e0',
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    marginLeft: '25px',
                  }}
                >
                  <MdColorLens size={24} color="#000" />
                </button>
                <input
                  id="secondary-color-picker"
                  type="color"
                  value={secondaryColor}
                  onChange={(e) => handleColorChange(e.target.value, 'secondary')}
                  style={{ display: 'none' }}
                />





              </div>
            </div>
          </div>
        </section>

        <section className="settings-section">
          <h3>{t('dashboard.settings.logoCustomization.title')}</h3>
          <p>{t('dashboard.settings.logoCustomization.description')}</p>
          <div className="logo-upload-container">
            <label className="upload-label">
              {t('dashboard.settings.logoCustomization.chooseFile')}
              <input type="file" accept="image/*" onChange={handleLogoUpload} />
            </label>
            {newLogo && (
              <div className="logo-preview">
                <img src={newLogo} alt={t('dashboard.settings.logoCustomization.preview')} />
              </div>
            )}
          </div>
        </section>

        <button className="save-button" onClick={handleSave} disabled={isSaving}>
          {isSaving ? t('common.saving') : t('common.save')}
        </button>

      </div>
    </div>
  );
};

export default Settings;
