async function fetchWithRetry(url, options, maxRetries = 3) {
  for (let i = 0; i < maxRetries; i++) {
    try {
      const response = await fetch(url, options);
      if (response.ok) return response;
    } catch (err) {
      if (i === maxRetries - 1) throw err;
    }
    // Wait before retrying (exponential backoff)
    await new Promise(resolve => setTimeout(resolve, Math.pow(2, i) * 1000));
  }
  throw new Error('Max retries reached');
}

export function logError(error) {
  console.error('Client-side error:', error);
  // Commentez temporairement l'envoi au serveur
  // fetchWithRetry('/api/log-error', {
  //   method: 'POST',
  //   headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify(errorData)
  // }).catch(err => console.error('Failed to log error after retries:', err));
}
