// export const API_URL = (() => {
//     const baseURL = process.env.REACT_APP_API_URL_4000?.replace(/\/+$/, '') || ''; // Nettoie l'URL de base
//     return baseURL.endsWith('/api') ? baseURL : `${baseURL}/api`; // Ajoute "/api" si nécessaire
//   })();

// export const API_URL = process.env.NODE_ENV === 'production' 
//   ? 'https://backend-production-717e.up.railway.app/api'  // Add /api prefix
//   : 'http://localhost:4000/api';  // Add /api prefix
  
export const API_URL = (() => {
  // Détermine l'URL de base selon l'environnement
  const baseURL =
      process.env.NODE_ENV === 'production'
          ? process.env.REACT_APP_API_URL_4000?.replace(/\/+$/, '') || 'https://www.hrnov.ai'
          : 'http://localhost:4000';

  // Ajoute "/api" à la fin si ce n'est pas déjà présent
  return baseURL.endsWith('/api') ? baseURL : `${baseURL}/api`;
})();

