import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./AssessmentsList.scss";
import { API_URL } from "../../../../shared.js";

const fetchAiConversation = async () => {
  const aiConvesation = await fetch(`${API_URL}/all-assessments`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  const result = await aiConvesation.json();
  console.log("teeeest result", result);
  return result;
};

const AssessmentsList = ({ userAddOns = {} }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedAssessmentType, setSelectedAssessmentType] = useState("");
  const [aiConversation, setAiConversation] = useState([]);

  const hasSkillsAssessment = userAddOns?.add_on_skills_assessment || true; // TODO: change to false when we have the real data
  const hasLanguageAssessment = userAddOns?.add_on_language_assessment || true; // TODO: change to false when we have the real data

  const handleAssessmentTypeChange = (e) => {
    const type = e.target.value;
    setSelectedAssessmentType(type);

    // Redirect to the appropriate creation page based on type
    switch (type) {
      case "soft":
        navigate("/b2b/dashboard/assessments/create/soft-skills");
        break;
      case "hard":
        navigate("/b2b/dashboard/assessments/create/hard-skills");
        break;
      case "language":
        navigate("/b2b/dashboard/assessments/create/language-skills");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      const result = await fetchAiConversation();
      console.log("result :", result);
      if (isMounted) setAiConversation(result);
    };

    fetchData();

    return () => {
      isMounted = false; // Nettoyage pour éviter des effets secondaires
    };
  }, []);

  const handleActionSelect = (event, assessmentId) => {
    const action = event.target.value;
    switch (action) {
      case "view":
        navigate(
          `/b2b/dashboard/assessments/analysis-language/${assessmentId}`
        );
        break;
      case "edit":
        console.log("Edit assessment:", assessmentId);
        break;
      case "delete":
        console.log("Delete assessment:", assessmentId);
        break;
      default:
        break;
    }
  };

  return (
    <div className="assessments-list">
      <div className="assessments-header">
        <h2>{t("assessments.title")}</h2>
        <select
          className="create-assessment-select"
          value={selectedAssessmentType}
          onChange={handleAssessmentTypeChange}
        >
          <option value="" disabled>
            {t("assessments.create")}
          </option>
          {hasSkillsAssessment && (
            <>
              <option value="soft">{t("assessments.createSoftSkills")}</option>
              <option value="hard">{t("assessments.createHardSkills")}</option>
            </>
          )}
          {hasLanguageAssessment && (
            <option value="language">
              {t("assessments.createLanguageSkills")}
            </option>
          )}
        </select>
      </div>
      <table className="assessments-table">
        <thead>
          <tr>
            <th>{t("assessments.createdAt")}</th>
            <th>{t("assessments.jobOpening")}</th>
            <th>{t("Talexa.subscriptionDashBoardPage.subscription.type")}</th>
            <th>{t("assessments.actions")}</th>
          </tr>
        </thead>
        <tbody>
          {aiConversation.map((assessment) => (
            <tr key={assessment.id}>
              <td>{new Date(assessment.createdAt).toLocaleDateString()}</td>
              <td>{assessment.title}</td>
              <td>{assessment.type}</td>
              <td className="actions-column">
                <select
                  className="action-select"
                  onChange={(e) => handleActionSelect(e, assessment.id)}
                  defaultValue="default"
                >
                  <option value="default" disabled>
                    {t("assessments.selectAction")}
                  </option>
                  <option value="view">{t("assessments.view")}</option>
                  <option value="edit">{t("assessments.edit")}</option>
                  <option value="delete">{t("assessments.delete")}</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AssessmentsList;
