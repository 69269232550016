const API_URL = process.env.REACT_APP_API_URL || "http://localhost:4000";

export const addCandidateToPipeline = async (candidateData) => {
  try {
    const response = await fetch(`${API_URL}/api/candidates-pipeline`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(candidateData)
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.message || 'Failed to add candidate to pipeline');
    }

    return await response.json();
  } catch (error) {
    console.error('Error adding candidate to pipeline:', error);
    throw error;
  }
};