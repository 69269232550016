import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getJobOfferDetails } from '../services/jobOfferService';
import { FaMapMarkerAlt, FaBriefcase, FaClock, FaBuilding, FaArrowLeft, FaLaptopHouse, FaHome } from 'react-icons/fa';
import './JobBoardDetails.scss';

const JobBoardDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const jobBoardPath = window.location.pathname.includes('/b2b/dashboard/job-board') 
    ? '/b2b/dashboard/job-board' 
    : window.location.pathname.includes('/b2b') 
    ? '/b2b/job-board' 
    : '/b2c/job-board';
  

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        setLoading(true);
        const data = await getJobOfferDetails(id);
        setJobDetails(data);
      } catch (err) {
        console.error('Error fetching job details:', err);
        setError(t('jobBoard.details.error'));
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [id, t]);

  const formatSalary = (min, max) => {
    if (!min && !max) return t('jobBoard.details.salaryNotSpecified');
    if (!max) return `${t('jobBoard.details.minSalary')}: ${min}€`;
    if (!min) return `${t('jobBoard.details.maxSalary')}: ${max}€`;
    return `${min}€ - ${max}€`;
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  if (loading) {
    return (
      <div className="job-details-loading">
        <div className="loading-spinner"></div>
        <p>{t('jobBoard.details.loading')}</p>
      </div>
    );
  }

  if (error || !jobDetails) {
    return (
      <div className="job-details-error">
        <h2>{t('jobBoard.details.error')}</h2>
        <button onClick={() => navigate(`${jobBoardPath}/job-board`)} className="back-button">
          <FaArrowLeft /> {t('jobBoard.details.backToList')}
        </button>
      </div>
    );
  }


  return (
    <div className="job-details-page">
      <div className="job-details-container">
        <button onClick={() => navigate(jobBoardPath)} className="back-button">
          <FaArrowLeft /> {t('jobBoard.details.backToList')}
        </button>

        <div className="job-header">
          <h1>{jobDetails.role}</h1>
          <div className="job-meta">
            <div className="meta-item">
              <FaBuilding />
              <span>{jobDetails.company_name}</span>
            </div>
            <div className="meta-item">
              <FaMapMarkerAlt />
              <span>
                {jobDetails.city && jobDetails.country 
                  ? `${jobDetails.city}, ${jobDetails.country}`
                  : t('jobBoard.details.locationNotSpecified')}
              </span>
            </div>
            <div className="meta-item">
              <FaBriefcase />
              <span>{jobDetails.contract_type}</span>
            </div>
            <div className="meta-item">
              <FaClock />
              <span>{t('jobBoard.details.posted')}: {formatDate(jobDetails.created_at)}</span>
            </div>
          </div>
        </div>

        <div className="job-content">
          {jobDetails.company_description && (
            <section>
              <h2>{t('jobBoard.details.companyDescription')}</h2>
              <p>{jobDetails.company_description}</p>
            </section>
          )}

          <section>
            <h2>{t('jobBoard.details.mission')}</h2>
            <p>{jobDetails.mission}</p>
          </section>

          <section>
            <h2>{t('jobBoard.details.responsibilities')}</h2>
            <p>{jobDetails.responsibilities}</p>
          </section>

          <section>
            <h2>{t('jobBoard.details.requirements')}</h2>
            <p>{jobDetails.requirements}</p>
          </section>

          {jobDetails.skills && jobDetails.skills.length > 0 && (
            <section>
              <h2>{t('jobBoard.details.skills')}</h2>
              <div className="skills-list">
                {jobDetails.skills.map((skill, index) => (
                  <span key={index} className="skill-tag">{skill}</span>
                ))}
              </div>
            </section>
          )}

          <section className="work-environment">
            <h2>{t('jobBoard.details.workEnvironment')}</h2>
            <div className="environment-tags">
              {jobDetails.remote && (
                <span className="environment-tag">
                  <FaHome />
                  {t('jobBoard.details.remote')}
                </span>
              )}
              {jobDetails.hybrid && (
                <span className="environment-tag">
                  <FaLaptopHouse />
                  {t('jobBoard.details.hybrid')}
                </span>
              )}
              {!jobDetails.remote && !jobDetails.hybrid && (
                <span className="environment-tag">
                  <FaBuilding />
                  {t('jobBoard.details.onsite')}
                </span>
              )}
            </div>
          </section>

          {(jobDetails.min_salary || jobDetails.max_salary) && (
            <section>
              <h2>{t('jobBoard.details.salary')}</h2>
              <p className="salary">
                {formatSalary(jobDetails.min_salary, jobDetails.max_salary)}
              </p>
            </section>
          )}

          {jobDetails.perks && (
            <section>
              <h2>{t('jobBoard.details.benefits')}</h2>
              <p>{jobDetails.perks}</p>
            </section>
          )}

          <div className="apply-section">
            <button 
              className="apply-button" 
              onClick={() => navigate(`${jobBoardPath}/${id}/apply`, { 
                state: { 
                  jobTitle: jobDetails.role,
                  companyName: jobDetails.company_name 
                }
              })}
            >
              {t('jobBoard.details.applyNow')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobBoardDetails;
