import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./SubscriptionAddonsModal.scss";

const SubscriptionAddonsModal = ({
  selectedAddons,
  allAddons,
  onClose,
  onUpdateAddons,
}) => {
  const { t } = useTranslation();
  const [currentAddons, setCurrentAddons] = useState(selectedAddons);

  // Synchroniser `currentAddons` avec `selectedAddons` à chaque ouverture
  useEffect(() => {
    setCurrentAddons(selectedAddons);
  }, [selectedAddons]);

  const handleCheckboxChange = (addOn) => {
    // Met à jour `currentAddons` localement
    if (currentAddons.includes(addOn)) {
      setCurrentAddons(currentAddons.filter((item) => item !== addOn)); // Supprime l'add-on
    } else {
      setCurrentAddons([...currentAddons, addOn]); // Ajoute l'add-on
    }
  };

  const handleSave = () => {
    onUpdateAddons(currentAddons); // Applique les modifications au parent
    onClose(); // Ferme la modal
  };

  const handleClose = () => {
    onClose(); // Ferme la modal sans appliquer les modifications
  };

  return (
    <div className={`addons-modal-overlay visible`} onClick={handleClose}>
      <div
        className="addons-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <h2>{t("Talexa.addons.modalTitle")}</h2>
        <div className="addons-modal-actions">
          <form>
            {allAddons.map((addOn) => (
              <div
                key={addOn}
                className="addons-modal-item"
                onClick={(e) => {
                  if (
                    e.target.tagName !== "INPUT" &&
                    e.target.tagName !== "LABEL"
                  ) {
                    handleCheckboxChange(addOn);
                  }
                }}
              >
                <input
                  type="checkbox"
                  id={addOn}
                  name={addOn}
                  value={addOn}
                  checked={currentAddons.includes(addOn)} // Synchronisé avec `currentAddons`
                  onChange={() => handleCheckboxChange(addOn)} // Gère le changement via l'input
                />
                <label htmlFor={addOn}>
                  {t(`Talexa.addons.${addOn}.title`)}
                </label>
                <div className="addons-modal-price_container">
                  <p className="price">€{t(`Talexa.addons.${addOn}.price`)}</p>
                  <p>/month</p>
                  {t(`Talexa.addons.${addOn}.credit`) && (
                    <p className="credit">
                      {t(`Talexa.addons.${addOn}.credit`)} credits
                    </p>
                  )}
                </div>
              </div>
            ))}
          </form>
        </div>
        <div className="addons-modal-footer">
          <button onClick={handleSave}>{t("Talexa.addons.saveButton")}</button>
          <button onClick={handleClose}>
            {t("Talexa.addons.closeButton")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionAddonsModal;
