import { API_URL } from "../shared";


export const login = async (email, password) => {
  try {
    const response = await fetch(`${API_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
      credentials: 'include', // important for sending/receiving cookies
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Login failed');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const logout = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('tenantId');
};

export const checkAuthStatus = async () => {
  try {
    const response = await fetch(`${API_URL}/auth/status`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Authentication check failed');
    }

    const data = await response.json();
    console.log("Data", data);
    return {
      isAuthenticated: true,
      userType: data.user.role,
      userAddOns: data.user.userAddOns || {},
      subscription: data.user.subscription
    };
  } catch (error) {
    console.error('Auth check error:', error);
    return { isAuthenticated: false };
  }
};

export const setAuthHeader = (token) => {
  if (token) {
    sessionStorage.setItem('token', token);
  } else {
    sessionStorage.removeItem('token');
  }
};