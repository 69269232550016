import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./EditJobOpening.scss";
import { API_URL } from "../../../shared.js";

const EditJobOpening = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [jobOffer, setJobOffer] = useState({
    role: "",
    company_name: "",
    company_description: "",
    cost_center: "",
    contract_type: "",
    employment_type: "",
    mission: "",
    responsibilities: "",
    requirements: "",
    min_year_experience: "",
    role_level: "",
    skills: [],
    start_hour: "",
    end_hour: "",
    city: "",
    is_remote: false,
    is_hybrid: false,
    onsite: false,
    offer_content: "",
    perks: "",
    min_salary: "",
    max_salary: "",
    hiring_process: "",
    tone: "",
  });

  useEffect(() => {
    const fetchJobOffer = async () => {
      try {
        const response = await fetch(`${API_URL}/job-offers/${id}`, 
          {
            credentials: "include",
          }
        );
        if (response.ok) {
          const data = await response.json();
          console.log("Fetched job offer:", data);
          setJobOffer(data);
        } else {
          setError("Failed to fetch job offer");
        }
      } catch (error) {
        console.error("Error fetching job offer:", error);
        setError("Error fetching job offer");
      }
    };

    fetchJobOffer();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log("handleChange called for:", name, "with value:", value);

    if (type === "checkbox") {
      setJobOffer((prev) => ({
        ...prev,
        [name]: checked,
      }));
    } else if (name === "skills") {
      const skillsArray = value
        .split(",")
        .map((skill) => skill.trim())
        .filter((skill) => skill !== "");
      setJobOffer((prev) => ({
        ...prev,
        skills: skillsArray,
      }));
    } else if (
      name === "min_year_experience" ||
      name === "min_salary" ||
      name === "max_salary"
    ) {
      setJobOffer((prev) => ({
        ...prev,
        [name]: value === "" ? "" : Number(value),
      }));
    } else if (
      name === "employment_type" ||
      name === "contract_type" ||
      name === "role_level"
    ) {
      console.log("Updating select field:", name, "to value:", value);
      setJobOffer((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setJobOffer((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    console.log("Updated jobOffer state:", jobOffer);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    try {
      console.log("1. Données envoyées au serveur:", jobOffer);

      const response = await fetch(`${API_URL}/job-offers/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          ...jobOffer,
          skills: Array.isArray(jobOffer.skills)
            ? jobOffer.skills
            : jobOffer.skills.split(",").map((skill) => skill.trim()),
        }),
      });

      const data = await response.json();
      console.log("2. Réponse du serveur:", data);

      if (response.ok) {
        setSuccessMessage("Job offer updated successfully");
        // Attendre un peu pour montrer le message de succès
        setTimeout(() => {
          navigate("/b2b/dashboard/job-openings");
        }, 1500);
      } else {
        setError(data.message || "Error updating job offer");
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setError("Network error occurred");
    }
  };

  const employmentTypeOptions = [
    {
      value: "",
      label: t("editJobOpening.fields.employmentType.selectOption"),
    },
    {
      value: "Permanent",
      label: t("editJobOpening.fields.employmentType.permanent"),
    },
    {
      value: "Fixed-term",
      label: t("editJobOpening.fields.employmentType.fixedTerm"),
    },
    {
      value: "Temporary",
      label: t("editJobOpening.fields.employmentType.temporary"),
    },
    {
      value: "Internship",
      label: t("editJobOpening.fields.employmentType.internship"),
    },
    {
      value: "Freelance",
      label: t("editJobOpening.fields.employmentType.freelance"),
    },
  ];

  const contractTypeOptions = [
    { value: "", label: t("editJobOpening.fields.contractType.selectOption") },
    {
      value: "full-time",
      label: t("editJobOpening.fields.contractType.fullTime"),
    },
    {
      value: "part-time",
      label: t("editJobOpening.fields.contractType.partTime"),
    },
    {
      value: "contract",
      label: t("editJobOpening.fields.contractType.contract"),
    },
    {
      value: "internship",
      label: t("editJobOpening.fields.contractType.internship"),
    },
  ];

  return (
    <div className="edit-job-opening">
      <div className="edit-job-opening-header">
        <h2>{t("editJobOpening.title")}</h2>
      </div>

      {error && <div className="error-message">{error}</div>}
      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="role">{t("editJobOpening.fields.role")}</label>
          <input
            type="text"
            id="role"
            name="role"
            value={jobOffer.role}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="company_name">
            {t("editJobOpening.fields.companyName")}
          </label>
          <input
            type="text"
            id="company_name"
            name="company_name"
            value={jobOffer.company_name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="company_description">
            {t("editJobOpening.fields.companyDescription")}
          </label>
          <textarea
            id="company_description"
            name="company_description"
            value={jobOffer.company_description}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="cost_center">
            {t("editJobOpening.fields.costCenter")}
          </label>
          <input
            type="text"
            id="cost_center"
            name="cost_center"
            value={jobOffer.cost_center}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="contract_type">
            {t("editJobOpening.fields.contractType.label")}
          </label>
          <select
            id="contract_type"
            name="contract_type"
            value={jobOffer.contract_type || ""}
            onChange={handleChange}
            required
          >
            {contractTypeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="employment_type">
            {t("editJobOpening.fields.employmentType.label")}
          </label>
          <select
            id="employment_type"
            name="employment_type"
            value={jobOffer.employment_type || ""}
            onChange={handleChange}
            required
          >
            {employmentTypeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="mission">{t("editJobOpening.fields.mission")}</label>
          <textarea
            id="mission"
            name="mission"
            value={jobOffer.mission}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="responsibilities">
            {t("editJobOpening.fields.responsibilities")}
          </label>
          <textarea
            id="responsibilities"
            name="responsibilities"
            value={jobOffer.responsibilities}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="requirements">
            {t("editJobOpening.fields.requirements")}
          </label>
          <textarea
            id="requirements"
            name="requirements"
            value={jobOffer.requirements}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="min_year_experience">
            {t("editJobOpening.fields.minYearExperience")}
          </label>
          <input
            type="number"
            id="min_year_experience"
            name="min_year_experience"
            value={jobOffer.min_year_experience}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="role_level">
            {t("editJobOpening.fields.roleLevel")}
          </label>
          <select
            id="role_level"
            name="role_level"
            value={jobOffer.role_level}
            onChange={handleChange}
            required
          >
            <option value="">Select Role Level</option>
            <option value="Junior">Junior</option>
            <option value="Intermediate">Intermediate</option>
            <option value="Senior">Senior</option>
            <option value="Lead">Lead</option>
            <option value="Manager">Manager</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="skills">{t("editJobOpening.fields.skills")}</label>
          <input
            type="text"
            id="skills"
            name="skills"
            value={
              Array.isArray(jobOffer.skills)
                ? jobOffer.skills.join(", ")
                : jobOffer.skills
            }
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="city">{t("editJobOpening.fields.city")}</label>
          <input
            type="text"
            id="city"
            name="city"
            value={jobOffer.city}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group checkbox-group">
          <label>
            <input
              type="checkbox"
              name="is_remote"
              checked={jobOffer.is_remote}
              onChange={handleChange}
            />
            {t("editJobOpening.fields.workType.remote")}
          </label>
          <label>
            <input
              type="checkbox"
              name="is_hybrid"
              checked={jobOffer.is_hybrid}
              onChange={handleChange}
            />
            {t("editJobOpening.fields.workType.hybrid")}
          </label>
          <label>
            <input
              type="checkbox"
              name="onsite"
              checked={jobOffer.onsite}
              onChange={handleChange}
            />
            {t("editJobOpening.fields.workType.onsite")}
          </label>
        </div>
        <div className="hour-container">
          <div className="form-group">
            <label htmlFor="start_hour">
              {t("editJobOpening.fields.workHours.start")}
            </label>
            <input
              type="time"
              id="start_hour"
              name="start_hour"
              value={jobOffer.start_hour}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="end_hour">
              {t("editJobOpening.fields.workHours.end")}
            </label>
            <input
              type="time"
              id="end_hour"
              name="end_hour"
              value={jobOffer.end_hour}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="offer_content">
            {t("editJobOpening.fields.offerContent")}
          </label>
          <textarea
            id="offer_content"
            name="offer_content"
            value={jobOffer.offer_content}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="perks">{t("editJobOpening.fields.perks")}</label>
          <textarea
            id="perks"
            name="perks"
            value={jobOffer.perks}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="min_salary">
            {t("editJobOpening.fields.salary.min")}
          </label>
          <input
            type="number"
            id="min_salary"
            name="min_salary"
            value={jobOffer.min_salary}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="max_salary">
            {t("editJobOpening.fields.salary.max")}
          </label>
          <input
            type="number"
            id="max_salary"
            name="max_salary"
            value={jobOffer.max_salary}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="hiring_process">
            {t("editJobOpening.fields.hiringProcess")}
          </label>
          <textarea
            id="hiring_process"
            name="hiring_process"
            value={jobOffer.hiring_process}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="tone">{t("editJobOpening.fields.tone")}</label>
          <input
            type="text"
            id="tone"
            name="tone"
            value={jobOffer.tone}
            onChange={handleChange}
          />
        </div>

        <div className="form-actions">
          <button
            type="button"
            className="cancel-button"
            onClick={() => navigate("/b2b/dashboard/job-openings")}
          >
            {t("common.cancel")}
          </button>
          <button type="submit" className="submit-button">
            {t("editJobOpening.submit")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditJobOpening;
