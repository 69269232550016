import React, { useMemo, useState } from "react";
import "./TrackingBoardItemCard.scss";
import { MdDelete, MdAddCircle } from "react-icons/md";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import TrackingBoardTaskCard from "./TrackingBoardTaskCard";
import axios from "axios";
import { API_URL } from "../../../shared";
import { useTranslation } from "react-i18next";

const TrackingBoardItemCard = ({
  listItem,
  tasks = [],
  deleteTask,
  deleteBoardCard,
  getCurrentBoard,
  createNewTask,
  handEditTask,
}) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: listItem.id,
    data: { type: "board", listItem },
    disabled: edit, // Disable drag when editing title
  });

  const style = {
    transform: transform ? CSS.Transform.toString(transform) : undefined,
    transition: "transform 0.3s ease, opacity 0.1s ease",
  };
  const updateTitle = async (id, value) => {
    try {
      const response = await axios.put(
        `${API_URL}/v1/kanban/lists/${id}`,
        {
          name: value,
        },
        {
          headers: {
            "Content-Type": "application/json", 
          },
          withCredentials: true, 
        }
      );
      console.log(response);
      if (response) {
        getCurrentBoard();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    createNewTask(listItem.id, title, description);

    setTitle("");
    setDescription("");
    setIsModalOpen(false);
  };

  const refreshBoard = () => {
    getCurrentBoard();
  };
  const tasksID = useMemo(() => tasks.map((item) => item?.id), [tasks]);
  if (isDragging) {
    return (
      <>
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal-content">
              <button
                type="button"
                className="modal-close"
                onClick={() => setIsModalOpen(false)}
              >
                {t('trackingBoardItemCard.modal.close')}
              </button>
              <div className="create-board-form">
                <form onSubmit={handleSubmit}>
                  <label htmlFor="boardName">{t('trackingBoardItemCard.modal.form.taskTitle.label')}</label>
                  <input
                    type="text"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder={t('trackingBoardItemCard.modal.form.taskTitle.placeholder')}
                    required
                  />
                  <label htmlFor="boardName">{t('trackingBoardItemCard.modal.form.taskDescription.label')}</label>
                  <textarea
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder={t('trackingBoardItemCard.modal.form.taskDescription.placeholder')}
                    rows="4"
                    required
                  />
                  <button type="submit" className="create-task-btn">
                    {t('trackingBoardItemCard.modal.form.submit')}
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
        <div className="dragged" ref={setNodeRef} style={style}>
          <div className="card_main">
            <div className="card_header">
              {!edit ? (
                <div onClick={() => setEdit(true)}>{listItem.name}</div>
              ) : (
                <input
                  value={listItem.name}
                  onChange={(e) => updateTitle(listItem.id, e.target.value)}
                  className="Edit_input_title"
                  autoFocus
                  onBlur={() => setEdit(false)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") setEdit(false);
                  }}
                />
              )}
              <button
                className="card_Btn"
                onClick={() => {
                  if (window.confirm(t('trackingBoardItemCard.confirmDeleteBoard'))) {
                    deleteBoardCard(listItem?.id);
                  }
                }}
              >
                <MdDelete className="delete_card_icon" />
              </button>
            </div>

            {/* Card Content */}
            <div className="card_content">
              <SortableContext items={tasksID}>
                {tasks.map((task) => (
                  <TrackingBoardTaskCard
                    refreshBoard={refreshBoard}
                    key={task.id}
                    task={task}
                    deleteTask={deleteTask}
                    handEditTask={handEditTask}
                  />
                ))}
              </SortableContext>
            </div>

            {/* Add Task Button */}
            <button className="add_task" onClick={() => setIsModalOpen(true)}>
              <p>{t('trackingBoardItemCard.addTask')}</p>
              <MdAddCircle />
            </button>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button
              type="button"
              className="modal-close"
              onClick={() => setIsModalOpen(false)}
            >
              {t('trackingBoardItemCard.modal.close')}
            </button>
            <div className="create-board-form">
              <form onSubmit={handleSubmit}>
                <label htmlFor="boardName">{t('trackingBoardItemCard.modal.form.taskTitle.label')}</label>
                <input
                  type="text"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder={t('trackingBoardItemCard.modal.form.taskTitle.placeholder')}
                  required
                />
                <label htmlFor="boardName">{t('trackingBoardItemCard.modal.form.taskDescription.label')}</label>
                <textarea
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder={t('trackingBoardItemCard.modal.form.taskDescription.placeholder')}
                  rows="4"
                  required
                />
                <button type="submit" className="create-task-btn">
                  {t('trackingBoardItemCard.modal.form.submit')}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
      <div
        className="card_main"
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
      >
        {/* Card Header */}
        <div className="card_header">
          {!edit ? (
            <div onClick={() => setEdit(true)}>{listItem.name}</div>
          ) : (
            <input
              value={listItem.name}
              onChange={(e) => updateTitle(listItem.id, e.target.value)}
              className="Edit_input_title"
              autoFocus
              onBlur={() => setEdit(false)}
              onKeyDown={(e) => {
                if (e.key === "Enter") setEdit(false);
              }}
            />
          )}
          <button
            className="card_Btn"
            onClick={() => {
              if (window.confirm(t('trackingBoardItemCard.confirmDeleteBoard'))) {
                deleteBoardCard(listItem?.id);
              }
            }}
          >
            <MdDelete className="delete_card_icon" />
          </button>
        </div>

        {/* Card Content */}
        <div className="card_content">
          <SortableContext items={tasksID}>
            {tasks.map((task) => (
              <TrackingBoardTaskCard
                key={task.id}
                task={task}
                refreshBoard={refreshBoard}
                deleteTask={deleteTask}
                handEditTask={handEditTask}
              />
            ))}
          </SortableContext>
        </div>

        {/* Add Task Button */}
        <button className="add_task" onClick={() => setIsModalOpen(true)}>
          <p>{t('trackingBoardItemCard.addTask')}</p>
          <MdAddCircle />
        </button>
      </div>
    </>
  );
};

export default TrackingBoardItemCard;
