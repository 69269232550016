import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../shared";
import { Link } from "react-router-dom";
import "./PaymentBlocked.scss";


const PaymentBlocked = () => {
  const {t} = useTranslation();

  const redirectToStripePortal = async () => {
    try {
      const response = await fetch(`${API_URL}/portal-subscription`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to create Stripe portal session");
      }

      const { url } = await response.json();
      window.location.href = url; // Redirige l'utilisateur vers le portail Stripe
    } catch (error) {
      console.error("Error redirecting to Stripe portal:", error);
    }
  };

  return (
    <div className="payment-blocked-container">
      <div className="payment-blocked-container-content">
        <h1 className="payment-blocked-container-title">{ t("Talexa.subscriptionDashBoardPage.paymentBlocked.title") }</h1>
        <p className="payment-blocked-container-text">{ t("Talexa.subscriptionDashBoardPage.paymentBlocked.text") }</p>
        <button className="payment-blocked-container-button" onClick={redirectToStripePortal}>{ t("Talexa.subscriptionDashBoardPage.paymentBlocked.button") }</button>
      </div>
    </div>
  );
}

export default PaymentBlocked;