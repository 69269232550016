import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import "./TrackingBoardListing.scss";
import { API_URL } from "../../../shared";
import axios from "axios";
import { useTranslation } from "react-i18next";

const TrackingBoardListing = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [boardName, setBoardName] = useState("");
  const [boards, setBoards] = useState([]);
  const isDashboard = location.pathname === "/b2b/dashboard";
  const maxBoard = 5;
  const limitBoards = isDashboard ? boards.slice(0, maxBoard) : boards;
  const hasMoreBoards = boards.length > maxBoard;
  const navigate = useNavigate();

  const createBoard = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${API_URL}/v1/kanban/boards`,
        { name: boardName },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      alert(t('trackingBoardListing.actions.delete.success', { name: response.data.board.name }));

      setBoardName("");
      setIsModalOpen(false);
      getAllboards();
    } catch (error) {
      console.error("Error:", error.message);
      alert(t('trackingBoardListing.actions.delete.error', { message: error.response?.data?.error || error.message }));
    }
  };

  const deleteBoardCard = async (id) => {
    try {
      const response = await axios.delete(`${API_URL}/v1/kanban/boards/${id}`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      getAllboards();
      console.log(response);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleCreateBoard = () => {
    setIsModalOpen(true);
  };

  const getAllboards = async () => {
    try {
      const response = await axios.get(`${API_URL}/v1/kanban/boards`, {
        withCredentials: true,
      });
      setBoards(response.data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllboards();
  }, []);
  return (
    <>
      <div className="tracking-board">
        <h2 className="tracking-board-title">{t('trackingBoardListing.title')}</h2>
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal-content">
              {!isDashboard && (
                <button
                  type="button"
                  className="modal-close"
                  onClick={() => setIsModalOpen(false)}
                >
                  {t('trackingBoardListing.modal.close')}
                </button>
              )}
              <div className="create-board-form">
                <form onSubmit={createBoard}>
                  <label htmlFor="boardName">{t('trackingBoardListing.modal.form.label')}</label>
                  <input
                    type="text"
                    id="boardName"
                    value={boardName}
                    onChange={(e) => setBoardName(e.target.value)}
                    placeholder={t('trackingBoardListing.modal.form.placeholder')}
                    required
                  />
                  <button type="submit">{t('trackingBoardListing.modal.form.submit')}</button>
                </form>
              </div>
            </div>
          </div>
        )}
        <div className="create_board_btn">
          {!isDashboard && (
            <div className="add_column_btn" onClick={() => handleCreateBoard()}>
              {t('trackingBoardListing.actions.createBoard')}
            </div>
          )}
        </div>
        {limitBoards.map((board) => (
          <div key={board.id}>
            <div className="boards-request-list">
              <p>{board.name}</p>
              <span style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
                <button
                  type="button"
                  className="card_Btn"
                  onClick={() => {
                    if (window.confirm(t('trackingBoardListing.actions.delete.confirm'))) {
                      deleteBoardCard(board?.id);
                    }
                  }}
                >
                  <MdDelete className="delete_card_icon" />
                </button>
                <Link to={`/b2b/dashboard/tracking-boards/${board.id}`}>
                  {t('trackingBoardListing.actions.viewDetails')}
                </Link>
              </span>
            </div>
          </div>
        ))}
        {isDashboard && hasMoreBoards && (
          <div className="see-more-boards">
            <button
              type="button"
              className="see-more-boards-button"
              onClick={() => navigate("/b2b/dashboard/tracking-boards")}
            >
              {t('trackingBoardListing.actions.seeMore')}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default TrackingBoardListing;
