import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./SuccessStripeModal.scss";

const SuccessModal = ({ onClose, onRedirect }) => {
  const { t } = useTranslation();

  return (
    <div className="success-modal">
      <div className="success-modal__content">
        <h2>{t('Talexa.paymentSuccess.titleSuccessModal')}</h2>
        <div className="success-modal__content-text-container">
          <p>{t('Talexa.paymentSuccess.resumeSuccessModal')}</p>
          <p className="success-modal__content-text-container_message">
          {t('Talexa.paymentSuccess.notifSuccessModal')}
          </p>
        </div>
        <button className="success-modal__button" onClick={onRedirect}>
        {t('Talexa.paymentSuccess.buttonSuccessModal')}
        </button>
      </div>
    </div>
  );
};

SuccessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onRedirect: PropTypes.func.isRequired,
};

export default SuccessModal;
