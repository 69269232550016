
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../admin.scss";
import { FaEdit } from "react-icons/fa";
import { MdAddCircle, MdCancel, MdDelete, MdUpload } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../shared";

const AdminDashboardUserListing = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    job_role: "",
    email: "",
    phone: "",
    city: "",
  });
  const [isEditing, setIsEditing] = useState(false);
   const navigate = useNavigate();
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setNewUser({
      first_name: "",
      last_name: "",
      job_role: "",
      email: "",
      phone: "",
      city: "",
    });
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleSaveUser = () => {
    if (isEditing) {
      const updatedUsers = users.map((user) =>
        user.email === newUser.email ? newUser : user
      );
      setUsers(updatedUsers);
      localStorage.setItem("users", JSON.stringify(updatedUsers));
    } else {
      const updatedUsers = [...users, newUser];
      setUsers(updatedUsers);
      localStorage.setItem("users", JSON.stringify(updatedUsers));
    }
    handleModalClose();
  };

  const handleDelete = (email) => {
    const updatedUsers = users.filter((user) => user.email !== email);
    setUsers(updatedUsers);
    localStorage.setItem("users", JSON.stringify(updatedUsers));
  };

  const handleEdit = (user) => {
    setNewUser(user);
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const navigateToUpload = () => {
    navigate("/admin/dashboard/users/upload");
  }

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${API_URL}/user`, {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
        localStorage.setItem("users", JSON.stringify(data));
      } else {
        throw new Error("Failed to fetch users");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const storedUsers = localStorage.getItem("users");
    if (storedUsers) {
      setUsers(JSON.parse(storedUsers));
    } else {
      fetchUsers();
    }
  }, []);

  return (
    <>
      {/* Add/Edit User Modal */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="modal-close p-3 m-5" onClick={handleModalClose}>
              <MdCancel />
            </button>
            <div className="modal-form">
              <form onSubmit={handleSaveUser}>
                <div className="form-group">
                  <label htmlFor="first_name">First Name</label>
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    value={newUser.first_name}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="last_name">Last Name</label>
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    value={newUser.last_name}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="company_name">Company Name</label>
                  <input
                    type="text"
                    id="company_name"
                    name="company_name"
                    value={newUser.company_name}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="job_role">Select Role</label>
                  <select
                    id="job_role"
                    name="job_role"
                    value={newUser.job_role}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select user role</option>
                    <option value="Admin">Admin</option>
                    <option value="Candidate">Candidate</option>
                    <option value="Company">Company</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={newUser.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    value={newUser.phone}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={newUser.city}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <button type="submit">
                  {isEditing ? "Update User" : "Add User"}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Users List */}
      <div className="list">
        <div className="top-header">
          <h2>Users List</h2>
          <div style={{ display: "flex", gap: "2" }}>
            <button onClick={navigateToUpload}>
              Bulk Upload <MdUpload />
            </button>
            <button onClick={handleModalOpen} style={{ marginLeft: "5px"}}>
              Add User <MdAddCircle />
            </button>
          </div>
        </div>
        <table style={{ marginTop: "10px" }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Full Name</th>
              <th>Company Name</th>
              <th>Job Role</th>
              <th>Email</th>
              <th>Phone</th>
              <th>City</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user.email}>
                <td>{index + 1}</td>
                <td>
                  {user.first_name} {user.last_name}
                </td>
                <td>{user.company_name}</td>
                <td>{user.job_role}</td>
                <td>{user.email}</td>
                <td>{user.phone}</td>
                <td>{user.city}</td>
                <td>
                  {/* Hide actions for users with role "Admin" */}
                  {user.job_role !== "Admin" && user.role !== "admin" && (
                    <div>
                      <button
                        className="trash-icon"
                        onClick={() => {
                          if (
                            window.confirm(
                              t("Are you sure you want to delete this user?")
                            )
                          ) {
                            handleDelete(user.email);
                          }
                        }}
                      >
                        <MdDelete className="trash-icon" />
                      </button>

                      <button
                        onClick={() => handleEdit(user)}
                        className="edit-icon"
                      >
                        <FaEdit className="edit-icon" />
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AdminDashboardUserListing;
