
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import { FaEdit, FaSave, FaUserPlus } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { API_URL } from '../../../../../shared.js';
import './SendAssessmentModal.scss';

const fetchCandidateList = async () => {

  const candidateFromPool = await fetch(`${API_URL}/candidates`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  });

  const candidatesResult = await candidateFromPool.json();
  console.log('candidatesResult:', candidatesResult);

  const candidateWithApplication = await fetch(`${API_URL}/candidates-with-application`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  const candidatesWithApplicationResult = await candidateWithApplication.json();
  console.log('candidatesWithApplicationResult:', candidatesWithApplicationResult);

  const candidatesWithApplicationArray = candidatesWithApplicationResult.candidates;

  const uniqueCandidates = [
    ...new Map(
      [...candidatesResult, ...candidatesWithApplicationArray].map(candidate => [candidate.id, candidate])
    ).values()];
  return uniqueCandidates;
}


const SendAssessmentModal = ( { onClose } ) => {

  const { t } = useTranslation();
  const { id: assessmentId } = useParams();
  const [candidates, setCandidates] = useState([]);


  const handleOutsideClick = (event) => {
    if (event.target.classList.contains("modal-overlay")) {
      onClose();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const mergedCandidates = await fetchCandidateList();
      setCandidates(mergedCandidates);
    }
    fetchData();
  }, []);

  const handleInviteSubmit = async ( candidate) => {

    try {
      const response = await fetch(
        `${API_URL}/assessment-invitation-candidate`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            candidateId: candidate.id,
            email: candidate.email,
            assessmentId: assessmentId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      if (result.success) {

        alert("Invitation sent successfully.");
      } else {
        console.error("Error sending invitation:", result.message);
      }
    } catch (error) {
      console.error("Error sending invitation:", error);
    }
  };

  return (
      <div className="send-assessment-modal">
        <button
          type="button"
          className="modal-close p-3 m-5"
          onClick={onClose}
        >
          ×
        </button>
        <h2 className="send-assessment-modal__title">Send Assessment to Candidate</h2>
        <div className="send-assessment-modal__content">
          <div className="send-assessment-modal__content-candidate-list">
            <h3 className="send-assessment-modal__content-candidate-subtitle">Candidates</h3>
            <div className="send-assessment-modal__content-candidate-container">
              {candidates.map(candidate => (
                <div
                  key={candidate.id}
                  className="send-assessment-modal__content-candidate-container-item"
                >
                  <p className="send-assessment-modal__content-candidate-container-item-name">
                    {candidate.first_name} {candidate.last_name}
                  </p>
                  <button onClick={() => handleInviteSubmit(candidate)} className="send-assessment-modal__content-candidate-container-item-button">
                    Send Assessment
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    ) 
}

export default SendAssessmentModal;