import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./AllEmailsTemplates.scss";
import { FiPlus } from "react-icons/fi";
import { API_URL } from "../../../shared";

const AllEmailsTemplates = () => {
  const { t } = useTranslation();
  const [templates, setTemplates] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await fetch(`${API_URL}/email-templates`, {
          credentials: 'include',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();
        setTemplates(data);
      } catch (error) {
        console.error(t('emailTemplates.errors.fetchError'), error);
      }
    };

    const fetchCandidates = async () => {
      try {
        const response = await fetch(`${API_URL}/candidates`, {
          credentials: 'include',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();
        setCandidates(data);
      } catch (error) {
        console.error(t('emailTemplates.errors.fetchError'), error);
      }
    };

    fetchTemplates();
    fetchCandidates();
  }, [t]);

  const findCandidate = (candidateId) => {
    return candidates.find((c) => c.id === candidateId) || { 
      first_name: t('emailTemplates.noData'), 
      last_name: t('emailTemplates.noData'), 
      email: t('emailTemplates.noData') 
    };
  };

  const uniqueCandidates = Array.isArray(templates) 
    ? templates.reduce((acc, template) => {
        if (!acc.some(candidate => candidate.id === template.candidate_id)) {
          const candidate = findCandidate(template.candidate_id);
          if (candidate && candidate.id) {
            acc.push(candidate);
          }
        }
        return acc;
      }, [])
    : [];

  const filteredCandidates = uniqueCandidates.filter(
    (candidate) =>
      candidate.first_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      candidate.last_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      candidate.email?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleActionChange = (action, candidateId) => {
    if (!candidateId || candidateId === "undefined") {
      alert(t('emailTemplates.errors.invalidCandidate'));
      return;
    }

    switch (action) {
      case "view":
        navigate(`/b2b/dashboard/candidate-emails/${candidateId}`);
        break;
      case "delete":
        handleDelete(candidateId);
        break;
      default:
        console.warn("Unknown action selected:", action);
    }
  };

  const handleDelete = async (candidateId) => {
    const isConfirmed = window.confirm(
      t('emailTemplates.confirmDelete.message')
    );
  
    if (!isConfirmed) {
      return;  
    }
  
    try {
      await fetch(`${API_URL}/email-templates?candidate_id=${candidateId}`, {
        credentials: 'include',
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
  
      setTemplates((prevTemplates) =>
        prevTemplates.filter((template) => template.candidate_id !== candidateId)
      );
    } catch (error) {
      console.error("Delete error:", error);
      alert(t('emailTemplates.confirmDelete.error'));
    }
  };

  return (
    <div className="all-emails-container">
      <div className="header">
        <h2>{t('emailTemplates.title')}</h2>
        <button 
          className="generate-button"
          onClick={() => navigate('/b2b/dashboard/email-templates/generate')}
        >
          <FiPlus /> {t('emailTemplates.generateButton')}
        </button>
      </div>

      <input
        type="text"
        className="search-bar"
        placeholder={t('emailTemplates.searchPlaceholder')}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      <table className="email-table">
        <thead>
          <tr>
            <th>{t('emailTemplates.table.columns.firstName')}</th>
            <th>{t('emailTemplates.table.columns.lastName')}</th>
            <th>{t('emailTemplates.table.columns.email')}</th>
            <th>{t('emailTemplates.table.columns.actions')}</th>
          </tr>
        </thead>
        <tbody>
          {filteredCandidates.map((candidate) => (
            <tr key={candidate.id}>
              <td>{candidate.first_name || t('emailTemplates.noData')}</td>
              <td>{candidate.last_name || t('emailTemplates.noData')}</td>
              <td>{candidate.email || t('emailTemplates.noData')}</td>
              <td>
                <select
                  className="action-select"
                  defaultValue=""
                  onChange={(e) => handleActionChange(e.target.value, candidate.id)}
                >
                  <option value="" disabled>
                    {t('emailTemplates.actions.chooseAction')}
                  </option>
                  <option value="view">{t('emailTemplates.actions.viewAll')}</option>
                  <option value="delete">{t('emailTemplates.actions.delete')}</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="return-button" onClick={() => navigate("/b2b/dashboard")}>
        <span>{t('emailTemplates.navigation.returnToDashboard')}</span>
      </div>
    </div>
  );
};

export default AllEmailsTemplates;
