import React, { useState, useEffect, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Loading from "./components/Loading";
import NotFoundPage from "./components/404Page";
import ForgotPasswordPage from "./components/ForgotPasswordPage";
import ResetPasswordPage from "./components/ResetPasswordPage";
import ProtectedRoute from "./ProtectedRoute";
import SubscriptionRoute from "./SubscriptionRoute";
import B2BDashboardLayout from "./components/B2B/B2BDashboard/B2BDashboardLayout";
import B2BDashboardRoutes from "./components/B2B/B2BDashboard/B2BDashboardRoutes";
import TalexaSubscription from "./components/B2B/TalexaSubscription.jsx";
import TalexaConfirmationSubscription from "./components/B2B/TalexaConfirmationSubscription.jsx";
import HiringRequestForm from "./components/B2B/B2BDashboard/HiringRequestForm.jsx";
import ApplicationThankYouPage from './components/ApplicationThankYouPage';
import { ThemeProvider } from './components/B2B/B2BDashboard/ThemeContext';
import AdminDashboardLayout from "./components/Admin/AdminDashboardLayout.jsx";
import AdminDashboardRoutes from "./components/Admin/AdminDashboardRoutes.jsx";
import AssessmentForm from "./components/B2B/B2BDashboard/Assessments/AssessmentForm.jsx";
import PaymentBlocked from "./components/B2B/PaymentBlocked.jsx";

// Composants chargés de manière différée
const LandingGeneral = lazy(() => import("./components/LandingGeneral"));
const B2BLayout = lazy(() => import("./components/B2B/B2BLayout"));
const B2CLayout = lazy(() => import("./components/B2C/B2CLayout"));
const LandingB2B = lazy(() => import("./components/B2B/LandingB2B"));
const ContactB2B = lazy(() => import("./components/B2B/ContactB2B"));
const TalexaB2B = lazy(() => import("./components/B2B/TalexaCompany"));
const TalexaB2C = lazy(() => import("./components/B2C/TalexaCandidate"));
const CompanyB2B = lazy(() => import("./components/B2B/CompanyB2B"));
const LandingB2C = lazy(() => import("./components/B2C/LandingB2C"));
const ContactB2C = lazy(() => import("./components/B2C/ContactB2C"));
const CompanyB2C = lazy(() => import("./components/B2C/CompanyB2C"));
const SoonAvailable = lazy(() => import("./components/SoonAvailable"));
const BlogDetail = lazy(() => import("./components/BlogDetail"));
const BlogList = lazy(() => import("./components/BlogList"));

// Composants chargés de manière différée
const JobBoard = lazy(() => import("./components/JobBoard"));
const JobBoardDetails = lazy(() => import("./components/JobBoardDetails"));
const JobApplication = lazy(() => import("./components/JobApplication"));
const SignUp = lazy(() => import("./components/SignUpPage"));
const Login = lazy(() => import("./components/LogInPage"));
const PrivacyPolicy = lazy(() =>
  import("./components/FooterPages/PrivacyPolicy")
);
const CookiePolicy = lazy(() =>
  import("./components/FooterPages/CookiePolicy")
);
const LegalNotice = lazy(() => import("./components/FooterPages/LegalNotice"));
const GeneralTerms = lazy(() =>
  import("./components/FooterPages/GeneralTerms")
);
const FAQ = lazy(() => import("./components/FooterPages/FAQ"));

import { API_URL } from "./shared.js";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userAddOns, setUserAddOns] = useState({});
  const [loading, setLoading] = useState(true);

  const [dashboardData, setDashboardData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const updateAuthStatus = (status) => {
    setIsAuthenticated(status);
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${API_URL}/dashboard-data`, {
          credentials: "include",
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(
            errorData.message || `HTTP error! status: ${response.status}`
          );
        }
        const data = await response.json();

        setDashboardData(data);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (isAuthenticated) {
      fetchDashboardData();
    }
  }, [isAuthenticated]);

  return (
    <Router>
      <Suspense fallback={<Loading />}>

          <Routes>
            {/* Public path */}
            <Route path="/" element={<LandingGeneral />} />
            {/* <Route path="/job-board" 
            element={
                <ThemeProvider>
                  <JobBoard />
                </ThemeProvider>
              }>
            </Route>
            <Route path="/job-board/:id" element={<JobBoardDetails />} />
            <Route path="/job-board/:id/apply" element={<JobApplication />} /> */}

            <Route
              path="/login"
              element={<Login setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route
              path="/forgot-password"
              element={
                <Suspense fallback={<Loading />}>
                  <ForgotPasswordPage />
                </Suspense>
              }
            />
            <Route
              path="/reset-password/:token"
              element={
                <Suspense fallback={<Loading />}>
                  <ResetPasswordPage />
                </Suspense>
              }
            />
            <Route path="/signup" element={<SignUp />} />
            <Route
              path="/hiring-invitation-new/:formId"
              element={<HiringRequestForm />}
            />
            <Route path="/assessment-invitation-new/:id" element={<AssessmentForm />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/legal-notice" element={<LegalNotice />} />
            <Route path="/general-terms" element={<GeneralTerms />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/soon-available" element={<SoonAvailable />} />
            <Route path="*" element={<NotFoundPage />} />

            {/* Path B2B */}
            <Route path="/b2b" element={<B2BLayout />}>
              <Route path="home" element={<LandingB2B />} />
              <Route path="contact" element={<ContactB2B />} />
              <Route path="talexa" element={<TalexaB2B />} />
              <Route path="company" element={<CompanyB2B />} />
              <Route path="job-board" element={<JobBoard />} />
              <Route path="blogPosts" element={<BlogList />} />
              <Route path="blogPosts/:id" element={<BlogDetail />} />
              <Route path="job-board" 
            element={
                <ThemeProvider>
                  <JobBoard />
                </ThemeProvider>
              }>
            </Route>
            <Route path="job-board/:id" element={<JobBoardDetails />} />
            <Route path="job-board/:id/apply" element={<JobApplication />} />
            <Route
              path="job-board/thank-you/:id"
              element={<ApplicationThankYouPage />}
            />

              <Route path="subscription" element={<TalexaSubscription />} />
              <Route
                path="subscription/confirmation"
                element={
                  <ProtectedRoute>
                    <TalexaConfirmationSubscription />
                  </ProtectedRoute>
                }
              />
              <Route 
                path="payment-blocked" 
                element={
                  <ProtectedRoute>
                    <SubscriptionRoute>
                      <PaymentBlocked />
                    </SubscriptionRoute>
                  </ProtectedRoute>
                } 
              />
            </Route>

            {/* Routes B2C - Add the path prefix */}
            <Route path="/b2c" element={<B2CLayout />}>
              <Route path="home" element={<LandingB2C />} />
              <Route path="contact" element={<ContactB2C />} />
              <Route path="talexa" element={<TalexaB2C />} />
              <Route path="company" element={<CompanyB2C />} />
              <Route path="job-board" element={<JobBoard />} />
              <Route path="blogPosts" element={<BlogList />} />
              <Route path="blogPosts/:id" element={<BlogDetail />} />
              <Route path="job-board" 
                 element={
                     <ThemeProvider>
                       <JobBoard />
                     </ThemeProvider>
                   }>
              </Route>
              <Route path="job-board/:id" element={<JobBoardDetails />} />
              <Route path="job-board/:id/apply" element={<JobApplication />} />
              <Route
                path="job-board/thank-you/:id"
                element={<ApplicationThankYouPage />}
              />
            </Route>

            {/* Protected path B2B */}
            <Route
              path="/b2b/dashboard/*"
              element={
                <ProtectedRoute>
                  <SubscriptionRoute redirectPath="/b2b/subscription">
                    <ThemeProvider>
                      {" "}
                      {}
                      <B2BDashboardLayout userAddOns={userAddOns}>
                        <B2BDashboardRoutes
                          dashboardData={dashboardData}
                          isLoading={isLoading}
                          error={error}
                        />
                      </B2BDashboardLayout>
                    </ThemeProvider>
                  </SubscriptionRoute>
                </ProtectedRoute>
              }
            />

            {/* Routes B2C */}
            <Route path="/b2c" element={<B2CLayout />}>
              <Route path="home" element={<LandingB2C />} />
              <Route path="contact" element={<ContactB2C />} />
              <Route path="talexa" element={<TalexaB2C />} />
              <Route path="company" element={<CompanyB2C />} />
              <Route path="job-board" element={<JobBoard />} />
            </Route>

            {/* Protected path B2B */}
             <Route
              path="/admin/dashboard/*"
              element={
                <ProtectedRoute>
                  <ThemeProvider> {}
                    <AdminDashboardLayout userAddOns={userAddOns}>
                      <AdminDashboardRoutes
                        dashboardData={dashboardData}
                        isLoading={isLoading}
                        error={error}
                      />
                    </AdminDashboardLayout>
                  </ThemeProvider>
                </ProtectedRoute>
              }
            />
          </Routes>
      </Suspense>
    </Router>
  );
};

export default App;