import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../admin.scss";
import { FaEdit } from "react-icons/fa";
import { MdAddCircle, MdCancel, MdDelete } from "react-icons/md";
import { API_URL } from "../../../shared";

const AdminDashboardCompaniesListing = () => {
  const { t } = useTranslation();
  const [companies, setCompanies] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCompany, setNewCompany] = useState({
    company_name: "",
    industry: "",
    website: "",
  });
  const [isEditing, setIsEditing] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setNewCompany({
      company_name: "",
      industry: "",
      website: "",
    });
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCompany({ ...newCompany, [name]: value });
  };

  const handleSavecompany = () => {
    if (isEditing) {
      const updatesCompanies = companies.map((company) =>
        company.email === newcompany.email ? newCompany : company
      );
      setcompanys(updatesCompanies);
      localStorage.setItem("companys", JSON.stringify(updatesCompanies));
    } else {
      const updatesCompanies = [...companies, newCompany];
      setcompanys(updatedCompanies);
      localStorage.setItem("companys", JSON.stringify(updatedCompanies));
    }
    handleModalClose();
  };

  const handleDelete = (email) => {
    const updatedCompanies = companies.filter((company) => companies.email !== email);
    setcompanys(updatedCompanies);
    localStorage.setItem("companys", JSON.stringify(updatedCompanies));
  };

  const handleEdit = (company) => {
    setNewcompany(company);
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const fetchCompanies = async () => {
    try {
      const response = await fetch(`${API_URL}/company`, {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setCompanies(data);
      } else {
        throw new Error("Failed to fetch Companies");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  return (
    <>
      <div className="list">
        <div className="top-header">
          <h2>Companies</h2>
          <button onClick={handleModalOpen}>
            Add Company <MdAddCircle />
          </button>
        </div>
        <table style={{ marginTop: "10px" }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Company Name</th>
              <th>Industry</th>
              <th>Website</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {companies.map((company, index) => (
              <tr key={company.email}>
                <td>{index + 1}</td>
                <td>{company.company_name}</td>
                <td>{company.industry}</td>
                <td>{company.website}</td>
                <td>
                    <div>
                      <button
                        className="trash-icon"
                        onClick={() => {
                          if (
                            window.confirm(
                              t("Are you sure you want to delete this company?")
                            )
                          ) {
                            handleDelete(company.email);
                          }
                        }}
                      >
                        <MdDelete className="trash-icon" />
                      </button>

                      <button
                        onClick={() => handleEdit(company)}
                        className="edit-icon"
                      >
                        <FaEdit className="edit-icon" />
                      </button>
                    </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AdminDashboardCompaniesListing;