import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./HiringRequestDetail.scss";
import { API_URL } from "../../../shared";

const HiringRequestDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [hiringRequest, setHiringRequest] = useState(null);

  useEffect(() => {
    fetchHiringRequestDetail();
  }, [id]);

  const fetchHiringRequestDetail = async () => {
    try {
      const response = await fetch(`${API_URL}/hiring-requests/${id}`, { credentials: "include" });
      if (response.ok) {
        const data = await response.json();
        setHiringRequest(data);
      } else {
        throw new Error("Failed to fetch hiring request details");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (!hiringRequest) return <div>{t('common.loading')}</div>;

  return (
    <div className="hiring-request-detail">
      <h2>{t('hiringRequestDetail.title')}</h2>
      <div className="detail-section">
        <h3>{t('hiringRequestDetail.sections.basicInfo')}</h3>
        <p>
          <strong>{t('hiringRequestDetail.fields.jobTitle')}:</strong> {hiringRequest.jobtitle}
        </p>
        <p>
          <strong>{t('hiringRequestDetail.fields.role')}:</strong> {hiringRequest.role}
        </p>
        <p>
          <strong>{t('hiringRequestDetail.fields.requester')}:</strong>{" "}
          {`${hiringRequest.first_name} ${hiringRequest.last_name}`}
        </p>
        <p>
          <strong>{t('hiringRequestDetail.fields.email')}:</strong> {hiringRequest.email}
        </p>
        <p>
          <strong>{t('hiringRequestDetail.fields.department')}:</strong> {hiringRequest.department}
        </p>
        <p>
          <strong>{t('hiringRequestDetail.fields.companyDomain')}:</strong> {hiringRequest.company_domain}
        </p>
        <p>
          <strong>{t('hiringRequestDetail.fields.submissionDate')}:</strong>{" "}
          {new Date(hiringRequest.createdAt).toLocaleDateString()}
        </p>
      </div>
      <div className="detail-section">
        <h3>{t('hiringRequestDetail.sections.jobDetails')}</h3>
        <p>
          <strong>{t('hiringRequestDetail.fields.hiringPurpose')}:</strong> {hiringRequest.hiring_purpose}
        </p>
        <p>
          <strong>{t('hiringRequestDetail.fields.contractType')}:</strong> {hiringRequest.contract_type}
        </p>
        <p>
          <strong>{t('hiringRequestDetail.fields.employmentType')}:</strong> {hiringRequest.employment_type}
        </p>
        <p>
          <strong>{t('hiringRequestDetail.fields.mission')}:</strong> {hiringRequest.mission}
        </p>
        <p>
          <strong>{t('hiringRequestDetail.fields.responsibilities')}:</strong> {hiringRequest.responsibilities}
        </p>
        <p>
          <strong>{t('hiringRequestDetail.fields.requirements')}:</strong> {hiringRequest.requirements}
        </p>
        <p>
          <strong>{t('hiringRequestDetail.fields.minYearExperience')}:</strong>{" "}
          {hiringRequest.min_year_experience}
        </p>
        <p>
          <strong>{t('hiringRequestDetail.fields.roleLevel')}:</strong> {hiringRequest.role_level}
        </p>
      </div>
      <div className="detail-section">
        <h3>{t('hiringRequestDetail.sections.candidateProfile')}</h3>
        <p>
          <strong>{t('hiringRequestDetail.fields.resumeImportant')}:</strong>{" "}
          {hiringRequest.resume_important ? t('common.yes') : t('common.no')}
        </p>
        <p>
          <strong>{t('hiringRequestDetail.fields.resultsObjectives')}:</strong>{" "}
          {hiringRequest.results_objectives}
        </p>
        <p>
          <strong>{t('hiringRequestDetail.fields.skills')}:</strong> {hiringRequest.skills.join(", ")}
        </p>
        <p>
          <strong>{t('hiringRequestDetail.fields.minSkills')}:</strong> {hiringRequest.min_skills.join(", ")}
        </p>
        <p>
          <strong>{t('hiringRequestDetail.fields.bonusSkills')}:</strong> {hiringRequest.bonus_skills.join(", ")}
        </p>
      </div>
      <div className="detail-section">
        <h3>{t('hiringRequestDetail.sections.workArrangement')}</h3>
        <p>
          <strong>{t('hiringRequestDetail.fields.isHybrid')}:</strong> {hiringRequest.is_hybrid ? t('common.yes') : t('common.no')}
        </p>
        <p>
          <strong>{t('hiringRequestDetail.fields.isRemote')}:</strong> {hiringRequest.is_remote ? t('common.yes') : t('common.no')}
        </p>
        <p>
          <strong>{t('hiringRequestDetail.fields.isOnsite')}:</strong> {hiringRequest.is_onsite ? t('common.yes') : t('common.no')}
        </p>
        <p>
          <strong>{t('hiringRequestDetail.fields.isHeadcountValidated')}:</strong>{" "}
          {hiringRequest.is_headcount_validated ? t('common.yes') : t('common.no')}
        </p>
      </div>

      <div className="button-container">
        <button 
          className="back-button"
          onClick={() => navigate('/b2b/dashboard/hiring-requests')}
        >
          {t('common.backToList')}
        </button>
      </div>
    </div>
  );
};

export default HiringRequestDetail;
