import React from "react";
import { useNavigate } from "react-router-dom";
import "./SubscriptionModal.scss";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const SubscriptionModal = ({ title, message, onClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBackgroundClick = (e) => {
    // Vérifie si l'utilisateur clique directement sur l'arrière-plan (et pas à l'intérieur du contenu)
    if (e.target.classList.contains("modal-overlay")) {
      onClose();
    }
  };

  useEffect(() => {
    console.log("SubscriptionModal mounted");
  }, []);
  

  return (
    <div className="modalSubscription-overlay" onClick={handleBackgroundClick}>
      <div className="modalSubscription-content">
        <h2 className="modalSubscription-title">{t('Talexa.SubscriptionModal.title')}</h2>
        <p>{t('Talexa.SubscriptionModal.message')}</p>
        <div className="modalSubscription-actions">
          <button
            onClick={() => {
              onClose();
              navigate("/login", { state: { redirectTo: "/b2b/subscription" } }); // Redirection vers login
            }}
            className="modalSubscription-button-login"
          >
            {t('Talexa.SubscriptionModal.loginButton')}
          </button>
          <button
            onClick={() => {
              onClose();
              navigate("/signup", { state: { redirectTo: "/b2b/subscription" } }); // Redirection vers register
            }}
            className="modalSubscription-button-register"
          >
            {t('Talexa.SubscriptionModal.registerButton')}
          </button>
        </div>
        <button onClick={onClose} className="modalSubscription-close-button">
          {t('Talexa.SubscriptionModal.closeButton')}
        </button>
      </div>
    </div>
  );
};

export default SubscriptionModal;
