import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext({
  theme: 'light',
  setTheme: () => {},
  customColors: {
    primaryColor: '#6920b0',
    secondaryColor: '#fa41cc',
  },
  updateCustomColors: () => {},
  logo: '',
  updateLogo: () => {},
  addOnWhiteLabel: true,
  clientConsole: false,
});

export const ThemeProvider = ({ children, isWhiteLabel, clientConsole }) => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [customColors, setCustomColors] = useState({
    primaryColor: localStorage.getItem('primaryColor') || '#6920b0',
    secondaryColor: localStorage.getItem('secondaryColor') || '#fa41cc',
  });
  const [logo, setLogo] = useState(localStorage.getItem('logo') || '');
  const [addOnWhiteLabel, setAddOnWhiteLabel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [clientConsoleEnabled, setClientConsoleEnabled] = useState(false);





    
    useEffect(() => {
      const fetchSettings = async () => {
        try {
          const response = await fetch('/api/get-settings', {
            method: 'GET',
            credentials: 'include',
          });
          if (response.ok) {
            const data = await response.json();
            setCustomColors({
              primaryColor: data.colors.primary || '#6920b0',
              secondaryColor: data.colors.secondary || '#fa41cc',
            });
            setLogo(data.logo || '');
            setAddOnWhiteLabel(data.addOnWhiteLabel || false); // Mise à jour correcte
            setClientConsoleEnabled(data.clientConsole || false);
          } else {
            console.error('Failed to fetch settings:', response.statusText);
          }
        } catch (err) {
          console.error('Error fetching settings:', err);
        } finally {
          setIsLoading(false); // Le chargement est terminé
        }
      };
    
      fetchSettings();
    }, [isWhiteLabel, clientConsole]);



  useEffect(() => {
    
    document.documentElement.style.setProperty('--primary-color', customColors.primaryColor);
    document.documentElement.style.setProperty('--secondary-color', customColors.secondaryColor);

     
    localStorage.setItem('primaryColor', customColors.primaryColor);
    localStorage.setItem('secondaryColor', customColors.secondaryColor);
    localStorage.setItem('logo', logo);
  }, [customColors, logo]);

  const updateCustomColors = (newColors) => {
    const updatedColors = { ...customColors, ...newColors };
    setCustomColors(updatedColors);

   
    if (newColors.primaryColor) {
      document.documentElement.style.setProperty('--primary-color', newColors.primaryColor);
    }
    if (newColors.secondaryColor) {
      document.documentElement.style.setProperty('--secondary-color', newColors.secondaryColor);
    }

     
    localStorage.setItem('primaryColor', updatedColors.primaryColor);
    localStorage.setItem('secondaryColor', updatedColors.secondaryColor);

     
    window.dispatchEvent(new CustomEvent('themeChange', { detail: updatedColors }));
  };



  const updateLogo = (newLogo) => {
    setLogo(newLogo);
    localStorage.setItem('logo', newLogo);
    console.log('Dispatching logoChange event:', newLogo);  
   
    window.dispatchEvent(new CustomEvent('logoChange', { detail: { logo: newLogo } }));
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
        customColors,
        updateCustomColors,
        logo,
        updateLogo: (newLogo) => {
          setLogo(newLogo);
          localStorage.setItem('logo', newLogo);
        },
        addOnWhiteLabel,  
        clientConsole: clientConsoleEnabled,
        isLoading, // Ajout ici
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};