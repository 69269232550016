import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../../../shared.js";
import { useNavigate } from "react-router-dom";
import Spinner from '../../../common/Spinner/Spinner';

import "./CreateAssessment.scss";

const CreateLanguageAssessment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedJob, setSelectedJob] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [jobOpenings, setJobOpenings] = useState([]);
  const [loading, setLoading] = useState(false);

  // use effect to fetch job openings
  useEffect(() => {
    const fetchJobOpenings = async () => {
      try {
        const response = await fetch(`${API_URL}/job-offers`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Job Openings:", data);
        setJobOpenings(data);
      } catch (err) {
        console.error("Error fetching job openings:", err);
      }
    };

    fetchJobOpenings(); // Appeler la fonction
  }, []);

  // // Mock data
  // const jobOpenings = [
  //   { id: 1, title: 'Senior Software Engineer' },
  //   { id: 2, title: 'Product Manager' },
  // ];

  const languages = ["English", "French", "German", "Spanish", "Italian"];

  const levels = ["A1", "A2", "B1", "B2", "C1", "C2"];

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Generate Language Assessment:", {
      job: selectedJob,
      language: selectedLanguage,
      level: selectedLevel,
    });

    const selectedJobOpening = jobOpenings.find(
      (job) => job.id === selectedJob
    );

    const type = "Language-Skills";

    if (selectedJobOpening) {
      console.log(
        "Generate Language Skills Assessment for job:",
        selectedJobOpening.role
      );
      console.log("Type:", type);

      const payload = {
        title: selectedJobOpening.role,
        type,
        language: selectedLanguage,
        level: selectedLevel,
      };

      console.log("Payload:", payload);
      console.log("Generate Soft Skills Assessment for job:", selectedJobOpening.role);

      const title = encodeURIComponent(selectedJobOpening.role);
      const language = encodeURIComponent(selectedLanguage);
      const level = encodeURIComponent(selectedLevel);

      setLoading(true);

      const fetchData = async () => {
        try {
          const response = await fetch(
            `${API_URL}/languageskillsassessmentgenerated/${title}/${type}/${level}/${language}`,
            {
              method: "GET",
              headers: { "Content-Type": "application/json" },
              credentials: "include",
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const result = await response.json();
          console.log("Result:", result);
          const assessmentId = result.analysisId;

          navigate(`/b2b/dashboard/assessments/analysis-language/${assessmentId}`, {
            state: { data: result },
          });
        } catch (err) {
          console.error("Error fetching data:", err);
          // setError(err.message);
        } finally {
          setLoading(false);
        }
      };
      fetchData();

    } else {
      console.error("No job opening selected");
    }
  };

  return (
    <div className="create-assessment">
      {loading && (
        <div>
          <Spinner 
          />
          <p>Le processus peut prendre jusqu'à 90 secondes</p>
        </div>
      )}
      <div className="create-assessment-header">
        <h2>{t("assessments.createLanguage")}</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="jobOpening">{t("assessments.selectJob")}</label>
          <select
            id="jobOpening"
            value={selectedJob}
            onChange={(e) => setSelectedJob(e.target.value)}
            required
          >
            <option value="" disabled>
              {t("assessments.selectJobOption")}
            </option>
            {jobOpenings.map((job) => (
              <option key={job.id} value={job.id}>
                {job.role}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="language">{t("assessments.selectLanguage")}</label>
          <select
            id="language"
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
            required
          >
            <option value="" disabled>
              {t("assessments.selectLanguageOption")}
            </option>
            {languages.map((lang) => (
              <option key={lang} value={lang}>
                {t(`assessments.languages.${lang}`)}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="level">{t("assessments.selectLevel")}</label>
          <select
            id="level"
            value={selectedLevel}
            onChange={(e) => setSelectedLevel(e.target.value)}
            required
          >
            <option value="" disabled>
              {t("assessments.selectLevelOption")}
            </option>
            {levels.map((level) => (
              <option key={level} value={level}>
                {t(`assessments.levels.${level}`)}
              </option>
            ))}
          </select>
        </div>
        <div className="form-actions">
          <button type="submit" className="submit-button">
            {t("assessments.generateLanguage")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateLanguageAssessment;
