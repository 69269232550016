import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './AddCandidate.scss';
import { API_URL } from '../../../shared.js';

const AddCandidate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    zip_code: '',
    country: '',
    photo: '',
    linkedin_url: '',
    twitter_url: '',
    facebook_url: '',
    instagram_url: '',
    youtube_url: '',
    website_url: '',
    summary: '',
    skills: [],
    education: '',
    experience: '',
    certifications: '',
    languages: '',
    interests: '',
    availability: '',
    desired_salary: '',
    notice_period: '',
    visa_status: '',
    relocation_willingness: '',
    work_arrangement: '',
    resume: null,
  });

  const [errorMessage, setErrorMessage] = useState('');


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

      // Ajout de la validation pour "desired_salary"
    if (name === 'desired_salary') {
      if (value > 1000000) {
        setErrorMessage('The maximum allowed salary is 1,000,000.');
      } else if (value < 0) {
        setErrorMessage('The salary cannot be negative.');
      } else {
        setErrorMessage('');
      }
    }

    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

    // Validation du champ Desired Salary
    const validateDesiredSalary = (value) => {
      if (value > 1000000) {
        setErrorMessage('The maximum allowed salary is 1,000,000.');
      } else if (value < 0) {
        setErrorMessage('The salary cannot be negative.');
      } else {
        setErrorMessage('');
      }
    };


    const handleFileUpload = (e) => {
      const file = e.target.files[0];
      if (file) {
        setFormData((prevState) => ({
          ...prevState,
          resume: file,  
        }));
      }
    };






  const handleSkillsChange = (e) => {
    const skills = e.target.value.split(',').map(skill => skill.trim());
    setFormData(prevState => ({ ...prevState, skills }));
  };

  



  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const payload = { ...formData };
  
    if (formData.resume && formData.resume instanceof Blob) {
      // Si un CV est fourni, lisez son contenu et soumettez avec
      const reader = new FileReader();
      reader.onloadend = async () => {
        payload.resume = reader.result; // Encode le CV en base64
        await submitCandidate(payload);
      };
      reader.readAsDataURL(formData.resume);
    } else {
      // Si aucun CV n'est fourni, soumettez sans le champ "resume"
      payload.resume = null; // ou supprimez ce champ si non nécessaire
      await submitCandidate(payload);
    }
  };
  
  const submitCandidate = async (payload) => {
    try {
      const response = await fetch(`${API_URL}/create-candidate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        credentials: "include",
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || t("addCandidate.alerts.error"));
      }
  
      const data = await response.json();
      console.log("Candidate created:", data);
      alert(t("addCandidate.alerts.success"));
      navigate("/b2b/dashboard/candidates");
    } catch (error) {
      console.error("Error creating candidate:", error);
      alert(t("addCandidate.alerts.error", { message: error.message }));
    }
  };
  

   
   


  return (
    <div className="add-candidate">
      <div className="add-candidate-header">
        <h2>{t('addCandidate.title')}</h2>
      </div>
      <form onSubmit={handleSubmit}
      
      >
        {/* Personal Information */}
        <div className="form-group">
          <label htmlFor="first_name">{t('addCandidate.personalInfo.firstName.label')}</label>
          <input 
            type="text" 
            id="first_name" 
            name="first_name" 
            value={formData.first_name} 
            onChange={handleChange} 
            placeholder={t('addCandidate.personalInfo.firstName.placeholder')}
            required 
          />
        </div>



        <div className="form-group">
          <label htmlFor="resume">{t("addCandidate.resume.label", "Upload CV")}</label>
          <input
            type="file"
            id="resume"
            name="resume"
            onChange={handleFileUpload}
            accept=".pdf,.doc,.docx"
          />
        </div>









        <div className="form-group">
          <label htmlFor="last_name">{t('addCandidate.personalInfo.lastName.label')}</label>
          <input 
            type="text" 
            id="last_name" 
            name="last_name" 
            value={formData.last_name} 
            onChange={handleChange} 
            placeholder={t('addCandidate.personalInfo.lastName.placeholder')}
            required 
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">{t('addCandidate.personalInfo.email.label')}</label>
          <input 
            type="email" 
            id="email" 
            name="email" 
            value={formData.email} 
            onChange={handleChange} 
            placeholder={t('addCandidate.personalInfo.email.placeholder')}
            required 
          />
        </div>

        <div className="form-group">
          <label htmlFor="phone">{t('addCandidate.personalInfo.phone.label')}</label>
          <input 
            type="tel" 
            id="phone" 
            name="phone" 
            value={formData.phone} 
            onChange={handleChange} 
            placeholder={t('addCandidate.personalInfo.phone.placeholder')}
          />
        </div>

        {/* Location Information */}
        <div className="form-group">
          <label htmlFor="address">{t('addCandidate.location.address.label')}</label>
          <textarea 
            id="address" 
            name="address" 
            value={formData.address} 
            onChange={handleChange} 
            placeholder={t('addCandidate.location.address.placeholder')}
          />
        </div>

        <div className="form-group">
          <label htmlFor="city">{t('addCandidate.location.city.label')}</label>
          <input 
            type="text" 
            id="city" 
            name="city" 
            value={formData.city} 
            onChange={handleChange} 
            placeholder={t('addCandidate.location.city.placeholder')}
          />
        </div>

        {/* Professional Information */}
        <div className="form-group">
          <label htmlFor="skills">{t('addCandidate.professional.skills.label')}</label>
          <input 
            type="text" 
            id="skills" 
            name="skills" 
            value={formData.skills.join(', ')} 
            onChange={handleSkillsChange} 
            placeholder={t('addCandidate.professional.skills.placeholder')}
          />
        </div>

        <div className="form-group">
          <label htmlFor="experience">{t('addCandidate.professional.experience.label')}</label>
          <textarea 
            id="experience" 
            name="experience" 
            value={formData.experience} 
            onChange={handleChange} 
            placeholder={t('addCandidate.professional.experience.placeholder')}
          />
        </div>

        <div className="form-group">
          <label htmlFor="education">{t('addCandidate.professional.education.label')}</label>
          <textarea 
            id="education" 
            name="education" 
            value={formData.education} 
            onChange={handleChange} 
            placeholder={t('addCandidate.professional.education.placeholder')}
          />
        </div>

        {/* Social Media Links */}
        <div className="form-group">
          <label htmlFor="linkedin_url">{t('addCandidate.social.linkedin.label')}</label>
          <input 
            type="url" 
            id="linkedin_url" 
            name="linkedin_url" 
            value={formData.linkedin_url} 
            onChange={handleChange} 
            placeholder={t('addCandidate.social.linkedin.placeholder')}
          />
        </div>

        {/* Work Preferences */}
        <div className="form-group">
          <label htmlFor="desired_salary">{t('addCandidate.preferences.desiredSalary.label')}</label>
          <input 
            type="number" 
            id="desired_salary" 
            name="desired_salary" 
            value={formData.desired_salary} 
            onChange={handleChange} 
            placeholder={t('addCandidate.preferences.desiredSalary.placeholder')}
          />
          {errorMessage && <p className="error-message">{t(`addCandidate.preferences.desiredSalary.error.${errorMessage === 'The maximum allowed salary is 1,000,000.' ? 'max' : 'negative'}`)}</p>}
        </div>

        <div className="form-group">
          <label htmlFor="availability">{t('addCandidate.preferences.availability.label')}</label>
          <input 
            type="text" 
            id="availability" 
            name="availability" 
            value={formData.availability} 
            onChange={handleChange} 
            placeholder={t('addCandidate.preferences.availability.placeholder')}
          />
        </div>

        <div className="form-actions">
          <button 
            type="button" 
            className="cancel-button"
            onClick={() => navigate('/b2b/dashboard/candidates')}
          >
            {t('addCandidate.actions.cancel')}
          </button>
          <button 
            type="submit" 
            className="submit-button"
          >
            {t('addCandidate.actions.submit')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddCandidate;


