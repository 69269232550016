import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useState } from "react";
import logo from "../assets/Logo.png";
import FooterMenuGeneral from "./FooterMenuGeneral";
import { FaCheckCircle, FaArrowLeft } from "react-icons/fa";
import "./ApplicationThankYouPage.scss";

// Import flag images
import ukFlag from "../assets/flags/en-flag.png";
import frFlag from "../assets/flags/fr-flag.png";
import esFlag from "../assets/flags/es-flag.png";
import itFlag from "../assets/flags/it-flag.png";
import ptFlag from "../assets/flags/pt-flag.png";
import deFlag from "../assets/flags/de-flag.png";

const ApplicationThankYouPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);

  const toggleLangMenu = () => {
    setIsLangMenuOpen(!isLangMenuOpen);
  };

  const getCurrentLanguageFlag = () => {
    switch (i18n.language) {
      case "fr":
        return frFlag;
      case "es":
        return esFlag;
      case "it":
        return itFlag;
      case "pt":
        return ptFlag;
      case "de":
        return deFlag;
      default:
        return ukFlag;
    }
  };

  const jobBoardPath = (() => {
    const path = window.location.pathname;
    
    if (path.startsWith('/b2b/dashboard/job-board')) {
      return '/b2b/dashboard/job-board';
    }
    if (path.startsWith('/b2b')) {
      return '/b2b/job-board';
    }
    return '/b2c/job-board';
  })();

  return (
    <div className="thank-you-page">
      {/* <header className="header-nav">
        <div className="logo-container">
          <Link to="/" className="logo-link">
            <img src={logo} alt="Logo" className="logo" />
          </Link>
        </div>
        <ul className="nav-links">
          <li className="language-selector">
            <button onClick={toggleLangMenu} className="lang-toggle">
              <img
                src={getCurrentLanguageFlag()}
                alt={i18n.language}
                className="flag-icon"
              />
              <span className="current-lang">
                {i18n.language.toUpperCase()}
              </span>
            </button>
            <ul className={`lang-dropdown ${isLangMenuOpen ? "open" : ""}`}>
              <li>
                <button onClick={() => i18n.changeLanguage("en")}>
                  English
                </button>
              </li>
              <li>
                <button onClick={() => i18n.changeLanguage("fr")}>
                  Français
                </button>
              </li>
              <li>
                <button onClick={() => i18n.changeLanguage("es")}>
                  Español
                </button>
              </li>
              <li>
                <button onClick={() => i18n.changeLanguage("it")}>
                  Italiano
                </button>
              </li>
              <li>
                <button onClick={() => i18n.changeLanguage("pt")}>
                  Português
                </button>
              </li>
              <li>
                <button onClick={() => i18n.changeLanguage("de")}>
                  Deutsch
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </header> */}
      <div className="thank-you-container">
        <FaCheckCircle className="success-icon" />
        <h1>{t("thankYouApplication.title")}</h1>
        <p>{t("thankYouApplication.message")}</p>
        <button
          onClick={() => navigate(`${jobBoardPath}`)}
          className="back-button"
        >
          <FaArrowLeft /> {t("thankYouApplication.backToJobs")}
        </button>
      </div>
    </div>
  );
};

export default ApplicationThankYouPage;
