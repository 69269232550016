import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './CandidatePipelineDetails.scss';

const CandidatePipelineDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  console.log('CandidatePipelineDetails rendered with id:', id);

  // Mock data for demonstration
  const [candidate] = useState({
    id: id,
    name: 'John Doe',
    email: null, // Can be null
    phone: null, // Can be null
    currentStage: 'Sourcing',
    position: 'Frontend Developer',
    experience: '5 years',
    skills: ['React', 'JavaScript', 'CSS', 'Node.js'],
    education: 'Master in Computer Science',
    lastUpdate: '2024-03-20',
    documents: [], // Can be empty
    notes: 'Strong candidate with relevant experience in frontend development.',
    languages: ['English', 'French', 'Spanish'],
    workExperiences: [],
    academicExperiences: [],
    recommendations: [],
    assessments: [],
  });

  const handleStageChange = (newStage) => {
    // Handle stage change logic here
    console.log('Stage changed to:', newStage);
  };

  const handleBack = () => {
    navigate('/b2b/dashboard/candidates/pipeline');
  };

  return (
    <div className="candidate-pipeline-details">
      <button className="back-button" onClick={handleBack}>
        {t('dashboard.backToPipeline')}
      </button>

      <div className="candidate-header">
        <h2>{candidate.name}</h2>
        <span className="position-badge">{candidate.position}</span>
        <span className="stage-badge">{candidate.currentStage}</span>
      </div>

      <div className="details-grid">
        <div className="detail-section">
          <h3>{t('dashboard.personalInfo')}</h3>
          <div className="info-group">
            {candidate.email && (
              <p><strong>{t('dashboard.email')}:</strong> {candidate.email}</p>
            )}
            {candidate.phone && (
              <p><strong>{t('dashboard.phone')}:</strong> {candidate.phone}</p>
            )}
            {candidate.experience && (
              <p><strong>{t('dashboard.experience')}:</strong> {candidate.experience}</p>
            )}
            {candidate.education && (
              <p><strong>{t('dashboard.education')}:</strong> {candidate.education}</p>
            )}
            {!candidate.email && !candidate.phone && (
              <p className="no-contact-info">{t('dashboard.contactInfoNotAvailable')}</p>
            )}
          </div>
        </div>

        <div className="detail-section">
          <h3>{t('dashboard.skills')}</h3>
          <div className="skills-container">
            {candidate.skills.length > 0 ? (
              candidate.skills.map((skill, index) => (
                <span key={index} className="skill-badge">{skill}</span>
              ))
            ) : (
              <p className="no-data">{t('dashboard.noSkillsListed')}</p>
            )}
          </div>
        </div>

        {candidate.documents.length > 0 && (
          <div className="detail-section">
            <h3>{t('dashboard.documents')}</h3>
            <div className="documents-list">
              {candidate.documents.map((doc, index) => (
                <div key={index} className="document-card">
                  <span className="document-icon">📄</span>
                  <div className="document-info">
                    <p className="document-name">{doc.name}</p>
                    <p className="document-type">{doc.type}</p>
                    <p className="document-date">{doc.uploadDate}</p>
                  </div>
                  <button className="download-btn">{t('dashboard.download')}</button>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="detail-section full-width">
          <h3>{t('dashboard.notes')}</h3>
          <div className="notes-container">
            {candidate.notes ? (
              <p>{candidate.notes}</p>
            ) : (
              <p className="no-data">{t('dashboard.noNotesAvailable')}</p>
            )}
            {candidate.workExperiences.length > 0 && (
              <p><strong>{t('dashboard.workExperience')}:</strong> {candidate.workExperiences}</p>
            )}
            {candidate.academicExperiences.length > 0 && (
              <p><strong>{t('dashboard.academicExperience')}:</strong> {candidate.academicExperiences}</p>
            )}
            {candidate.recommendations.length > 0 && (
              <p><strong>{t('dashboard.recommendations')}:</strong> {candidate.recommendations}</p>
            )}
            {candidate.assessments.length > 0 && (
              <p><strong>{t('dashboard.assessments')}:</strong> {candidate.assessments}</p>
            )}
          </div>
        </div>
      </div>

      <div className="action-buttons">
        <button className="primary-btn">
          {t('dashboard.contactCandidate')}
        </button>
        <button className="secondary-btn">
          {t('dashboard.addToPool')}
        </button>
        <button className="danger-btn">
          {t('dashboard.dismiss')}
        </button>
      </div>
    </div>
  );
};

export default CandidatePipelineDetails;
