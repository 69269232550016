import { useState } from "react";
import "./TrackingBoardItemCard.scss";
import { MdDelete, MdEdit, MdVisibility } from "react-icons/md";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import axios from "axios";
import { API_URL } from "../../../shared";
import CandidateDescription from "./CandidateDescription.jsx";
import CandidateDetailsModal from "../Modals/CandidateDetailModal.jsx";
import { t } from "i18next";
const TrackingBoardTaskCard = ({ task, refreshBoard }) => {
  const [edit, setEdit] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [currentId, setCurrentId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // adding this for modal candidate details

  // Variables des informations du candidat
  const candidateName = task.candidate_id
    ? task.description.split("\n")[0]
    : "";
  const candidateEmail = task.description.match(/Email:\s(.+)/)?.[1] || "";
  const candidatePhone = task.description.match(/Phone:\s(.+)/)?.[1] || "";
  const candidateLinkedIn =
    task.description.match(/LinkedIn:\s(.+)/)?.[1] || "";

  const [formData, setFormData] = useState({
    title: task.title || "",
    description: task.description || "",
    position: task.position || "",
    list_id: task.list_id,
  });

  const getComments = async (taskId) => {
    try {
      const response = await axios.get(
        `${API_URL}/v1/kanban/cards/comment/${taskId}`,
        {
          withCredentials: true,
        }
      );
      setComments(response?.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  function handleViewModalOpen(id) {
    setIsViewModalOpen(true);
    setCurrentId(id);
    getComments(id);
  }

  const handleCommentSubmit = async () => {
    if (!comment.trim()) {
      alert("Comment cannot be empty");
      return;
    }

    try {
      const response = await fetch(
        `${API_URL}/v1/kanban/cards/comment/${currentId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            comment: comment,
          }),
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        alert("Comment submitted successfully!");
        setComment(""); // Clear the textarea after submission
        getComments(currentId);
      } else {
        alert("Failed to submit comment");
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleEditCard = async (id) => {
    try {
      console.log(id);
      const response = await axios.put(
        `${API_URL}/v1/kanban/cards/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.data) {
        refreshBoard();
        setEdit(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: task.id,
    data: { type: "task", task },
    disabled: edit, // Disable drag when in edit mode
  });

  const style = {
    transform: transform ? CSS.Transform.toString(transform) : undefined,
    transition,
  };

  if (isDragging) {
    return <div className="draggedTask" ref={setNodeRef} style={style}></div>;
  }
  const deleteTask = async (id) => {
    try {
      const response = await axios.delete(`${API_URL}/v1/kanban/cards/${id}`, {
        withCredentials: true,
      });

      if (response.status === 204) {
        alert("Task deleted successfully");
        refreshBoard();
      } else {
        console.log(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error deleting task:", error.message);
    }
  };
  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={`task-container ${task.candidate_id ? "candidate-task" : ""} ${
        edit ? "editing" : ""
      }`}
    >
      {edit ? (
        <div className="edit-fields">
          <input
            className="edit-input"
            name="title"
            value={formData.title}
            placeholder="Title"
            onChange={handleChange}
          />
          <textarea
            className="edit-textarea"
            name="description"
            value={formData.description}
            rows={5}
            placeholder="Description"
            onChange={handleChange}
          />
          <div className="edit-task-btns">
            <button
              type="button"
              className="cancel-button"
              onClick={() => setEdit(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="save-button"
              onClick={() => handleEditCard(task.id)}
            >
              Save
            </button>
          </div>
        </div>
      ) : (
        <>
          <div class="task-icons-header">
            <h4
              className="task-title"
              onClick={() => handleViewModalOpen(task.id)}
            >
              {task.title}
            </h4>
            <div class="task-icons">
              <MdEdit
                className="task-icon-edit"
                onClick={() => setEdit((prev) => !prev)}
                cursor="pointer"
              />
              <MdDelete
                className="task-icon-delete"
                cursor="pointer"
                onClick={() => {
                  if (
                    window.confirm("Are you sure you want to delete this task?")
                  ) {
                    deleteTask(task.id);
                  }
                }}
              />
            </div>
          </div>
          <p
            className="task-description"
            onClick={() => handleViewModalOpen(task.id)}
          >
            {task.candidate_id ? (
              <>
                <CandidateDescription
                  description={task.description}
                  candidateId={task.candidate_id}
                />
                <button
                  type="button"
                  className="view-details-button"
                  onClick={(e) => {
                    e.stopPropagation(); // Empêche le déclenchement des événements parents
                    setIsModalOpen(true);
                  }}
                >
                  {t("CandidateDescription.viewCandidateDetails")}
                </button>
              </>
            ) : (
              <span>{task.description}</span>
            )}
          </p>
        </>
      )}

      {isModalOpen && (
        <CandidateDetailsModal
          candidateId={task.candidate_id}
          onClose={() => setIsModalOpen(false)}
        />
      )}

      {isViewModalOpen && (
        <div
          className="view-modal-overlay"
          onClick={() => setIsViewModalOpen(false)}
        >
          <div
            className="view-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="view-modal-close"
              onClick={() => setIsViewModalOpen(false)}
            >
              ×
            </button>
            <div>
              <h3 class="view-model-title">Title: {formData.title}</h3>
              <h3 class="view-model-description-label">Description:</h3>
              <p class="view-model-description">{formData.description}</p>

              {/* Display comments */}
              {comments?.map((comment) => (
                <div key={comment.id} className="comment-box">
                  <div className="comment-header">
                    <strong className="comment-user">
                      {comment.User.username}
                    </strong>
                    <span className="comment-time">
                      {new Date(comment.created_at).toLocaleString()}
                    </span>
                  </div>
                  <p className="comment-content">{comment.comment}</p>
                </div>
              ))}

              <h3 class="view-model-add-comment-label">Add a comment</h3>
              <textarea
                className="comment"
                rows="5"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Write your comment here..."
              />
              <div style={{ textAlign: "right" }}>
                <button
                  className="commentBtn"
                  onClick={handleCommentSubmit}
                  disabled={!comment.trim()}
                  style={{
                    cursor: comment.trim() ? "pointer" : "not-allowed",
                    opacity: comment.trim() ? 1 : 0.6,
                  }}
                >
                  Comment
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrackingBoardTaskCard;
