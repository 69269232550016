import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FiMail, FiChevronDown, FiArrowLeft } from "react-icons/fi";
import "./CandidateEmailsTemplates.scss";
import { API_URL } from "../../../shared";

const CandidateEmailsTemplates = () => {
  const { candidateId } = useParams();
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [candidate, setCandidate] = useState(null);
  const [expandedTemplates, setExpandedTemplates] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch email templates for the candidate
        const templatesResponse = await fetch(
          `${API_URL}/email-templates/candidate/${candidateId}`,
          {
            credentials: 'include',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
        );
        const templatesData = await templatesResponse.json();
        setTemplates(templatesData);

        // Fetch candidate details
        const candidateResponse = await fetch(
          `${API_URL}/candidates/${candidateId}`,
          {
            credentials: 'include',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          }
        );
        const candidateData = await candidateResponse.json();
        setCandidate(candidateData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [candidateId]);

  const toggleEmailDetails = (emailId) => {
    setExpandedTemplates(prevState => ({
      ...prevState,
      [emailId]: !prevState[emailId]
    }));
  };

  return (
    <div className="candidate-emails-container">
      <div className="email-box">
        <div className="header">Inbox</div>
        <div className="emails-list">
          {templates.length > 0 ? (
            templates.map((email) => (
              <div
                key={email.id}
                className={`email-item ${expandedTemplates[email.id] ? "open" : ""}`}
                onClick={() => toggleEmailDetails(email.id)}
              >
                <div className="email-header">
                  <span className="email-subject">{email.subject}</span>
                  <FiMail className="email-icon" />
                  <FiChevronDown
                    className="email-icon"
                    style={{
                      transform: expandedTemplates[email.id] ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "transform 0.3s ease",
                    }}
                  />
                </div>
                <p className="email-preview">
                  {expandedTemplates[email.id]
                    ? email.content
                    : `${email.content.slice(0, 100)}...`}
                </p>
              </div>
            ))
          ) : (
            <p className="no-emails">No emails found for this candidate.</p>
          )}
        </div>
        <div className="return-button" onClick={() => navigate("/b2b/dashboard/email-templates")}>
          <FiArrowLeft className="return-icon" />
          <span>Return to All Emails</span>
        </div>
      </div>
    </div>
  );
};

export default CandidateEmailsTemplates;
