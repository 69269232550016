import React from 'react';
import { useTranslation } from 'react-i18next';

const WelcomeMessage = ({ firstName }) => {
  const { t } = useTranslation();

  return (
    <div className="welcome-message">
      {t('dashboard.mainPage.welcome')}, {firstName}!
    </div>
  );
};

export default WelcomeMessage;
