import "./TalexaConfirmationSubscription.scss";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import SubscriptionAddonsModal from "./Modals/SubscriptionAddonsModal";
import SuccessModal from "./Modals/SuccessStripeModal";
import { loadStripe } from "@stripe/stripe-js";
import { API_URL } from "../../shared.js";

// Charger Stripe avec la clé publiable
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const TalexaConfirmationSubscription = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // États pour gérer les données
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeError, setPromoCodeError] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [isPromoApplied, setIsPromoApplied] = useState(false);
  const [currentFormData, setCurrentFormData] = useState(null);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const addons = [
    "add_on_analysis",
    "add_on_ai_phone_call",
    "add_on_skills_assessment",
    "add_on_language_assessment",
    "add_on_white_label",
  ];

  const basePrice = 97; // Prix de base

  // Calcul du prix total
  const calculateTotalPrice = (
    formData,
    selectedAddons,
    discountPercentage
  ) => {
    const addonsPrice = selectedAddons.reduce((acc, addOn) => {
      const price = parseFloat(t(`Talexa.addons.${addOn}.price`)) || 0;
      return acc + price;
    }, 0);

    let totalPrice =
      formData.payment_term === "Yearly"
        ? (basePrice + addonsPrice) * formData.quantity * 12
        : (basePrice + addonsPrice) * formData.quantity;

    if (discountPercentage > 0) {
      totalPrice -= (totalPrice * discountPercentage) / 100;
    }

    return totalPrice;
  };

  // Récupérer les données de session
  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const response = await fetch(`${API_URL}/subscription/session`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch subscription data from session.");
        }

        const data = await response.json();
        const selectedAddons = addons.filter((addOn) => data[addOn]);
        const discount = data.discount_percentage || 0;
        const totalPrice = calculateTotalPrice(data, selectedAddons, discount);

        setCurrentFormData({
          ...data,
          quantity: parseInt(data.quantity, 10 || 1),
          price: totalPrice,
        });

        setPromoCode(data.promo_code || "");
        setSelectedAddons(selectedAddons);
        setDiscountPercentage(discount);
        setIsPromoApplied(!!data.promo_code);
      } catch (error) {
        console.error("Error fetching subscription data:", error);
        navigate("/b2b/subscription");
      }
    };

    fetchSessionData();
  }, [navigate]);

  // Recalcul immédiat du prix total dès que les données changent
  useEffect(() => {
    if (currentFormData) {
      const totalPrice = calculateTotalPrice(
        currentFormData,
        selectedAddons,
        discountPercentage
      );
      setCurrentFormData((prevFormData) => ({
        ...prevFormData,
        price: totalPrice,
      }));
    }
  }, [
    discountPercentage,
    selectedAddons,
    currentFormData?.quantity,
    currentFormData?.payment_term,
  ]);

  // Vérification du retour Stripe
  useEffect(() => {
    const success = searchParams.get("success");
    const canceled = searchParams.get("canceled");
    const sessionId = searchParams.get("session_id");
    console.log("Session Id : ",sessionId);

    if (success === "true" && sessionId && !isPaymentConfirmed) {
      const validateStripeSession = async () => {
        try {
          const response = await fetch(
            `${API_URL}/stripe/session/${sessionId}`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
              credentials: "include",
            }
          );
          if (!response.ok) {
            throw new Error("Failed to validate Stripe session.");
          }

          const sessionData = await response.json();

          // Récupérer les informations de type de paiement et statut
          const paymentStatus = sessionData.payment_status;
          const paymentMethod = sessionData.payment_method_types
            ? sessionData.payment_method_types[0]
            : "unknown";

          if (paymentStatus === "paid") {
            const updatedFormData = {
              ...currentFormData,
              status: paymentStatus,
              payment_type: paymentMethod,
              session_id: sessionId,
            };

            setCurrentFormData(updatedFormData);
            await updateSession(updatedFormData);

            sessionStorage.setItem("paymentConfirmed", "true");
            setIsPaymentConfirmed(true);
            setShowSuccessModal(true);
            
            setTimeout(async () => {
               navigate("/b2b/dashboard", { state: { paymentConfirmed: true } });
            }, 8420);
          } else {
            navigate("/b2b/subscription/confirmation");
          }
        } catch (error) {
          console.error("Error validating Stripe session:", error);
          navigate("/b2b/subscription/confirmation");
        }
      };
      // passer la subscription à la base de données après confirmation
      validateStripeSession();
    } else if (canceled === "true") {
      setErrorMessage(t("stripeSession.stripeFailed"));

      setTimeout(() => {
        setErrorMessage("");
        navigate("/b2b/subscription/confirmation" );
      }, 5000);
    }
  }, [searchParams, navigate, isPaymentConfirmed]); //currentFormData,

  // passer la subscription à la base de données après confirmation
  useEffect(() => {
    if (isPaymentConfirmed && currentFormData?.session_id) {
      submitSubscription();
    }
  }, [isPaymentConfirmed, currentFormData?.session_id]);

  // Mise à jour des données de session
  const updateSession = async (updatedFormData) => {
    if (JSON.stringify(currentFormData) === JSON.stringify(updatedFormData)) {
      console.log("Session data unchanged, skipping update.");
      return;
    }

    try {
      const response = await fetch(`${API_URL}/subscription/session`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ formData: updatedFormData }),
      });

      if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(
          `Failed to update session: ${
            errorDetails.message || response.statusText
          }`
        );
      }

      const result = await response.json();
      console.log("Session updated successfully:", result);
      setCurrentFormData(result.data);
      return result;
    } catch (error) {
      console.error("Error updating session:", error);
      throw error;
    }
  };

  // Mise à jour du prix total
  const updateTotalPrice = (updatedFormData, updatedAddons) => {
    const totalPrice = calculateTotalPrice(
      updatedFormData,
      updatedAddons,
      discountPercentage
    );
    const newFormData = { ...updatedFormData, price: totalPrice };
    setCurrentFormData(newFormData);
    updateSession(newFormData);
  };

  // Gestion des changements
  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10) || 1;
    const updatedFormData = { ...currentFormData, quantity: newQuantity };
    updateTotalPrice(updatedFormData, selectedAddons);
  };

  const handlePaymentTermChange = (e) => {
    const newPaymentTerm = e.target.value;
    const updatedFormData = {
      ...currentFormData,
      payment_term: newPaymentTerm,
    };
    updateTotalPrice(updatedFormData, selectedAddons);
  };

  const updateSelectedAddons = (updatedAddons) => {
    setSelectedAddons(updatedAddons);

    const updatedFormData = { ...currentFormData };
    addons.forEach((addOn) => {
      updatedFormData[addOn] = updatedAddons.includes(addOn);
    });

    updateTotalPrice(updatedFormData, updatedAddons);
  };

  // Gestion du code promo
  const validatePromoCode = async () => {
    try {
      if (!promoCode.trim()) {
        setPromoCodeError(t(`Talexa.subscriptionPage.promoEmpty`));
        return;
      }

      if (isPromoApplied) {
        setPromoCodeError(t(`Talexa.subscriptionPage.promoApplied`));
        return;
      }

      const response = await fetch(`${API_URL}/promo-codes/validate`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials : "include",
        body: JSON.stringify({ promo_code: promoCode }),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(
          errorResponse.message || t(`Talexa.subscriptionPage.promoInvalid`)
        );
      }

      const promoData = await response.json();
      const discount = promoData.discount_percentage;

      setDiscountPercentage(discount);
      setIsPromoApplied(true);

      const updatedFormData = {
        ...currentFormData,
        promo_code: promoCode,
        discount_percentage: discount,
      };
      const newPrice = calculateTotalPrice(
        updatedFormData,
        selectedAddons,
        discount
      );
      setCurrentFormData({ ...updatedFormData, price: newPrice });

      //mettre à jour la session
      await updateSession({ ...updatedFormData, price: newPrice });
      setPromoCodeError("");
    } catch (error) {
      setPromoCodeError(t(`Talexa.subscriptionPage.promoInvalidorExpired`));
      console.error("Error validating promo code:", error);
    }
  };

  const removePromoCode = async () => {
    try {
      const updatedFormData = {
        ...currentFormData,
        promo_code: null,
        discount_percentage: 0,
      };

      // Recalculer le prix total
      const newPrice = calculateTotalPrice(updatedFormData, selectedAddons, 0);

      // Mettre à jour localement d'abord
      setCurrentFormData({ ...updatedFormData, price: newPrice });

      // Mettre à jour la session ensuite
      await updateSession({ ...updatedFormData, price: newPrice });

      // Réinitialiser les états liés au promo code
      setDiscountPercentage(0);
      setIsPromoApplied(false);
      setPromoCode("");
      console.log("Promo code removed successfully");
    } catch (error) {
      console.error("Error removing promo code:", error);
    }
  };

  const paymentCall = async () => {
    try {

      // verify if the user have already an active subscription
          try {
            const response = await fetch(`${API_URL}/subscriptions/user/current`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
              credentials: "include",
            });
      
            if (!response.ok) {
              throw new Error("Failed to get user subscription data.");
            }
      
            const data = await response.json();
            console.log("User subscription data:", data);
      
            if (data) {
              console.log("User already has an active subscription");
              alert("You already have an active subscription.");
              return;
            }
          } catch (error) {
            console.error("Error getting user subscription data:", error);
          }


      const response = await fetch(
        `${API_URL}/stripe/create-checkout-session`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify(currentFormData),
        }
      );

      if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(
          `Failed to create checkout session: ${errorDetails.message}`
        );
      }

      const sessionData = await response.json();
      const stripe = await stripePromise;

      const { error } = await stripe.redirectToCheckout({
        sessionId: sessionData.id,
      });

      if (error) {
        console.error("Error redirecting to Stripe checkout:", error);
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  const submitSubscription = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      // Validation des données
      if (!currentFormData || !currentFormData.session_id) {
        console.log("Current form data:", currentFormData);
        console.error("Form data is incomplete. session_id is missing.");
        return;
      }

      const response = await fetch(`${API_URL}/subscription`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify(currentFormData),
      });

      if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(
          errorDetails.message || "Error submitting subscription"
        );
      }

      console.log("Subscription successfully submitted.");

      const sessionId = searchParams.get("session_id");
      console.log("Session Id : ",sessionId);

      const confirmSubscription = async (sessionId) => {
        try {
          const response = await fetch(`/api/subscription/confirm`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ session_id: sessionId }),
          });
          if (!response.ok) {
            throw new Error("Error confirming subscription.");
          }
          const result = await response.json();
          console.log("Subscription confirmed:", result);
        } catch (error) {
          console.error("Error confirming subscription:", error);
        }
      };

      await confirmSubscription(sessionId);

    } catch (error) {
      console.error("Error submitting subscription:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (isPaymentConfirmed) {
      // Replace the history state to prevent the user from going back to the payment page
      window.history.replaceState(null, "", window.location.href);
    }
  }, [isPaymentConfirmed]);
 
  

  if (!currentFormData) {
    return <p>Loading subscription details...</p>;
  }

  return (
    <div className="subscription-confirmation">
      {showSuccessModal && (
        <SuccessModal
          onClose={() => setShowSuccessModal(false)}
          onRedirect={() => {
            navigate("/b2b/dashboard");
          }}
        />
      )}

      <header className="subscription-confirmation__title-container">
        <h1 className="subscription-confirmation__title">
          {isPaymentConfirmed ? "Payment Confirmation" : "Subscription Summary"}
        </h1>
      </header>

      <div className="subscription-confirmation__content">
        <div className="subscription-confirmation__container">
          <div className="subscription-confirmation__addons">
            <h2>{t(`Talexa.titleAddOns`)}</h2>
            {selectedAddons.length > 0 ? (
              <div className="subscription-confirmation__addons-container-items">
                {selectedAddons.map((addOn) => (
                  <div
                    key={addOn}
                    className="subscription-confirmation__addons-container-items-item"
                  >
                    <p key={addOn}>{t(`Talexa.addons.${addOn}.title`)}</p>
                    <div className="subscription-confirmation__addons-container-items-item-price_container">
                      <p className="price">
                        €{t(`Talexa.addons.${addOn}.price`)}
                      </p>
                      <p>/{t(`Talexa.addons.priceMonth`)}</p>
                      {t(`Talexa.addons.${addOn}.credit`) && (
                        <p className="credit">
                          {t(`Talexa.addons.${addOn}.credit`)}{" "}
                          {t(`Talexa.addons.priceCredit`)}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="subscription-confirmation__addons-item">
                <p>{t(`Talexa.addons.noadds`)}</p>
              </div>
            )}
            {!isPaymentConfirmed && (
              <div className="subscription-confirmation__addons-button">
                <button onClick={() => setIsModalVisible(true)}>
                  {t(`Talexa.subscriptionPage.addAddOnsButton`)}
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="subscription-confirmation__addons-container__modality">
          <div className="subscription-confirmation__quantity">
            <label>
              <strong>{t(`Talexa.addons.quantity`)}:</strong>
            </label>
            <input
              type="number"
              min="1"
              value={currentFormData.quantity || 1}
              onChange={(e) => handleQuantityChange(e)}
              disabled={isPaymentConfirmed}
            />
          </div>

          <div className="subscription-confirmation__payment-term">
            <label>
              <strong>{t(`Talexa.subscriptionPage.paymentTermTitle`)}</strong>
            </label>
            <select
              value={currentFormData.payment_term}
              onChange={(e) => handlePaymentTermChange(e)}
              disabled={isPaymentConfirmed}
            >
              <option value="Monthly">
                {t(`Talexa.subscriptionPage.paymentTermMonthly`)}
              </option>
              <option value="Yearly">
                {t(`Talexa.subscriptionPage.paymentTermYearly`)}
              </option>
            </select>
          </div>

          <div className="subscription-confirmation__promocode">
            <label>{t(`Talexa.subscriptionPage.promoCode`)}</label>
            <input
              type="text"
              value={promoCode || ""}
              onChange={(e) => setPromoCode(e.target.value)}
              disabled={isPromoApplied || isPaymentConfirmed}
            />
            <button
              onClick={validatePromoCode}
              disabled={isPromoApplied || isPaymentConfirmed}
            >
              {t(`Talexa.subscriptionPage.applyPromo`)}
            </button>
            {isPromoApplied && (
              <button onClick={removePromoCode} disabled={isPaymentConfirmed}>
                {t(`Talexa.subscriptionPage.removePromo`)}
              </button>
            )}
            {promoCodeError && <p className="error">{promoCodeError}</p>}
          </div>
        </div>

        <div className="subscription-confirmation__total-container">
          <p className="subscription-confirmation__total-container_group">
            {currentFormData.payment_term === "Yearly" ? (
              <span>{t("Talexa.subscriptionPage.paymentTermTotalYear")}</span>
            ) : (
              <span>{t("Talexa.subscriptionPage.paymentTermTotalMonth")}</span>
            )}
            :{" "}
            <span className="subscription-confirmation__total-container_group-final_amount">
              {currentFormData.price} €
            </span>
          </p>
        </div>
        <div className="subscription-confirmation__actions">
          {errorMessage && ( // Si un message d'erreur est présent
            <p className="error-message">{errorMessage}</p>
          )}
          {!isPaymentConfirmed && (
            <button onClick={paymentCall}>{t("Talexa.actionPayment")}</button>
          )}
        </div>
      </div>

      {isModalVisible && (
        <SubscriptionAddonsModal
          selectedAddons={selectedAddons}
          allAddons={addons}
          onClose={() => setIsModalVisible(false)}
          onUpdateAddons={updateSelectedAddons}
        />
      )}
    </div>
  );
};

export default TalexaConfirmationSubscription;
