import React, { useState } from "react";
import { API_URL } from "../shared";
import "./ForgotPasswordPage.scss";





const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(null);


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {



      const response = await fetch(`${API_URL}/forgot-password`, {  
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });



      if (response.ok) {
        setMessage("Check your email for reset instructions.");
        setIsSuccess(true);  
      } else {
        setMessage("Error: Unable to send reset email.");
        setIsSuccess(false);  
      }
    } catch (err) {
      setMessage("An error occurred.");
      setIsSuccess(false);  
    }
  };





  return (
    <div className="forgot-password-page">
  <form onSubmit={handleSubmit}>
    <h1>Forgot Password</h1>
    <label>Email </label>
    <input
      type="email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      required
    />
    <button type="submit">Send Reset Link</button>
    {message && (
       <p className={isSuccess ? "success" : "error"}>{message}</p>
    )}
  </form>
</div>
  );
};

export default ForgotPasswordPage;
