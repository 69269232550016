import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  FaChartLine, FaClock, FaBuilding, FaMoneyBillWave, 
  FaLightbulb, FaRocket, FaUsers, FaBalanceScale 
} from 'react-icons/fa';
import './AICompanyHiringTrends.scss';
import { API_URL } from '../../../../shared.js';
import { API_URL_3000 } from '../../../../shared3000.js';

const LoadingSpinner = ({ t }) => (
  <div className="loading-container">
    <div className="spinner"></div>
    <p className="loading-text">{t('companyAnalytics.loading')}</p>
  </div>
);

const ErrorMessage = ({ message }) => (
  <div className="error-message">
    <p>{message}</p>
  </div>
);

const Section = ({ number, title, content }) => {
  console.log(`Rendering section ${title}:`, content);

  const formatContent = (content) => {
    if (!content) return [];
    
    return content
      .split('\n')
      .map(line => line.trim())
      .filter(line => line && line.length > 0)
      .map(line => {
        if (!line.startsWith('•')) {
          return `• ${line}`;
        }
        return line;
      });
  };

  const lines = formatContent(content);
  console.log(`Formatted lines for ${title}:`, lines);

  if (lines.length === 0) return null;

  return (
    <div className="trends-section">
      <div className="section-header">
        <h2>{number}. {title}</h2>
      </div>
      <div className="section-content">
        <ul>
          {lines.map((line, index) => (
            <li key={index}>{line}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const AICompanyHiringTrends = () => {
  const { t, i18n } = useTranslation();
  const [trendsData, setTrendsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTrendsData = async () => {
      try {
        setLoading(true);
        console.log('Current i18n language:', i18n.language);
        
        const response = await fetch(`${API_URL_3000}/company-hiring-trends`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': i18n.language,
            'X-User-Language': i18n.language,
            'Preferred-Language': i18n.language
          }
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.status}`);
        }

        const { data, language } = await response.json();
        console.log('Response language:', language);
        
        if (data) {
          setTrendsData(data);
        } else {
          throw new Error('Invalid data structure received');
        }
      } catch (err) {
        console.error('Error fetching trends:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTrendsData();
  }, [i18n.language]);

  const formatContent = (trendsData) => {
    if (!trendsData) return '';
    
    // Combine all sections into one text
    const sections = [
      trendsData.hiringOverview,
      trendsData.recruitmentEfficiency,
      trendsData.departmentAnalysis,
      trendsData.recommendations,
      trendsData.futurePlanning
    ];

    // Use Set to remove duplicate lines
    const uniqueLines = new Set(
      sections
        .filter(section => section) // Remove empty sections
        .join('\n') // Join all sections
        .split('\n')
        .map(line => line.trim())
        .filter(line => line.length > 0)
        .map(line => {
          // Handle markdown headers (###)
          if (line.startsWith('###')) {
            return line.replace(/^###\s*/, '');
          }
          // Handle bold text (**text**)
          if (line.includes('**')) {
            return line;
          }
          // Handle nested bullet points
          if (line.startsWith('  -')) {
            return line.replace(/^  -\s*/, '    • ');
          }
          // Handle regular bullet points
          if (line.startsWith('-')) {
            return line.replace(/^-\s*/, '• ');
          }
          return line;
        })
    );

    return Array.from(uniqueLines).join('\n');
  };

  return (
    <div className="company-hiring-trends">
      <div className="trends-header">
        <h2>{t('companyAnalytics.title')}</h2>
        <p>{t('companyAnalytics.subtitle')}</p>
      </div>
      
      {loading ? (
        <LoadingSpinner t={t} />
      ) : error ? (
        <ErrorMessage message={t('companyAnalytics.error')} />
      ) : !trendsData ? (
        <ErrorMessage message={t('companyAnalytics.noData')} />
      ) : (
        <div className="trends-content">
          <div className="trends-summary">
            <ul>
              {formatContent(trendsData).split('\n').map((line, index) => (
                <li 
                  key={index} 
                  className={`summary-item ${line.includes('**') ? 'bold' : ''} ${line.startsWith('    •') ? 'nested' : ''}`}
                >
                  {line}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default AICompanyHiringTrends; 