import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Dashboard404Page.scss';
import logo404 from '../assets/logo404.jpg';

const Dashboard404Page = ({ userType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getDashboardPath = () => {
    switch (userType) {
      case 'admin':
        return '/admin/dashboard';
      case 'reseller':
        return '/reseller/dashboard';
      case 'company':
        return '/b2b/dashboard';
      case 'candidate':
        return '/b2c/dashboard';
      default:
        return '/';
    }
  };

  return (
    <div className="dashboard-not-found-page">
      <div className="logo-404-container">
        <img src={logo404} alt="404 Logo" className="logo-404" />
      </div>

      <main className="main-content">
        <h1>{t('404.title', 'Page Not Found')}</h1>
        <p>{t('404.message', 'Oops! The page you are looking for does not exist.')}</p>

        <div className="link-container">
          <Link to={getDashboardPath()} className="dashboard-link">
            {t('404.returnDashboard', 'Return to Dashboard')}
          </Link>
          
          <button onClick={() => navigate(-1)} className="back-link">
            {t('404.goBack', 'Go Back')}
          </button>
        </div>
      </main>
    </div>
  );
};

export default Dashboard404Page;
