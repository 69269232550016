import React from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const CandidateDescription = ({ description, candidateId }) => {
  const navigate = useNavigate();

  if (!description) return <p>No description provided.</p>;

  // Diviser la description en lignes
  const lines = description.split("\n");

  const getFieldValue = (key) => {
    const line = lines.find((line) => line.startsWith(key));
    return line ? line.replace(`${key}:`, "").trim() : "N/A";
  };

  const linkedInUrl = getFieldValue("LinkedIn");

  return (
    <div className="candidate-description" style={{ whiteSpace: "pre-line" }}>
      <p>
        <strong>{t("CandidateDescription.name")}</strong>{" "}
        {getFieldValue("Name")}
      </p>
      <p>
        <strong>{t("CandidateDescription.email")}</strong>{" "}
        {getFieldValue("Email")}
      </p>
      <p>
        <strong>{t("CandidateDescription.phone")}</strong>{" "}
        {getFieldValue("Phone")}
      </p>
      <p>
        <strong>{t("CandidateDescription.linkedin")}</strong>{" "}
        <a
          href={linkedInUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "blue", textDecoration: "underline" }}
          onClick={(e) => e.stopPropagation()}
        >
          {t("CandidateDescription.viewProfile")}
        </a>
      </p>
    </div>
  );
};

export default CandidateDescription;
